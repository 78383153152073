<mat-card class="checkinbox-element">
  <mat-card-content>
    <div class="blank-page-min-height">
      <div class="sm:w-2/4 text-center mx-auto mt-10 my-0">
        <h1 class="text-primary mb-10">{{ title }}</h1>
        <div class="text-primary" [innerHTML]="content"></div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
