import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseAPI {

  getSetting(settingName: string) {
    return this.http.get(`Lookup/config/${settingName}`);
  }
}
