<app-modal-layout>
  <h1 class="text-center learner">
    <strong>
      <ng-container>{{title}}</ng-container>
    </strong>
  </h1>
  <div *ngIf="currentAddLearnerStep === 1">
    <div class="flex flex-col w-full justify-center items-center">
      <p class="w-full text-center">
        Please select how you would like to add your learners.
      </p>
      <div class="mt-4">
        <div class="w-full flex flex-col md:flex-row lg:mt-8 md:gap-4">
          <div class="w-full md:w-1/3 mt-4">
            <div class="learner-card">
              <app-widget-tile [item]="svgManualIcon" class="w-full h-full"></app-widget-tile>
              <p class="description large">Input your learners using manual entry one learner at a time.</p>
              <button class="btn-primary-outlined mt-4 fixed-size-sm" (click)="selectManual()">
                Add Manually
              </button>
            </div>
          </div>
          <div class="w-full md:w-1/3 mt-4">
            <div class="learner-card">
              <app-widget-tile [item]="svgImportIcon" [isPremium]="true" class="w-full h-full"></app-widget-tile>
              <p class="description large">Upload a spreadsheet of your learners to add multiple learners at a time.</p>
              <ng-container *ngIf="data.activeSubscription; else upgrade">
                <button class="btn-primary-outlined mt-4 fixed-size-sm" (click)="selectPublic()">
                  Add by Spreadsheet
                </button>
              </ng-container>
              <ng-template #upgrade>
                <button class="btn-primary-outlined mt-4 fixed-size-sm" (click)="openPrices()">
                  Upgrade Account
                </button>
              </ng-template>
            </div>
          </div>
          <div class="w-full md:w-1/3 mt-4">
            <div class="learner-card">
              <app-widget-tile [item]="svgPublicIcon" [isPremium]="true" class="w-full h-full"></app-widget-tile>
              <p class="description large">Share a link to your Organization's Training Webpage to enable learners to join on their own.</p>
              <ng-container *ngIf="data.activeSubscription; else upgrade">
                <button class="btn-primary-outlined mt-4 fixed-size-sm" (click)="selectPublic()">
                  Add by Web Page
                </button>
              </ng-container>
              <ng-template #upgrade>
                <button class="btn-primary-outlined mt-4 fixed-size-sm" (click)="openPrices()">
                  Upgrade Account
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentAddLearnerStep === 2">
    <app-add-users *ngIf="selectedAddLearners === 1" (dataEvent)="receiveDataFromChild($event)"></app-add-users>
    <app-import-users *ngIf="selectedAddLearners === 2" (dataEvent)="receiveDataFromChild($event)"></app-import-users>
    <div *ngIf="selectedAddLearners === 3" class="text-center w-full">
      <h3 class="learner pt-2 pb-10 font-medium">Use the Organization Landing Page to enable users to join your organization without sending an invitation.</h3>
      <h2 class="learner">Organization Landing Page</h2>
      <p class="py-2">Use this toggle to enable your organization's page. Note: you must have a group created for users to associate. 
        <a
        class="text-right primary-link"
        href="https://mandatedreportertraining.zendesk.com/hc/en-us/articles/18755043082267"
        target="_blank"
        >Learn More</a
      ></p>
      <div class="mt-auto flex justify-center flex-col items-center">
        <form [formGroup]="form" class="flex items-center">
          <div class="flex flex-col items-center my-8">
            <mat-slide-toggle formControlName="publicUserAssociationToggle" (change)="setPublicPageStatus()">
            </mat-slide-toggle>
          </div>
          <a class="large font-bold pl-3">{{orgData.publicLandingPageEnabled ? 'On' : 'Off'}}</a>
        </form>
      </div>
      <h2 class="learner">Landing Page Link</h2>
      <p class="pt-2 pb-8">Share this link with your users. Note: Anyone with the link can use this page to join your organization as a learner. Use caution when sharing your link.</p>
      <div class="w-full flex justify-center lg:pb-20">
        <div class="w-full sm:w-2/5 pb-2">
          <form [formGroup]="form">
            <mat-form-field class="w-full">
              <mat-label>Public User Association URL </mat-label>
              <input
                id="publicUserAssociationURL"
                class="flex-wrap"
                type="text"
                matInput
                formControlName="publicUserAssociationURL"
                readonly="true"
              />
              <mat-icon *ngIf="orgData.publicLandingPageEnabled" matSuffix class="primary-link" (click)="clipBoard()"
                >assignment</mat-icon
              >
            </mat-form-field>
          </form>
        </div>
      </div>

      <div class="w-full block justify-center items-center">
        <div>
          <button class="btn-primary medium" type="button" [disabled]="!orgData.publicLandingPageEnabled" (click)="clipBoard()">
            <ng-container>Copy Link to Clipboard</ng-container>
          </button>
        </div>
        <div class="pt-3 inline-block">
          <button class="primary-link flex justify-center items-center" type="button" (click)="backStep()">
            <mat-icon class="rotated-icon-135">arrow_outward</mat-icon>
            <span>Back</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-layout>