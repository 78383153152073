import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, Renderer2, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { MediaMatcher, BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { AuthQuery } from '../../../../routes/authentication/state/auth.query';
import { GuidedTour, GuidedTourService, Orientation, GuidedTourModule } from 'ngx-guided-tour';
import { ApiService } from 'src/app/api/services/api.service';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/routes/authentication/state/auth.service';
import { environment } from 'src/environments/environment';
import { SwitchEntityProfileQuery } from '../header/state/switch-entity-profile.query';
import { SwitchEntityProfile } from '../header/state/switch-entity-profile.model';
import { SwitchEntityProfileService } from '../header/state/switch-entity-profile.service';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { AcceptInvitemodalComponent } from 'src/app/shared/components/modals/accept-invitemodal/accept-invitemodal.component';
import { InviteAcceptConfirmedService } from 'src/app/api/services/invite-accept-confirmed/invite-accept-confirmed.service';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { CommonModalComponent, CommonModalDialogData } from '../../modals/common-modal/common-modal.component';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { OrganizationQuery } from 'src/app/routes/org/organization/org-profile/state/organization.query';
import { FooterComponent } from '../footer/footer.component';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { OrgAdminBannerComponent } from '../banner/org-admin-banner/org-admin-banner.component';
import { AppHeaderComponent } from '../header/header.component';
import { AppSidebarComponent } from '../sidebar/sidebar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BannerComponent } from '../banner/banner/banner.component';
import { NgIf, NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { TruncateTextPipe } from 'src/app/api/pipes/truncate-text/truncate-text.pipe';
import { NewFeatureQuery } from 'src/app/api/services/new-feature/newFeature.query';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BannerComponent,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    AppSidebarComponent,
    AppHeaderComponent,
    NgClass,
    OrgAdminBannerComponent,
    BreadcrumbComponent,
    RouterOutlet,
    SpinnerComponent,
    FooterComponent,
    GuidedTourModule,
    AsyncPipe,
    DatePipe,
    TruncateTextPipe
  ],
})
export class FullComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  mobileQuery: MediaQueryList;
  url = '';
  sidebarOpened = false;
  loadTour: boolean = false;
  date: number = new Date().getFullYear();
  versionNumber!: { number: string; date: string };
  startTourServiceFlag: boolean = false;
  public showSearch = false;
  public showUpgradeButton = false;
  hasSubscription = false;
  disableUpgradeButton = false;
  public isAdmin = false;
  showEnvBanner: boolean = false;

  @ViewChild('snav', { static: false }) sideNav!: MatSidenav;
  @ViewChild(BannerComponent) bannerComponent!: BannerComponent;
  @ViewChild('profileSnav', { static: false }) profileSnav!: MatSidenav;

  private readonly _mobileQueryListener: () => void;
  public dashboardTour: GuidedTour = {
    tourId: 'purchases-tour',
    useOrb: false,
    preventBackdropFromAdvancing: true,

    skipCallback: (stepSkippedOn: number) => {
      const tourData = {
        key: 'welcomeTour',
        value: 'true',
      };
      this.api.userStateService
        .userState(tourData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.authService.userState();
          this.authService.welcomeTour$.next(false);
        });
      this.modalService.closeAll();
    },
    completeCallback: () => {
      const tourData = {
        key: 'welcomeTour',
        value: 'true',
      };

      this.api.userStateService
        .userState(tourData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.authService.userState();
          this.authService.welcomeTour$.next(false);
        });
      this.modalService.closeAll();
    },
    steps: [
      {
        title: 'This is your training dashboard.',
        content:
          'All your training happens on this page. View and take trainings available from your organization, download certificates, and access your training history here.',
        selector: '.tour-my-training',
        orientation: Orientation.Right,
        action: () => {},
      },
      {
        title: 'These are organization resources.',
        content: 'View information about your organization and any resources made available by its administrator',
        selector: '.tour-organization',
        orientation: Orientation.Right,
        action: () => {},
      },
      {
        title: 'These are your accounts.',
        content: `
        <p> Along with your new organization, you have a personal account. Switch between
         accounts to view trainings and other resources  available from your organization 
         and training completed on your own. </p><br>
        <p>To switch accounts, simply click the icon to view a list of 
        your available accounts. Select the account to visit the training
         dashboard for that account.</p>
         `,
        selector: '.profile-img',
        orientation: Orientation.BottomRight,
        action: () => {},
      },
    ],
  };

  closeProfileNav() {
    this.profileSnav.close();
  }

  closeSideNav() {
    if (!this.mobileQuery.matches) {
      this.sideNav.close();
    }
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public auth: AuthQuery,
    public breakpointObserver: BreakpointObserver,
    private guidedTourService: GuidedTourService,
    private api: ApiService,
    private authService: AuthService,
    public switchEntityProfileQuery: SwitchEntityProfileQuery,
    private switchEntityProfileService: SwitchEntityProfileService,
    public organizationService: OrganizationService,
    public organizationQuery: OrganizationQuery,
    private authquery: AuthQuery,
    private inviteConfirmService: InviteAcceptConfirmedService,
    private modalService: ModalService,
    private subscriptionQuery: SubscriptionQuery,
    private newFeatureQuery: NewFeatureQuery
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

    window.onbeforeunload = function () {
      if (localStorage?.theme === 'hc') {
        localStorage.removeItem('theme');
      }
    };
    
    this.newFeatureQuery.selectAll().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data?.length > 0) {
        this.modalService.newFeaturesModal(data);
      }
    });
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.versionNumber = {
      number: environment.versionNumber.split('|')[0],
      date: environment.versionNumber.split('|')[1],
    };

    const { paymentsuccessful, certPaymentsuccessful } = this.route.snapshot.queryParams;

    if (paymentsuccessful === 'true' || paymentsuccessful === true) {
      this.modalService.openPurchaseCompleteModal();
    } else if (certPaymentsuccessful === 'true' || certPaymentsuccessful === true) {
      this.modalService.openCertPurchaseCompleteModal();
    }

    this.inviteConfirmService.inviteInfo$
      .pipe(
        filter((inviteInfo) => inviteInfo?.inviteValid),
        takeUntil(this.destroy$),
      )
      .subscribe((inviteInfo) => {
        if (inviteInfo?.isJoinRequest) {
          // if invite is for coop and entitytype is not individual, switch to individual account
          if (inviteInfo?.entityType === 'Coop') {
            if (localStorage?.getItem('entityType') !== 'Individual') {
              const entities = this.switchEntityProfileQuery.getAll();
              const individual = entities.filter((org) => org.entityType === 'Individual');
              this.switchEntityProfileService.updateSwitchOrganization(individual[0].entityGuid);
            }
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { coopName: inviteInfo?.inviteOrgName, coopGuid: inviteInfo?.entityGuid},
              queryParamsHandling: 'merge',
            });
          } else {
            this.openWelcomeModal(inviteInfo?.inviteOrgName);
          }
        } else {
          this.modalService.open(
            AcceptInvitemodalComponent,
            {
              modalType: ModalType.Medium,
              data: {
                componentType: 'join-invite-user',
                item: {
                  inviteOrgName: inviteInfo?.inviteOrgName,
                  inviteGuid: inviteInfo?.inviteGuid,
                  inviteOrgGuid: inviteInfo?.inviteOrgGuid,
                },
                mode: 'add',
              },
              disableClose: true,
            },
            (accepted) => {
              if (accepted) {
                this.openWelcomeModal(inviteInfo?.inviteOrgName);
              }
            },
          );
        }
      });

    this.authquery.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        if (user.roles.includes('Admin')) {
          this.isAdmin = true;
          this.router.navigateByUrl('/login-as');
        } else {
          const entityGuid: any = window.localStorage.getItem('entityGuid');

          this.breakpointObserver
            .observe('(min-width: 768px)')
            .pipe(takeUntil(this.destroy$))
            .subscribe((matches) => {
              this.sidebarOpened = matches.matches;
            });

          if (localStorage.getItem('roleType') === 'EntityAdmin') {
            this.router.events
              .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this.destroy$),
              )
              .subscribe((event: any) => {
                const showModal = this.route.snapshot.queryParams['modal'];
                this.subscriptionQuery.checkSubscriptionUsage(undefined, showModal != 'false');
              });
          }

          this.authService.welcomeTour$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            if (val) {
              this.startTourServiceFlag = true;
              this.changeDetectorRef.detectChanges();
              if (this.startTourServiceFlag) {
                this.callTour();
              }
            } else {
              this.startTourServiceFlag = false;
              this.changeDetectorRef.detectChanges();
            }
          });
        }
      }
    });
  }

  ngAfterViewInit() {
    const drawerContainer = this.el.nativeElement.querySelector('.mat-drawer-inner-container');
    if (this.bannerComponent.maintMessage) {
      this.renderer.addClass(drawerContainer, 'maint-active');
    } else {
      this.renderer.removeClass(drawerContainer, 'maint-active');
    }

    const focusTrapAnchors = document.querySelectorAll('.cdk-focus-trap-anchor');
    focusTrapAnchors.forEach(element => {
      (element as HTMLElement).setAttribute('tabindex', '-1');
    });
  }

  openWelcomeModal(inviteOrgName: string) {
    this.modalService.open(CommonModalComponent, {
      modalType: ModalType.Medium,
      data: {
        title: 'Welcome to the team!',
        message1: `You are now viewing your account for ${inviteOrgName}. 
        You continue to have access to your individual account and any other existing organizations. Take a tour to see
        what's been enabled for your team.`,
        button1Text: 'Take a tour',
        button1Clicked: () => {
          this.inviteConfirmService.inviteInfo$.next(null);
          this.router
            .navigate([], {
              relativeTo: this.route,
              queryParams: null,
              replaceUrl: true,
            })
            .then(() => {
              this.authService.welcomeTour$.next(true);
              this.modalService.close();
            });
        },
        button2Text: 'Skip tour and start training!',
        button2Clicked: () => {
          this.inviteConfirmService.inviteInfo$.next(null);
          const tourData = {
            key: 'welcomeTour',
            value: 'true',
          };
          this.api.userStateService.userState(tourData).subscribe(() => this.authService.userState());
          this.modalService.close();
        },
      } as CommonModalDialogData,
    });
  }

  callTour() {
    setTimeout(() => {
      this.guidedTourService.startTour(this.dashboardTour);
    }, 0);
  }

  isEntityAdmin(org: any): boolean {
    return org?.roles?.includes('EntityAdmin');
  }

  isOrgPage(): boolean {
    return (
      this.router.url.includes('organization') ||
      this.router.url.includes('team') ||
      this.router.url.includes('dashboard') ||
      this.router.url.includes('reports') ||
      this.router.url.includes('trainings') ||
      this.router.url.includes('import-learners') ||
      this.router.url.includes('assign') ||
      this.router.url.includes('join-org')
    );
  }


  isEnvBanner(data: boolean) {
    this.showEnvBanner = data;
  }
}
