<div
  *ngIf="!inProd"
  class="env-banner"
  [ngClass]="{ 'env-banner-dev': context === 'dev', 'env-banner-test': context === 'test' }"
>
  {{ context }}
</div>
<div *ngIf="showRefreshBanner" class="banner-message">
  <div>
    <h3 class="learner text-primary font-bold">New Version Available</h3>
    <p>To continue training, click Refresh to get the latest version of the app.</p>
  </div>
  <div>
    <button type="button" class="btn-primary-outlined medium" (click)="refreshApp()">
      Refresh
    </button>
  </div>
</div>
<div *ngIf="maintMessage" class="banner-message">
  <div>
    <h3 class="learner text-primary font-bold">Maintenance Scheduled</h3>
    <p>
      Maintenance is scheduled for {{ maintMessage.maintenanceDate | date: 'shortTime' }} on
      {{ maintMessage.maintenanceDate | date: 'fullDate' }}. Access to Mandated Reporter Training will be unavailable.
    </p>
  </div>
  <div class="close-icon">
    <mat-icon class="primary-link" (click)="closeBanner(maintMessage.id)">close </mat-icon>
  </div>
</div>
<div *ngIf="generalMessage" class="banner-message">
  <div class="flex justify-between items-center w-full">
    <div>
      <h3 class="learner text-primary font-bold">{{ generalMessage.title }}</h3>
      <p>{{ generalMessage.message }}</p>
    </div>
    <div class="ml-2" *ngIf="generalMessage.buttonLink">
      <a
        type="button"
        class="btn-primary-outlined medium text-center"
        href="{{ generalMessage.buttonLink }}"
        target="_blank"
        >{{ generalMessage.buttonText }}</a
      >
    </div>
  </div>
  <div class="close-icon">
    <mat-icon class="primary-link" (click)="closeBanner(generalMessage.id)">close </mat-icon>
  </div>
</div>
<div *ngIf="customMessage && customMessage.length > 0" class="banner-message custom-message">
  <div class="text-center w-full"> 
      {{ customMessage }}
  </div>
</div>
