<ng-container *ngIf="false" [formGroup]="form">
  <div *ngIf="!isProfile" class="p-3 sm:p-8 sm:m-3 bg-background">
    <h4 class="text-center">California Early Care & Education Workforce Registry</h4>
    <div class="ext-number-field">
      <input type="text" formControlName="externalIdentityNumber"
        placeholder="Enter Your Workforce Registry ID Number" mask="000000000"/>
      <mat-error
        *ngIf="form.controls['externalIdentityNumber'].hasError('required') && form.controls['externalIdentityNumber'].touched">
        Required
      </mat-error>
      <mat-error
        *ngIf="form.controls['externalIdentityNumber'].hasError('pattern') && form.controls['externalIdentityNumber'].touched">
        Number must start with a 1, contain no spaces, and be 9 digits long.
      </mat-error>
      <mat-error *ngIf="form.controls['externalIdentityNumber'].errors?.message">{{
        form.controls['externalIdentityNumber'].getError('message')
        }}</mat-error>
    </div>
    <p class="text-center">
      By providing your California Early Care & Education Workforce Registry ID Number you are
      opting in to sharing
      proof of completion of your Mandated Reporter Training with the California Early Care & Education Workforce
      Registry
    </p>
    <p class="text-center mt-2"><a class="primary-link"
        href="https://ccala.zendesk.com/hc/en-us/articles/360011418414-Where-can-I-find-my-Registry-ID-number"
        target="_blank">Find
        Your
        Workforce Registry ID number</a> or <a class="primary-link"
        href="https://www.caregistry.org/index.cfm?module=userRegistration" target="_blank">Get
        One
        now!</a></p>
  </div>
  <div *ngIf="isProfile" class="w-full sm:w-1/2 sm:pr-6">
    <mat-form-field class="w-full">
      <mat-label>California Early Care & Education Workforce Registry ID Number</mat-label>
      <input matInput type="text" formControlName="externalIdentityNumber" mask="000000000"/>
      <mat-error
        *ngIf="form.controls['externalIdentityNumber'].hasError('required') && form.controls['externalIdentityNumber'].touched">
        Required
      </mat-error>
      <mat-error
        *ngIf="form.controls['externalIdentityNumber'].hasError('pattern') && form.controls['externalIdentityNumber'].touched">
        Number must start with a 1, contain no spaces, and be 9 digits long.
      </mat-error>
      <mat-error *ngIf="form.controls['externalIdentityNumber'].errors?.message">{{
        form.controls['externalIdentityNumber'].getError('message')
        }}</mat-error>
    </mat-form-field>
    <p><a class="primary-link"
        href="https://ccala.zendesk.com/hc/en-us/articles/360011418414-Where-can-I-find-my-Registry-ID-number"
        target="_blank">Find
        Your
        Workforce Registry ID number</a> or <a class="primary-link"
        href="https://www.caregistry.org/index.cfm?module=userRegistration" target="_blank">Get
        One
        now!</a></p>
    <p class="mt-2">
      By providing your California Early Care & Education Workforce Registry ID Number you are
      opting in to sharing
      proof of completion of your Mandated Reporter Training with the California Early Care & Education Workforce
      Registry
    </p>
  </div>
</ng-container>