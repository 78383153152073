<ng-container [formGroup]="form">
  <div class="flex flex-wrap w-full top-cascading-dropdowns">
    <div class="w-full sm:w-1/2 sm:pr-6 pb-2 select-Course" *ngIf="showfields">
      <mat-form-field class="w-full">
        <mat-label>State</mat-label>
        <mat-select formControlName="stateId" panelClass="mat-select-mt3">
          <mat-option *ngFor="let state of lookupsQuery.states$ | async" [value]="state.key">
            {{ state.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.controls['stateId'].hasError('required') && form.controls['stateId'].touched">Required</mat-error>
        <mat-error *ngIf="form.controls['stateId'].errors?.message">{{
          form.controls['stateId'].getError('message')
          }}</mat-error>
      </mat-form-field>
    </div>
    <div class="w-full sm:w-1/2 sm:pr-6 pb-2 select-Course" *ngIf="showfields">
      <mat-form-field class="w-full">
        <mat-label>Industry</mat-label>
        <mat-select formControlName="industryId" panelClass="mat-select-mt3">
          <mat-option *ngFor="let industry of lookupsQuery.industries$ | async" [value]="industry.value">
            {{ industry.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.controls['industryId'].hasError('required') && form.controls['industryId'].touched">Required</mat-error>
        <mat-error *ngIf="form.controls['industryId'].errors?.message">{{
          form.controls['industryId'].getError('message')
          }}</mat-error>
      </mat-form-field>
    </div>
    <div class="w-full sm:w-1/2 sm:pr-6 pb-2 select-Course" *ngIf="showfields">
      <mat-form-field class="w-full">
        <mat-label>Profession</mat-label>
        <mat-select formControlName="professionId" panelClass="mat-select-mt3">
          <mat-option *ngFor="let profession of lookupsQuery.professions$ | async" [value]="profession.value">
            {{ profession.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.controls['professionId'].hasError('required') && form.controls['professionId'].touched">Required</mat-error>
        <mat-error *ngIf="form.controls['professionId'].errors?.message">{{
          form.controls['professionId'].getError('message')
          }}</mat-error>
      </mat-form-field>
    </div>
    <div class="w-full sm:w-1/2 sm:pr-6 pb-2 select-Course" *ngIf="!isEntityAdmin && !isCoopAdmin && isSelectLanguage">
      <mat-form-field class="w-full">
        <mat-label>Language</mat-label>
        <mat-select formControlName="language" panelClass="mat-select-mt3">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let language of lookupsQuery.languages$ | async" [value]="language.value">
            {{ language.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="(lookupsQuery.jobTitleHasValue$ | async) && notTrainingPage" class="w-full sm:w-1/2 pb-2 select-Course">
      <mat-form-field class="w-full">
        <mat-label>Job Title</mat-label>
        <mat-select formControlName="jobTitleId" panelClass="mat-select-mt3">
          <mat-option *ngFor="let jobTitle of lookupsQuery.jobTitles$ | async" [value]="jobTitle.value">
            {{ jobTitle.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.controls['jobTitleId'].hasError('required') && form.controls['jobTitleId'].touched">Required</mat-error>
        <mat-error *ngIf="form.controls['jobTitleId'].errors?.message">{{
          form.controls['jobTitleId'].getError('message')
          }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-container>