<div class="p-5">
  <div class="mb-4">
    <div *ngFor="let org of getOrgList()" class="my-2">
      <a
        class="flex items-center w-full font-medium h-12 cursor-pointer"
        [ngClass]="org?.setActive ? 'active' : ''"
        (click)="switchEntityPro(org)"
      >
        <div class="profile-img ml-2" (click)="profileSnav?.toggle()">
          <div *ngIf="org.logoImageUrl as url; else placeholder">
            <img [src]="url" alt="Profile Picture" />
          </div>
          <ng-template #placeholder>
            <div class="h-10 w-10">
              <img
                *ngIf="org.entityType == 'Individual'"
                src="assets/images/profile/individual-icon.svg"
                alt="Default profile picture for individual"
              />
              <img
                *ngIf="org.entityType == 'Organization'"
                src="assets/images/profile/organization-icon.svg"
                alt="Default profile picture for organization"
              />
              <img
                *ngIf="org.entityType == 'Coop'"
                src="assets/images/profile/organization-icon.svg"
                alt="Default profile picture for cooperative"
              />
            </div>
          </ng-template>
        </div>
        <div class="ml-2">
          <p>{{ org?.entityName }}</p>
          <p class="role-tag"> {{  organizationService.getOrgChipLabel(org) }} </p>
        </div>
        <div *ngIf="org?.setActive" class="flex items-center ml-auto h-full text-green-500">
          <mat-icon>done</mat-icon>
        </div>
      </a>
    </div>
  </div>
  <hr />
  <div class="my-4">
    <a class="flex items-center w-full font-medium mb-3 cursor-pointe" routerLink="/profile" (click)="closeHeader()">
      <mat-icon class="mr-sm">account_circle</mat-icon>
      <span>Profile</span>
    </a>
    <a class="flex items-center w-full font-medium cursor-pointer" (click)="logout()">
      <mat-icon class="mr-sm">logout</mat-icon>
      <span>Log Out</span>
    </a>
  </div>
  <div>
    <div class="mb-2">
      <a href="https://mandatedreportertraining.com/company/terms-of-use" target="_blank" class="primary-link"
        >Terms Of Use</a
      >
    </div>
    <div class="mb-2">
      <a href="https://mandatedreportertraining.com/company/privacy-statement" target="_blank" class="primary-link"
        >Privacy Statement</a
      >
    </div>
    <div>
      <a href="https://mandatedreportertraining.com/company/accessibility" target="_blank" class="primary-link"
        >Accessibility</a
      >
    </div>
  </div>
</div>
