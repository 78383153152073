import { Component, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AppversionService } from 'src/app/api/services/appversion/appversion.service';
import { BannerMessageService } from 'src/app/api/services/banner-message/banner-message.service';
import { UserStateService } from 'src/app/api/services/user-state/user-state.service';
import { AuthQuery } from 'src/app/routes/authentication/state/auth.query';
import { AuthService } from 'src/app/routes/authentication/state/auth.service';
import { environment } from 'src/environments/environment';
import { BannerCustomMessageService } from 'src/app/shared/services/banner-custom-message.service';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        MatIconModule,
        DatePipe,
    ],
})
export class BannerComponent implements OnInit {
  context!: string;
  inProd!: boolean;
  showRefreshBanner = false;
  datetime: Date = new Date();
  generalMessage!: any;
  maintMessage!: any;
  customMessage!: string;
  closedMessages: number[] = [];


  constructor(
    private authQuery: AuthQuery,
    private userStateService: UserStateService,
    private authService: AuthService,
    private versionService: AppversionService,
    private bannerMessageService: BannerMessageService,
    private bannerCustomMessageService: BannerCustomMessageService
  ) {}



  ngOnInit(): void {
    if (environment.context !== 'prod') {
      this.context = environment.context;
      this.inProd = false;
      this.customMessage = '';
    } else {
      this.inProd = true; 
      this.customMessage.length
    }
    this.bannerCustomMessageService.customMessage$.subscribe((message) => { 
      // Get the message from the local JWT
      var token = localStorage.getItem('token');
      if (token) {
        var payload = token.split('.')[1];
        var decodedPayload = atob(payload);
        var parsedPayload = JSON.parse(decodedPayload);
        var isImpersonated = parsedPayload['IsImpersonated'];
        if (isImpersonated) {
          var impersonateName = parsedPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
          this.customMessage = 'Logged in as ' + impersonateName;
        }
        else {
          this.customMessage = '';
        }
      }
    });

    this.versionService.checkForUpdate().subscribe({
      next: (value: any) => {
        const currentVersion = environment.versionNumber.split('|')[0];
        const releaseDate = environment.versionNumber.split('|')[1];        
        if (currentVersion !== value.versionNumber && Date.parse(value.createdDate.split('T')[0]) > Date.parse(releaseDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2'))) {
          this.showRefreshBanner = true;
        }
      },
    });

    interval(environment.versionPollTime * 60 * 1000)
      .pipe(mergeMap(() => this.versionService.checkForUpdate()))
      .subscribe({
        next: (value: any) => {
          const currentVersion = environment.versionNumber.split('|')[0];
          const releaseDate = environment.versionNumber.split('|')[1];
          if (currentVersion !== value.versionNumber && Date.parse(value.createdDate) > Date.parse(releaseDate)) {
            this.showRefreshBanner = true;
          }
        },
      });

    this.bannerMessageService.getBannerMessages().subscribe({
      next: (bannerMessages: any) => {
        if (bannerMessages) {
          let closedMessages: number[] = [];
          this.authQuery.user$.subscribe((user) => {
            if (user) {
              const stateSettings = user.stateSettings;
              const messageAck = stateSettings.find((x: any) => x.key === 'messageAck');
              if (messageAck) {
                if (messageAck.value) {
                  closedMessages = JSON.parse(messageAck.value);
                  this.closedMessages = closedMessages;
                }
              }

              const maintMessage = bannerMessages.find((x: any) => x.maintenanceDate !== null);

              if (maintMessage && !closedMessages.some((x: any) => x === maintMessage.id)) {
                this.maintMessage = maintMessage;
              } else {
                this.maintMessage = null;
              }

              const generalMessage = bannerMessages.find((x: any) => x.maintenanceDate === null);

              if (generalMessage && !closedMessages.some((x: any) => x === generalMessage.id)) {
                this.generalMessage = generalMessage;
              } else {
                this.generalMessage = null;
              }
            }
          });
        }
      },
    });
  }


  refreshApp() {
    window.location.reload();
    this.showRefreshBanner = false;
  }

  closeBanner(messageId: number, type: 'maint' | 'general') {
    this.closedMessages.push(messageId);
    this.userStateService
      .userState({ key: 'messageAck', value: JSON.stringify(this.closedMessages) })
      .subscribe(() => this.authService.userState());
  }
}
