import { Component, Input, OnInit, Inject, Optional } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { AnnualPlanComponent } from './annual-plan/annual-plan.component';
import { PayAsYouGoComponent } from './pay-as-you-go/pay-as-you-go.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  standalone: true,
  imports: [
    MatTabsModule,
    MatCardModule,
    AnnualPlanComponent,
    PayAsYouGoComponent,
    CommonModule
  ],
})
export class PricingComponent implements OnInit {

  initialTab?: number;
  hasActiveSubscription?: boolean;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.data) {
     this.initialTab = this.data.initialTab;
     this.hasActiveSubscription = this.data.hasActiveSubscription;
    }else{
      this.initialTab = 0;
      this.hasActiveSubscription = false;
    }
}
}
