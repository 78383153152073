import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { TrainingMetric } from './training-metric.model';

export interface TrainingMetricsState extends EntityState<TrainingMetric> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'training-metrics' })
export class TrainingMetricsStore extends EntityStore<TrainingMetricsState> {

  constructor() {
    super();
  }

}
