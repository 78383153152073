import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { LookupsQuery } from 'src/app/lookups/lookups.query';
import { LookupsStore } from 'src/app/lookups/lookups.store';
import { LookupsService } from '../../../../../api/services/lookups/lookups.service';
import { RegistrationService } from '../registration.service';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, of, Subject, throwError } from 'rxjs';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabGroup as MatTabGroup, MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { environment } from 'src/environments/environment';
import { InviteAcceptConfirmedService } from 'src/app/api/services/invite-accept-confirmed/invite-accept-confirmed.service';
import { AuthService } from '../../../state/auth.service';
import { BlockCopyPasteDirective } from '../../../../../shared/directives/block-copy-paste/block-copy-paste.directive';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { UserCascadingDropdownsComponent } from 'src/app/shared/components/user-cascading-dropdowns/user-cascading-dropdowns.component';
import { ExternalNumberComponent } from 'src/app/shared/components/external-number/external-number.component';
import { NgxMaskDirective } from 'ngx-mask';
import { GrecaptchaService } from 'src/app/shared/services/grecaptcha.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { TotpType } from 'src/app/api/services/TOTP/totp.service';
import { VerifyType } from '../../verify-email/verify-email.component';

function equalTo(key: string, confirmationKey: string) {
  return (group: UntypedFormGroup) => {
    const input = group.controls[key];
    const confirmationInput = group.controls[confirmationKey];
    return confirmationInput.setErrors(input.value !== confirmationInput.value ? { notEquivalent: true } : null);
  };
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    MatLegacyTabsModule,
    MatLegacyCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    NgFor,
    MatLegacyOptionModule,
    MatLegacyInputModule,
    NgClass,
    BlockCopyPasteDirective,
    RouterLink,
    AsyncPipe,
    MatRadioModule,
    UserCascadingDropdownsComponent,
    ExternalNumberComponent,
    NgxMaskDirective,
    SnackbarComponent,
  ],
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  hide = true;
  ohide = true;
  chide = true;
  ochide = true;
  regularIcon = false;
  regularIconActive = true;
  orgNormal = true;
  orgActive = true;
  orgSelected: boolean = false;
  @ViewChild('tabGroup')
  tabGroup!: MatTabGroup;
  public form: UntypedFormGroup = Object.create(null);
  industries: any[] = [];
  states: any[] = [];
  validEmailSpinner: boolean = false;
  disableFlag: boolean = false;
  cancelledInvite: boolean = false;
  orgName: string = '';
  verifyEmail: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    public lookupsQuery: LookupsQuery,
    public lookupsStore: LookupsStore,
    public lookupsService: LookupsService,
    private registrationService: RegistrationService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private inviteService: InviteAcceptConfirmedService,
    private authService: AuthService,
    private reCaptchaService: GrecaptchaService,
  ) {
    
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.lookupsStore.languages();

    this.lookupsQuery.industries$
      .pipe(
        map((industries) => {
          if (industries.length > 0) {
            return (this.industries = industries);
          } else {
            return this.lookupsStore.industries();
          }
        }),
        switchMap((indus) => {
          if (indus === undefined) {
            return of(null);
          } else {
            return of(indus);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((data) => {
        if (data !== null) {
          if (data.length > 0) {
            this.industries = data;
          }
        }
      });

    this.lookupsQuery.states$
      .pipe(
        map((states) => {
          if (states.length > 0) {
            return states;
          } else {
            return this.lookupsStore.states();
          }
        }),
        switchMap((state) => {
          if (state === undefined) {
            return of(null);
          } else {
            return of(state);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((data) => {
        if (data !== null) {
          if (data.length > 0) {
            this.states = data;
          }
        }
      });

    this.queryParamsData();
    let URLreg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.form = this.fb.group(
      {
        firstName: this.fb.control(null, Validators.compose([Validators.required])),
        lastName: this.fb.control(null, Validators.compose([Validators.required])),
        email: this.fb.control('', Validators.compose([Validators.required, Validators.email as ValidatorFn])),
        confirmEmail: this.fb.control(null, Validators.compose([Validators.required])),
        password: this.fb.control(null, Validators.compose([Validators.required])),
        confirmPassword: this.fb.control(null, Validators.compose([Validators.required])),
        industryId: this.fb.control(null, Validators.compose([Validators.required])),
        professionId: this.fb.control({ value: null, disabled: true }, Validators.compose([Validators.required])),
        volunteerOrgName: this.fb.control('', Validators.compose([Validators.maxLength(50)])),
        jobTitleId: this.fb.control(null),
        stateId: this.fb.control(null, Validators.compose([Validators.required])),
        externalIdentityNumber: this.fb.control(null),
        languageId: this.fb.control(null),
        zipcode: this.fb.control(null),
      },
      { validator: [equalTo('email', 'confirmEmail'), equalTo('password', 'confirmPassword')] },
    );

    // professions are based on industry selection
    this.form.get('industryId')?.valueChanges.subscribe((industryId) => {
      this.form.get('jobTitleId')?.reset();
      if (industryId) {
        this.lookupsStore.professions(industryId);
      }
    });

    this.form.get('professionId')?.valueChanges.subscribe((professionId) => {
      this.form.get('jobTitleId')?.reset();
    });
  }

  ngAfterViewInit() {
    if (this.orgSelected) {
      this.tabGroup.selectedIndex = 2;
    }

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params) {
        this.form.get('firstName')?.patchValue(params.firstname);
        this.form.get('lastName')?.patchValue(params.lastname);
        if(params.email) {
          this.form.get('email')?.patchValue(decodeURIComponent(params.email));
          this.checkEmail(decodeURIComponent(params.email), this.form.controls['email']);
        }
      }
    });
  }

  queryParamsData() {
    return combineLatest([this.lookupsQuery.states$, this.lookupsQuery.industries$, this.route.queryParams])
      .pipe(
        tap(([states, industries, params]) => {
          if (states.length > 0 && industries.length > 0 && params) {
            this.states = states;
            this.industries = industries;
            const { state, zipcode, orgname } = params;
            if (state) {
              const stateCtrl = this.form.get('stateId');
              const val = this.getKeyFromParams(this.states, state);
              stateCtrl?.patchValue(val);
            }
            if (zipcode) {
              const zipcodeCtrl = this.form.get('zipcode');
              zipcodeCtrl?.patchValue(zipcode);
            }
            if (orgname) {
              const orgnameCtrl = this.form.get('volunteerOrgName');
              orgnameCtrl?.patchValue(orgname);
            }
          }

          if (params) {
            if (params.cancelledInvite) {
              this.cancelledInvite = true;
              this.orgName = params.orgName;
            }
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  registerIndividual() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          if (error?.field === 'password') {
            const control = this.form.get('password');
            if (control) {
              this.form.markAllAsTouched();
              control.setErrors({
                message: `The password does not meet the requirements: minimum of 6 characters and contain at least one of each of the following: uppercase letter, lowercase letter, special character, and a number`,
              });
            }
          } else {
            const control = this.form.get(error.field);
            if (control) {
              this.form.markAllAsTouched();
              control.setErrors({
                message: error.message,
              });
            }
          }
        });
      } else {
        this.disableFlag = false;
        const errors = Object.keys(validationErrors.error);
        if (errors.length) {
          if (errors[0] === 'reCaptcha') {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: {
                toastType: ToastType.Error,
                message:
                  'Error occurred during reCAPTCHA validation. If this problem persists, click the Help button for additional assistance.',
              },
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: 'Error occured' },
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Error, message: 'Error occured' },
          });
        }
      }

      return of(null);
    };

    const formValues = {
      ...this.form.getRawValue(),
    };

    if (this.form.get('externalIdentityNumber')?.value) {
      formValues.externalIdentifications = [
        {
          typeId: 1, // TODO there is only one for now
          number: this.form.get('externalIdentityNumber')?.value,
        },
      ];
    }

    this.reCaptchaService.execute('registerIndividual').then((token: any) => {
      formValues.reCaptchaToken = token;
      this.registrationService
        .addIndividual(formValues)
        .pipe(
          catchError((error) => throwError(error)),
          takeUntil(this.destroy$),
        )
        .subscribe(
          () => {
            const email = this.form.get('email')?.value;
            const forgot = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Success, message: 'Successfully Registered' },
            });
            this.router.navigateByUrl('/verify-email', {
              state: { email, forgot, verifyType: VerifyType.VerifyEmail },
            });
            this.disableFlag = false;
          },
          (errorResponse) => {
            validationErrorHandler(errorResponse);
            this.disableFlag = false;
          },
        );
    });
  }

  checkEmail(email: any, control: any) {
    this.verifyEmail = false;

    if (control?.errors === null) {
      this.validEmailSpinner = false;
      this.registrationService
        .validEmail(email)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            if (data.userExists && !data.inviteExists) {
              control.markAsTouched();
              control.setErrors({
                message: `Username '${email}' is already taken.`,
              });
            } else if (data.inviteExists) {
              control.markAsTouched();
              control.setErrors({
                invite: { message: 'You have an open invitation.', email: `${email}` },
              });
            }
            // Now underline and allow "Resend Verification Email" button to appear
            // Like the "Forgot Password" button, this button will send an email to the user
            else if (!data.emailConfirmed && data.userExists) {
              control.markAsTouched();
              this.verifyEmail = true;
            }

            this.validEmailSpinner = false;
          },
          (error) => {
            this.validEmailSpinner = false;
          },
        );
    }
  }

  resendEmail() {
    const formValues = {
      email: this.form?.get('email')?.value?.trim(),
      fullName: '',
    };
    this.authService
      .verify(formValues)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.router.navigateByUrl(`/verify?email=${this.form?.get('email')?.value?.trim()}&forgot=false`);
        },
      });

    this.verifyEmail = false;
  }

  resendInvite(email: any) {
    const acceptUrl = `${environment?.domainName}/accept-invitation-confirmed`;
    this.inviteService
      .resendInvite(email, acceptUrl)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Success, message: 'Email sent' },
          });
          this.router.navigateByUrl(`/verify?email=${this.form.get('email')?.value}&forgot=false`);
        },
        error: (err) => {
          this.form.reset();
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Error, message: 'Error occured' },
          });
        },
      });
  }

  getValueFromParams(arr: any, item: any) {
    const extractVal = item
      .split('-')
      .map((word: any) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');

    const filterArr = arr.filter((its: any) => {
      if (its.label.toLowerCase().trim() === extractVal.toLowerCase()) {
        return true;
      }
    });

    return filterArr[0]?.value;
  }

  getKeyFromParams(arr: any, item: any) {
    const extractVal = item
      .split('-')
      .map((word: any) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');

    const filterArr = arr.filter((its: any) => {
      if (its.label.toLowerCase().trim() === extractVal.toLowerCase()) {
        return true;
      }
    });

    return filterArr[0]?.key;
  }

  closeNoti() {
    this.cancelledInvite = false;
  }
}
