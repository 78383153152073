import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EditUser } from './edit-user.model';

export interface EditUsersState extends EntityState<EditUser> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'edit-users' })
export class EditUsersStore extends EntityStore<EditUsersState> {

  constructor() {
    super();
  }

}
