<div class="content-container">
    <mat-tab-group>
      <mat-tab label="Overview">
        <ng-template #LearnMoreLink>
          <a alt="Learn More" target="_blank" href="https://mandatedreportertraining.zendesk.com/hc/en-us/articles/28251047793179">Learn More</a>
        </ng-template>
        <ng-template matTabContent>
          <div class="mt-4">
            <h2 class="learner">Account Overview</h2>
            <p>This overview provides a snapshot of the usage on your account. For more detailed billing information, visit the Billing section using the tab above.</p>
          </div>
          <div *ngIf="planType === 'CertificateBased'" class="flex flex-col lg:flex-row  justify-between w-full mt-8 gap-8">
            <div class="w-full lg:w-1/2">
                <div class="flex justify-between">
                    <h3 class="learner">Plan - Certificates</h3>
                    <h3 class="learner">
                      <span *ngIf="certSubscriptionQuotaRatio >= 1" class="ApproachingLimit">Limit Reached </span>
                     <span *ngIf="certSubscriptionQuotaRatio >= .9 && certSubscriptionQuotaRatio < 1" class="ApproachingLimit">Approaching Limit </span> 
                     <span>{{billingOverviewData.certificatesUsedSubscription}} of {{billingOverviewData.certificateSubscriptionQuota}}</span>
                    </h3>
                </div>

                <div class="progress-bar-wrapper mt-3">
                    <div class="progress-bar" [ngStyle]="{'left': calculateProgressBar(billingOverviewData.certificatesUsedSubscription, billingOverviewData.certificateSubscriptionQuota)}"></div>
                </div>

                <div class="mt-4">
                  <ng-container *ngIf="hasActiveSubscription; else noSubscription" >
                    <p> 
   
                      <span *ngIf="certSubscriptionQuotaRatio >= 1">Plan limit reached. Upgrade your plan or use Pay-As-You-Go. </span>
                        <span *ngIf="certSubscriptionQuotaRatio >= .9 && certSubscriptionQuotaRatio < 1" class="ApproachingLimit">{{billingOverviewData.certificateSubscriptionQuota - billingOverviewData.certificatesUsedSubscription }} certificates remaining </span>
                        <span *ngIf="certSubscriptionQuotaRatio < .9">{{billingOverviewData.certificateSubscriptionQuota - billingOverviewData.certificatesUsedSubscription }} certificates remaining </span>
                        <span *ngIf="certSubscriptionQuotaRatio < 1"> until your plan requires an upgrade. </span>
                        <ng-container *ngTemplateOutlet="LearnMoreLink"></ng-container>
                    </p>
                    <div class="py-4">
                      <button class="btn-primary-outlined medium" (click)="manageSubscription()">Manage</button>
                    </div>
                  </ng-container>
                  
                  <ng-template #noSubscription>
                    <p>Click Subscribe Now to add a plan to your account. <ng-container *ngTemplateOutlet="LearnMoreLink"></ng-container>
                    </p>
                    <div class="py-4">
                      <button class="btn-primary-outlined medium" (click)="upgradeClicked()"
                      [disabled]="disableUpgradeButton">Subscribe Now</button>
                    </div>
                  </ng-template>
                </div>
            </div>
            
            <div class="w-full lg:w-1/2">
                <div class="flex justify-between">
                    <h3 class="learner">Pay-As-You-Go - Certificates</h3>
                    <h3 class="learner">
                      <span *ngIf="certPayAsYouGoQuotaRatio >= 1" class="ApproachingLimit">Limit Reached </span>
                      <span *ngIf="certPayAsYouGoQuotaRatio >= .9 && certPayAsYouGoQuotaRatio < 1" class="ApproachingLimit">Approaching Limit </span>
                      <span>{{billingOverviewData.certificatesUsedPayAsYouGo}} of {{billingOverviewData.certificatePayAsYouGoQuota}}</span>
                    </h3>
                </div>

                <div class="progress-bar-wrapper mt-3">
                  <div class="progress-bar" [ngStyle]="{'left': calculateProgressBar(billingOverviewData.certificatesUsedPayAsYouGo, billingOverviewData.certificatePayAsYouGoQuota)}"></div>
                </div>

                <div class="mt-4">
                  <ng-container *ngIf="billingOverviewData.certificatePayAsYouGoQuota > 0; else noCertificates" >
                    <p>
                      <span *ngIf="certPayAsYouGoQuotaRatio >=1">No certificates remaining. Use the button below to add more certificates.
                      </span>
                      <span *ngIf="certPayAsYouGoQuotaRatio >= .9 && certPayAsYouGoQuotaRatio <1" class="ApproachingLimit">{{billingOverviewData.certificatePayAsYouGoQuota - billingOverviewData.certificatesUsedPayAsYouGo }}  certificates remaining. </span>
                      <span *ngIf="certPayAsYouGoQuotaRatio < .9">{{billingOverviewData.certificatePayAsYouGoQuota - billingOverviewData.certificatesUsedPayAsYouGo }}  certificates remaining. </span> <ng-container *ngTemplateOutlet="LearnMoreLink"></ng-container> </p>
                  </ng-container>
                  
                  <ng-template #noCertificates>
                    <p>You have not purchased any Pay-As-You-Go Certificates. <ng-container *ngTemplateOutlet="LearnMoreLink"></ng-container></p>
                  </ng-template>
                  
                  <div class="py-4">
                    <button class="btn-primary-outlined medium" (click)="openPayAsYouGoTab()">Purchase Certificates</button>
                  </div>
                </div>

            </div>
          </div>
          <div *ngIf="planType === 'SeatBased' || planType === 'Enterprise'" class="flex justify-between w-full mt-8 gap-8">
            <div class="w-full lg:w-1/2">
                <div class="flex justify-between">
                    <h3 class="learner">Plan - Active Users</h3>
                    <h3 class="learner">
                      <span *ngIf="seatSubscriptionQuotaRatio >= 1" class="ApproachingLimit">Limit Reached </span>
                      <span *ngIf="seatSubscriptionQuotaRatio >= .9 && seatSubscriptionQuotaRatio < 1" class="ApproachingLimit">Approaching Limit </span>{{billingOverviewData.seatsUsedSubscription}} of {{billingOverviewData.seatSubscriptionQuota}}
                    </h3>
                </div>

                <div class="progress-bar-wrapper mt-3">
                    <div class="progress-bar" [ngStyle]="{'left': calculateProgressBar(billingOverviewData.seatsUsedSubscription, billingOverviewData.seatSubscriptionQuota)}"></div>
                </div>

                <div class="mt-4">
                  <p>
                    <span *ngIf="seatSubscriptionQuotaRatio >= 1">Plan limit reached. Upgrade your plan or contact us.  <ng-container *ngTemplateOutlet="LearnMoreLink"></ng-container>
                    </span>
                    <span *ngIf="seatSubscriptionQuotaRatio >= .9 && seatSubscriptionQuotaRatio < 1" class="ApproachingLimit">{{billingOverviewData.seatSubscriptionQuota - billingOverviewData.seatsUsedSubscription}} users remaining </span>
                    <span *ngIf="seatSubscriptionQuotaRatio < .9">{{billingOverviewData.seatSubscriptionQuota - billingOverviewData.seatsUsedSubscription}} users remaining </span>
                    <span *ngIf="seatSubscriptionQuotaRatio < 1">until your plan requires an upgrade. <ng-container *ngTemplateOutlet="LearnMoreLink"></ng-container></span>
                     </p>
                  <div class="py-4" *ngIf="planType === 'seatBased'">
                    <button class="btn-primary-outlined medium" (click)="manageSubscription()">Manage</button>
                  </div>
                </div>
            </div>
            
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Billing">
        <ng-template matTabContent>
          <app-billing [activeAccount]="hasActiveSubscription"></app-billing>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
