import { FormBuilder, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter, Input, DoCheck } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { GridOptions, GridParentEmitter } from 'src/app/api/interfaces/grid-options';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor, AsyncPipe, DatePipe, NgClass, NgStyle } from '@angular/common';
import { TruncateTextPipe } from 'src/app/api/pipes/truncate-text/truncate-text.pipe';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { FilterIconComponent } from '../../filter-icon/filter-icon.component';

@Component({
  selector: 'app-grid-template',
  templateUrl: './grid-template.component.html',
  styleUrls: ['./grid-template.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgStyle,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    NgFor,
    MatLegacyFormFieldModule,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    MatLegacyInputModule,
    MatCardModule,
    MatLegacyButtonModule,
    MatLegacyTableModule,
    MatSortModule,
    AsyncPipe,
    DatePipe,
    MatLegacyPaginatorModule,
    TruncateTextPipe,
    SpinnerComponent,
    FilterIconComponent,
  ]
})
export class GridTemplateComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
  @Input() gridOptions!: GridOptions;
  clearBtnFlag: boolean = true;
  dataSource!: MatTableDataSource<any>;
  smallScreen!: boolean;
  paginationForm = this.fb.group({
    pageSize: this.fb.control(20),
  });
  pageSizeOptions: Array<number> = [20, 40, 60, 100];
  currentPageNumber: number = 1;
  itemsFrom: number = 0;
  itemsTo: number = 0;
  itemsPerPage: number = 20;
  totalNumberOfItems: any = 0;
  totalNumberOfPages: any = 1;
  mobileData: any[] = [];
  visibleFormControlData!: any[];
  searchFormControlData!: any;
  hiddenFormControlData!: any[];
  displayedColumns: string[] = [];
  formValueChangesSub!: Subscription;
  
  @Output() actionEvent = new EventEmitter<GridParentEmitter>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;
  filtersForm: any;
  sortOrder: any;
  isLoading: boolean = false;
  hasFilters: boolean = false;
  disableCSV: boolean = false;

  constructor(
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private spinnerService: SpinnerService,
  ) {}

  ngOnInit(): void {
    this.spinnerService.whiteBackground();
    this.buildingFormFilter();
    this.gridOptions.columns.forEach((column) => {
      this.displayedColumns.push(column.controlName);
    });

    this.breakpointObserver.observe(['(min-width: 1024px)']).subscribe((result) => {
      this.smallScreen = !result.matches;
    });
    this.mobileData = [];

    this.paginationForm?.get('pageSize')?.valueChanges.subscribe((val: any) => {
      this.itemsPerPage = +this.paginationForm.get('pageSize')?.value!;
      this.totalNumberOfPages = this.totalNumberOfItems === 0 ? 1 : Math.ceil(this.totalNumberOfItems / this.itemsPerPage);
      if (this.currentPageNumber > this.totalNumberOfPages) {
        this.currentPageNumber = this.totalNumberOfPages;
      }
      this.currentPageNumber = 1;
      if (this.paginator && this.gridOptions.frontEndPagination) {
        this.paginator._changePageSize(this.itemsPerPage);
        this.paginator.firstPage();
      }
      this.paginationChange();
      if (this.gridOptions.data.data.length > 0 && !this.gridOptions.frontEndPagination && !this.isLoading) {
        this.fetchAll();
      }
    });

    this.formValueChangesSub = this.filtersForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(() => {
      this.currentPageNumber = 1;
      if (this.paginator && this.gridOptions.frontEndPagination) {
        this.paginator.firstPage();
      }
      this.sendFormChanges();
    });


  }

  ngAfterViewInit() {
    this.gridOptions.data.sort = this.matSort;
    if (this.gridOptions.frontEndPagination) {
      this.gridOptions.data.paginator = this.paginator;
    }
  }

  ngOnDestroy(): void {
    if (this.formValueChangesSub) {
      this.formValueChangesSub.unsubscribe();
    }
  }

  ngDoCheck() {
    if (this.gridOptions.frontEndPagination) {
      if (this.paginator) {
        this.gridOptions.totalNumberOfItems = this.paginator!.length;
      }
    }
    if (this.gridOptions.totalNumberOfItems !== this.totalNumberOfItems) {
      if ( this.gridOptions.frontEndPagination) {
        if (this.paginator) {
          this.currentPageNumber = 1;
          this.paginator.firstPage();
        }
      }
      this.paginationChange();
    }
    var i = 0;
    for (const filter of this.gridOptions.filterData) {
      if (filter.defaultValue !== undefined) {
        this.filtersForm.get(filter.formControlName)?.setValue(filter.defaultValue);
        this.gridOptions.filterData[i].defaultValue = undefined;
      }
      i++;
    }
    if (this.gridOptions.fetchData && !this.isLoading) {
      this.gridOptions.fetchData = false;
      this.fetchAll();
    }
    if (this.gridOptions.frontEndPagination) {
      this.mobileData = this.gridOptions.data.data.slice(this.itemsFrom - 1, this.itemsTo);
    } else {
      this.mobileData = this.gridOptions.data.data;
    }

    
    if (this.gridOptions.isLoading != this.isLoading) {
      this.isLoading = this.gridOptions.isLoading;
      if (this.isLoading) {
        this.spinnerService.show();
      } else {
        this.spinnerService.hide();
      }
    }
    if (!this.gridOptions.isLoading) {
      this.spinnerService.overlayBackground();
    }
    
  }

  buildingFormFilter() {
    const formControlsConfig: any = {};
    this.visibleFormControlData = [];
    this.hiddenFormControlData = [];
    if (this.gridOptions.filterData === undefined || this.gridOptions.filterData === null || this.gridOptions.filterData.length === 0) {
      this.hasFilters = false;
    } else {
      this.hasFilters = true;
    }
    this.gridOptions.filterData.forEach((filter) => {
      if (filter.dateOffset === undefined || filter.dateOffset === null) {
        filter.dateOffset = 0;
      }
      if (filter.type === 'date') {
        formControlsConfig[filter.formControlName] = [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + filter.dateOffset), Validators.required];
        this.visibleFormControlData.push(filter);
      } else if (filter.type === 'search') {
        formControlsConfig[filter.formControlName] = [''];
        this.searchFormControlData = filter;
      } else if (filter.type === 'searchDropdown') {
        formControlsConfig[filter.formControlName] = [''];
        this.hiddenFormControlData.push(filter);
      } else if (filter.type === 'checkBox') {
        formControlsConfig[filter.formControlName] = [false];
        this.hiddenFormControlData.push(filter);
      } else {
        formControlsConfig[filter.formControlName] = [null];
        this.hiddenFormControlData.push(filter);
      }
    });
    this.filtersForm = this.fb.group(formControlsConfig);
  }

  fetchAll() {
    const params = {
      ...this.filtersForm.value,
      pageNumber: this.currentPageNumber,
      pageSize: this.itemsPerPage,
      sortOrder: this.sortOrder,
      startDate: this.calculateFromDate(this.filtersForm.value.startDate),
      endDate: this.calculateToDate(this.filtersForm.value.endDate),
    };
    this.emitAction('fetchAll', params);
  }

  calculateFromDate(val: any) {
    // convert local date to UTC date
    if (val) {
      const date = new Date(val);
      date.setUTCHours(0, 0, 0, 0);
      const isoDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      return isoDate.toISOString();
    }
  }
  
  calculateToDate(val: any) {
    // convert local date to UTC date
    if (val) {
      const date = new Date(val);
      date.setUTCHours(23, 59, 9, 999);
      const isoDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      return isoDate.toISOString();
    }
  }

  utcToLocalDate(date: any) {
    // convert UTC date to local date
    if (date) {
      const localDate = new Date(date);
      return new Date(localDate.getTime() - (new Date().getTimezoneOffset()) * 60000);
    }
  }

  sendFormChanges() {
    const params = {
      ...this.filtersForm.value,
      pageNumber: this.currentPageNumber,
      pageSize: this.itemsPerPage,
      sortOrder: this.sortOrder,
    };
    this.emitAction('formChanges', params);
  }

  clearFilters() {
    this.itemsFrom = this.itemsTo = this.totalNumberOfItems = 0;
    this.currentPageNumber = this.totalNumberOfPages = 1;
    this.gridOptions.data.data = [];
    if (this.formValueChangesSub) {
      this.formValueChangesSub.unsubscribe();
    }
    this.buildingFormFilter();
    this.formValueChangesSub = this.filtersForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(() => {
      this.currentPageNumber = 1;
      if (this.paginator && this.gridOptions.frontEndPagination) {
        this.paginator.firstPage();
      }
      this.sendFormChanges();
    });
    this.emitAction('clearFilters');
    this.clearBtnFlag = true;
    this.gridOptions.isLoading = false;
    if (this.paginator && this.gridOptions.frontEndPagination) {
      this.paginator.firstPage();
    }
  }

  paginationRight() {
    if (this.currentPageNumber < this.totalNumberOfPages && !this.isLoading) {
      this.currentPageNumber++;
      this.paginationChange();
      if (!this.gridOptions.frontEndPagination) {
        this.fetchAll();
      } else {
        if (this.paginator && this.paginator.hasNextPage()) {
          this.paginator.nextPage();
        }
      }
    }
  }
  paginationLeft() {
    if (this.currentPageNumber > 1 && !this.isLoading) {
      this.currentPageNumber--;
      this.paginationChange();
      if (!this.gridOptions.frontEndPagination) {
        this.fetchAll();
      } else {
        if (this.paginator && this.paginator.hasPreviousPage()) {
          this.paginator.previousPage();
        }
      
      }
    }
  }

  paginationChange() {
    this.totalNumberOfItems = this.gridOptions.totalNumberOfItems;
    this.totalNumberOfPages = this.totalNumberOfItems === 0 ? 1 : Math.ceil(this.totalNumberOfItems / this.itemsPerPage);
    this.itemsFrom = ((this.currentPageNumber - 1) * this.itemsPerPage) + 1;
    if (this.totalNumberOfItems == 0) {
      this.itemsFrom = this.totalNumberOfItems;
    }
    this.itemsTo = this.itemsFrom + this.itemsPerPage - 1;
    if (this.itemsTo > this.totalNumberOfItems) {
      this.itemsTo = this.totalNumberOfItems;
    }
    if ( this.gridOptions.frontEndPagination) {
      this.mobileData = this.gridOptions.data.data.slice(this.itemsFrom - 1, this.itemsTo);
    }
  }

  sortData(sort: Sort) {
    this.sortOrder = sort;
    if (!this.gridOptions.frontEndPagination && !this.isLoading) {
      this.fetchAll();
    }
  }

  displayOptions(option: any): string {
    return option && option.name ? option.name : option?.length > 0 ? option : '';
  }

  searchButton() {
    this.currentPageNumber = 1;
    if (!this.gridOptions.frontEndPagination && !this.isLoading) {
      this.fetchAll();
    }
  }

  closeFilter() {
    this.gridOptions.openFilters = !this.gridOptions.openFilters;
  }

  actionButton(row: any, buttonAction: string) {
    this.emitAction(buttonAction, row);
  }

  exportCsv() {
    this.disableCSV = true;
    const params = {
      ...this.filtersForm.value,
      pageNumber: this.currentPageNumber,
      pageSize: this.itemsPerPage,
      sortOrder: this.sortOrder,
    };
    this.emitAction('exportCSV', params);

    setTimeout(() => {
      this.disableCSV = false;
    }, 3000);
  }

  addButton() {
    this.emitAction('addButton');
  }

  emitAction(ActionName: any, data: any = null) {
    this.actionEvent.emit({
      action: ActionName,
      data: data,
    });
  }

  toArray(obj: any): any[] {
    return Object.keys(obj).map(key => obj[key]);
  }

  isArray(list: any[]) {
    return Array.isArray(list);
  }
}
