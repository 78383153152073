import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { EntityCoursesStore, EntityCoursesState } from './entity-courses.store';
import { EntityCourseHistory, EntityCourseModule } from './entity-course.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EntityCoursesQuery extends QueryEntity<EntityCoursesState> {
  myEntityCourses$ = this.selectAll().pipe(
    map((entities) =>
      entities.filter((entity) => {
        return entity.selected;
      })
    )
  );

  allEntityCourses$ = this.selectAll().pipe(map((entities) => entities));

  currentEntityCourseModules$ = this.selectActive().pipe(
    filterNilValue(),
    distinctUntilChanged((entitiesA, entitiesB) => this.areEntitiesEqual(entitiesA, entitiesB)),
    map((entity) => {
      return (entity?.modules || []) as EntityCourseModule[];
    })
  );


  currentEntityCourse$ = this.selectActive().pipe(filterNilValue());

  constructor(protected store: EntityCoursesStore) {
    super(store);
  }

  private areEntitiesEqual(entitiesA: any, entitiesB: any): boolean {
    // Implement your custom equality logic here
    // For example, you can compare specific properties of the entities

    // Replace the following line with your custom equality check
    return JSON.stringify(entitiesA) === JSON.stringify(entitiesB);
  }
}