import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { AllCoursesService } from 'src/app/routes/learner/courses/components/courses/state/all-courses.service';
import { CoursesService } from 'src/app/routes/learner/courses/state/courses.service';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

@Component({
  selector: 'app-choose-course-text',
  templateUrl: './choose-course-text.component.html',
  styleUrls: ['./choose-course-text.component.scss'],
  standalone: true,
  imports: [
      NgClass,
      NgIf,
      MatIconModule,
      SnackbarComponent
  ],
})
export class ChooseCourseTextComponent  implements OnInit {
  courseSelected!: boolean;
  private _item: any;
  alreadyAdded: any;
  isEntityAdmin: any;
  isCoopAdmin: any;
  @Input() bottomBtn?: string;

  @Input() set item(item: any) {
    this._item = item;
  }

  @Output() selectCourse: EventEmitter<any> = new EventEmitter();

  get item() {
    return this._item;
  }

  constructor(
    private api: ApiService,
    private domSanitizer: DomSanitizer,
    private organizationService: OrganizationService,
    private snackBar: MatSnackBar,
    private coursesService: CoursesService,
    private allCoursesService: AllCoursesService
  ) {}

  ngOnInit(): void {
    this.isEntityAdmin = localStorage.getItem('roleType') === 'EntityAdmin';
    this.isCoopAdmin = localStorage.getItem('roleType') === 'CoopAdmin';
  }

  selectDiv(item: any) {
    if ((this.isEntityAdmin || this.isCoopAdmin) && window.location.pathname === '/trainings') {
      this.allCoursesService.updateCoursesActive(item.id);
    } else {
      this.coursesService.updateCoursesActive(item.id);
    }
  }

  icon(svg: any) {
    if (!svg) {
      return null;
    }
    return this.domSanitizer.bypassSecurityTrustHtml(svg);
  }

  svgIcon(icon: any) {
    return `/assets/images/individual-courses/${icon}.svg#Layer_1`;
  }

  getTransformedModuleNames(): string[] {
    return this.item.moduleNames.map(moduleName => 
      moduleName.includes('Exam') ? 'Exam' : moduleName
    );
  }

  // for EntityAdmins
  buttonAction(item: any) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    this.api.courseApiService
      .toggleActiveAndInactive({ entityGuid, courseId: item?.id })
      .pipe(
        catchError((e) => throwError(e)),

        switchMap(() => {
          return this.organizationService.getOrganizationCourses(entityGuid);
        })
      )
      .subscribe(
        () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Course Status Changed' }})
        },
        (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error Occured While Updating' }})
        }
      );
  }
}
