import { Component } from '@angular/core';
import { SelectCourseComponent } from '../../select-course/select-course.component';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';

@Component({
  selector: 'app-select-course-modal',
  templateUrl: './select-course-modal.component.html',
  styleUrls: ['./select-course-modal.component.scss'],
  standalone: true,
  imports: [
    SelectCourseComponent,
    ModalLayoutComponent
  ],
})
export class SelectCourseModalComponent {

}
