const activeImage = `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
<style type="text/css">
\t.st0{fill:#27B494;}
\t.st1{fill:#EE7284;}
\t.st2{fill:#fff;}
</style>
<g id="MandatedReporterTraining-GeneralTraining-icon" transform="translate(-124.792 -22.619)">
\t<g id="Group_772" transform="translate(130.267 40.075)">
\t\t<path id="Path_890" class="st0" d="M7.3,5.6l13.9,5.9c0.7,0.3,1.6,0,1.9-0.7c0,0,0,0,0,0c0.3-0.7,0-1.6-0.7-1.9L8.4,3
\t\t\tc-0.7-0.3-1.6,0-1.9,0.8C6.2,4.5,6.5,5.3,7.3,5.6L7.3,5.6z"/>
\t\t<path id="Path_891" class="st0" d="M32.1,18.6l-15.9-6.8c-0.7-0.3-1.6,0-1.9,0.8c-0.3,0.7,0,1.6,0.7,1.9L31,21.2
\t\t\tc0.7,0.3,1.6-0.1,1.8-0.8C33.1,19.7,32.8,18.9,32.1,18.6L32.1,18.6z"/>
\t\t<path id="Path_892" class="st0" d="M7.3,11l4.1,1.7c0.7,0.3,1.6,0,1.9-0.8c0.3-0.7,0-1.6-0.8-1.9l0,0L8.4,8.4
\t\t\tC7.6,8.1,6.8,8.5,6.5,9.2C6.2,9.9,6.6,10.7,7.3,11L7.3,11z"/>
\t\t<path id="Path_893" class="st0" d="M32.1,29.4l-7.4-3.1c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9L31,32
\t\t\tc0.7,0.3,1.6,0,1.9-0.7c0,0,0,0,0,0C33.2,30.5,32.8,29.7,32.1,29.4z"/>
\t\t<path id="Path_894" class="st0" d="M6.5,20c-0.3,0.7,0,1.6,0.7,1.9l12.6,5.4c0.7,0.3,1.6,0,1.9-0.7c0.3-0.7,0-1.6-0.8-1.9
\t\t\tL8.4,19.2C7.6,18.9,6.8,19.3,6.5,20z"/>
\t\t<path id="Path_895" class="st0" d="M32.1,34.8L8.4,24.7c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l0,0L31,37.4
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,35.5,32.6,35,32.1,34.8L32.1,34.8z"/>
\t\t<path id="Path_896" class="st0" d="M7.3,38.1l7.7,3.3c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7,0-1.5-0.7-1.8l-7.7-3.3
\t\t\tc-0.7-0.3-1.6,0-1.9,0.7C6.2,36.9,6.5,37.8,7.3,38.1L7.3,38.1L7.3,38.1z"/>
\t\t<path id="Path_897" class="st0" d="M32.1,45.6l-12.2-5.2c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9L31,48.2
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,46.3,32.6,45.8,32.1,45.6L32.1,45.6z"/>
\t\t<path id="Path_898" class="st0" d="M22.3,46.8L8.4,40.9c-0.7-0.3-1.6,0-1.9,0.8c-0.3,0.7,0,1.6,0.8,1.9l13.9,5.9
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C23.1,47.5,22.8,47,22.3,46.8L22.3,46.8z"/>
\t\t<path id="Path_899" class="st0" d="M52.3,11.7c0.2,0,0.4,0,0.6-0.1l13.9-5.9c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.7
\t\t\tL51.8,9c-0.7,0.3-1.1,1.1-0.8,1.9C51.3,11.4,51.8,11.7,52.3,11.7L52.3,11.7z"/>
\t\t<path id="Path_900" class="st0" d="M42.5,15.9c0.2,0,0.4,0,0.6-0.1l6-2.6c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.7
\t\t\tl-6,2.6c-0.7,0.3-1.1,1.1-0.8,1.9C41.4,15.5,41.9,15.9,42.5,15.9L42.5,15.9z"/>
\t\t<path id="Path_901" class="st0" d="M42.5,21.3c0.2,0,0.4,0,0.6-0.1L66.8,11c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.7
\t\t\tL41.9,18.6c-0.7,0.3-1.1,1.1-0.8,1.9C41.4,21,41.9,21.3,42.5,21.3L42.5,21.3z"/>
\t\t<path id="Path_902" class="st0" d="M42.5,26.7c0.2,0,0.4,0,0.6-0.1l15.9-6.8c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.8
\t\t\tL41.9,24c-0.7,0.3-1.1,1.1-0.8,1.9C41.4,26.4,41.9,26.7,42.5,26.7L42.5,26.7z"/>
\t\t<path id="Path_903" class="st0" d="M65.7,24.7L41.9,34.8c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1.1,1.1,1.9,0.8l0,0l23.7-10.1
\t\t\tc0.7-0.3,1.1-1.1,0.7-1.9C67.2,24.7,66.4,24.3,65.7,24.7L65.7,24.7L65.7,24.7z"/>
\t\t<path id="Path_904" class="st0" d="M65.7,30.1l-12.6,5.4c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1.1,1.1,1.9,0.8l0,0l12.6-5.4
\t\t\tc0.7-0.3,1.1-1.1,0.7-1.9C67.2,30.1,66.4,29.8,65.7,30.1L65.7,30.1z"/>
\t\t<path id="Path_905" class="st0" d="M65.7,35.5L41.9,45.6c-0.7,0.3-1.1,1.1-0.7,1.9c0.2,0.5,0.7,0.9,1.3,0.9c0.2,0,0.4,0,0.6-0.1
\t\t\tl23.7-10.1c0.7-0.3,1.1-1.1,0.7-1.9C67.2,35.5,66.4,35.2,65.7,35.5L65.7,35.5L65.7,35.5z"/>
\t\t<path id="Path_906" class="st0" d="M56.8,44.7L41.9,51c-0.7,0.3-1.1,1.1-0.8,1.9c0.2,0.5,0.7,0.9,1.3,0.9c0.2,0,0.4,0,0.6-0.1
\t\t\tl14.8-6.3c0.7-0.3,1.1-1.1,0.8-1.9C58.3,44.7,57.5,44.4,56.8,44.7L56.8,44.7z"/>
\t</g>
\t<g id="Group_773" transform="translate(130.267 47.643)">
\t\t<path id="Path_907" class="st1" d="M32.1,5.6l-6-2.6c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9c0,0,0,0,0,0l6,2.6
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,6.3,32.6,5.8,32.1,5.6L32.1,5.6z"/>
\t\t<path id="Path_908" class="st1" d="M32.1,16.4L8.4,6.3C7.6,6,6.8,6.3,6.5,7c-0.3,0.7,0,1.6,0.7,1.9L31,19c0.2,0.1,0.4,0.1,0.6,0.1
\t\t\tc0.8,0,1.4-0.6,1.4-1.4C33,17.1,32.6,16.6,32.1,16.4L32.1,16.4z"/>
\t\t<path id="Path_909" class="st1" d="M32.1,32.6L8.4,22.5c-0.3-0.1-0.7-0.2-1.1,0c-0.7,0.3-1.1,1.1-0.8,1.8c0.1,0.3,0.4,0.6,0.8,0.8
\t\t\tL31,35.2c0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,33.4,32.6,32.8,32.1,32.6z"/>
\t\t<path id="Path_910" class="st1" d="M32.1,43.4l-6-2.6c-0.7-0.3-1.6,0-1.9,0.8c-0.3,0.7,0,1.6,0.8,1.9l6,2.6
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,44.2,32.6,43.7,32.1,43.4L32.1,43.4z"/>
\t\t<path id="Path_911" class="st1" d="M62.2,10.7c0.2,0,0.4,0,0.6-0.1l4.1-1.7c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.8
\t\t\tc0,0,0,0,0,0L61.6,8c-0.7,0.3-1.1,1.1-0.7,1.9C61.1,10.4,61.6,10.7,62.2,10.7L62.2,10.7z"/>
\t\t<path id="Path_912" class="st1" d="M65.7,11.7L41.9,21.8c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1.1,1.1,1.9,0.8l0,0l23.7-10.1
\t\t\tc0.7-0.3,1.1-1.1,0.7-1.9C67.2,11.7,66.4,11.4,65.7,11.7L65.7,11.7L65.7,11.7z"/>
\t\t<path id="Path_913" class="st1" d="M50.4,29.5c-0.4-0.1-0.7-0.1-1.1,0l-7.4,3.1c-0.7,0.3-1.1,1.1-0.7,1.9c0.2,0.5,0.7,0.9,1.3,0.9
\t\t\tc0.2,0,0.4,0,0.6-0.1l7.4-3.1c0.7-0.3,1.1-1.1,0.8-1.9C51,29.9,50.7,29.6,50.4,29.5L50.4,29.5z"/>
\t\t<path id="Path_914" class="st1" d="M65.7,33.3l-5.1,2.2c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1.1,1.1,1.9,0.8l0,0l5.1-2.2
\t\t\tc0.7-0.3,1.1-1.1,0.8-1.9C67.2,33.3,66.4,33,65.7,33.3L65.7,33.3L65.7,33.3z"/>
\t</g>
\t<path id="Path_915" class="st2" d="M202.8,32.3L202.8,32.3c-0.4-0.3-0.9-0.3-1.3-0.1l-2.7,1.1v-3.1c0-0.8-0.6-1.4-1.4-1.4
\t\tc-0.2,0-0.4,0-0.6,0.1l-2.7,1.1V27c0-0.8-0.6-1.4-1.4-1.4c-0.2,0-0.4,0-0.6,0.1l-24.8,10.6l-24.8-10.6c-0.7-0.3-1.6,0-1.9,0.7
\t\tc-0.1,0.2-0.1,0.4-0.1,0.6v3.1l-2.7-1.1c-0.7-0.3-1.6,0-1.9,0.7c-0.1,0.2-0.1,0.4-0.1,0.6v3.1l-2.7-1.1c-0.7-0.3-1.6,0-1.9,0.7
\t\tc-0.1,0.2-0.1,0.4-0.1,0.6v55c0,0.6,0.3,1.1,0.9,1.3l34.7,14.8l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.4-0.1l0.1,0l-0.1-0.4
\t\tl0,0l0.2,0.3l34.7-14.8c0.5-0.2,0.9-0.7,0.9-1.3v-55C203.4,33,203.2,32.6,202.8,32.3z M165.9,49.3v51.9L134,87.5V35.7L165.9,49.3z
\t\t M138.7,32.4l28,12c0.4,0.2,0.8,0.2,1.1,0l28-12v2.2l-28.6,12.2l-28.6-12.2L138.7,32.4z M143.4,31.3v-2.2l23.4,10
\t\tc0.4,0.2,0.8,0.2,1.1,0l23.4-10v2.2l-23.9,10.2L143.4,31.3z M200.6,35.7v51.9l-31.8,13.6V49.3L200.6,35.7z"/>
</g>
</svg>

`;
const image = `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
<style type="text/css">
\t.st0{fill:#27B494;}
\t.st1{fill:#EE7284;}
\t.st2{fill:#221945;}
</style>
<g id="MandatedReporterTraining-GeneralTraining-icon" transform="translate(-124.792 -22.619)">
\t<g id="Group_772" transform="translate(130.267 40.075)">
\t\t<path id="Path_890" class="st0" d="M7.3,5.6l13.9,5.9c0.7,0.3,1.6,0,1.9-0.7c0,0,0,0,0,0c0.3-0.7,0-1.6-0.7-1.9L8.4,3
\t\t\tc-0.7-0.3-1.6,0-1.9,0.8C6.2,4.5,6.5,5.3,7.3,5.6L7.3,5.6z"/>
\t\t<path id="Path_891" class="st0" d="M32.1,18.6l-15.9-6.8c-0.7-0.3-1.6,0-1.9,0.8c-0.3,0.7,0,1.6,0.7,1.9L31,21.2
\t\t\tc0.7,0.3,1.6-0.1,1.8-0.8C33.1,19.7,32.8,18.9,32.1,18.6L32.1,18.6z"/>
\t\t<path id="Path_892" class="st0" d="M7.3,11l4.1,1.7c0.7,0.3,1.6,0,1.9-0.8c0.3-0.7,0-1.6-0.8-1.9l0,0L8.4,8.4
\t\t\tC7.6,8.1,6.8,8.5,6.5,9.2C6.2,9.9,6.6,10.7,7.3,11L7.3,11z"/>
\t\t<path id="Path_893" class="st0" d="M32.1,29.4l-7.4-3.1c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9L31,32
\t\t\tc0.7,0.3,1.6,0,1.9-0.7c0,0,0,0,0,0C33.2,30.5,32.8,29.7,32.1,29.4z"/>
\t\t<path id="Path_894" class="st0" d="M6.5,20c-0.3,0.7,0,1.6,0.7,1.9l12.6,5.4c0.7,0.3,1.6,0,1.9-0.7c0.3-0.7,0-1.6-0.8-1.9
\t\t\tL8.4,19.2C7.6,18.9,6.8,19.3,6.5,20z"/>
\t\t<path id="Path_895" class="st0" d="M32.1,34.8L8.4,24.7c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l0,0L31,37.4
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,35.5,32.6,35,32.1,34.8L32.1,34.8z"/>
\t\t<path id="Path_896" class="st0" d="M7.3,38.1l7.7,3.3c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7,0-1.5-0.7-1.8l-7.7-3.3
\t\t\tc-0.7-0.3-1.6,0-1.9,0.7C6.2,36.9,6.5,37.8,7.3,38.1L7.3,38.1L7.3,38.1z"/>
\t\t<path id="Path_897" class="st0" d="M32.1,45.6l-12.2-5.2c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9L31,48.2
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,46.3,32.6,45.8,32.1,45.6L32.1,45.6z"/>
\t\t<path id="Path_898" class="st0" d="M22.3,46.8L8.4,40.9c-0.7-0.3-1.6,0-1.9,0.8c-0.3,0.7,0,1.6,0.8,1.9l13.9,5.9
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C23.1,47.5,22.8,47,22.3,46.8L22.3,46.8z"/>
\t\t<path id="Path_899" class="st0" d="M52.3,11.7c0.2,0,0.4,0,0.6-0.1l13.9-5.9c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.7
\t\t\tL51.8,9c-0.7,0.3-1.1,1.1-0.8,1.9C51.3,11.4,51.8,11.7,52.3,11.7L52.3,11.7z"/>
\t\t<path id="Path_900" class="st0" d="M42.5,15.9c0.2,0,0.4,0,0.6-0.1l6-2.6c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.7
\t\t\tl-6,2.6c-0.7,0.3-1.1,1.1-0.8,1.9C41.4,15.5,41.9,15.9,42.5,15.9L42.5,15.9z"/>
\t\t<path id="Path_901" class="st0" d="M42.5,21.3c0.2,0,0.4,0,0.6-0.1L66.8,11c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.7
\t\t\tL41.9,18.6c-0.7,0.3-1.1,1.1-0.8,1.9C41.4,21,41.9,21.3,42.5,21.3L42.5,21.3z"/>
\t\t<path id="Path_902" class="st0" d="M42.5,26.7c0.2,0,0.4,0,0.6-0.1l15.9-6.8c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.8
\t\t\tL41.9,24c-0.7,0.3-1.1,1.1-0.8,1.9C41.4,26.4,41.9,26.7,42.5,26.7L42.5,26.7z"/>
\t\t<path id="Path_903" class="st0" d="M65.7,24.7L41.9,34.8c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1.1,1.1,1.9,0.8l0,0l23.7-10.1
\t\t\tc0.7-0.3,1.1-1.1,0.7-1.9C67.2,24.7,66.4,24.3,65.7,24.7L65.7,24.7L65.7,24.7z"/>
\t\t<path id="Path_904" class="st0" d="M65.7,30.1l-12.6,5.4c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1.1,1.1,1.9,0.8l0,0l12.6-5.4
\t\t\tc0.7-0.3,1.1-1.1,0.7-1.9C67.2,30.1,66.4,29.8,65.7,30.1L65.7,30.1z"/>
\t\t<path id="Path_905" class="st0" d="M65.7,35.5L41.9,45.6c-0.7,0.3-1.1,1.1-0.7,1.9c0.2,0.5,0.7,0.9,1.3,0.9c0.2,0,0.4,0,0.6-0.1
\t\t\tl23.7-10.1c0.7-0.3,1.1-1.1,0.7-1.9C67.2,35.5,66.4,35.2,65.7,35.5L65.7,35.5L65.7,35.5z"/>
\t\t<path id="Path_906" class="st0" d="M56.8,44.7L41.9,51c-0.7,0.3-1.1,1.1-0.8,1.9c0.2,0.5,0.7,0.9,1.3,0.9c0.2,0,0.4,0,0.6-0.1
\t\t\tl14.8-6.3c0.7-0.3,1.1-1.1,0.8-1.9C58.3,44.7,57.5,44.4,56.8,44.7L56.8,44.7z"/>
\t</g>
\t<g id="Group_773" transform="translate(130.267 47.643)">
\t\t<path id="Path_907" class="st1" d="M32.1,5.6l-6-2.6c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9c0,0,0,0,0,0l6,2.6
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,6.3,32.6,5.8,32.1,5.6L32.1,5.6z"/>
\t\t<path id="Path_908" class="st1" d="M32.1,16.4L8.4,6.3C7.6,6,6.8,6.3,6.5,7c-0.3,0.7,0,1.6,0.7,1.9L31,19c0.2,0.1,0.4,0.1,0.6,0.1
\t\t\tc0.8,0,1.4-0.6,1.4-1.4C33,17.1,32.6,16.6,32.1,16.4L32.1,16.4z"/>
\t\t<path id="Path_909" class="st1" d="M32.1,32.6L8.4,22.5c-0.3-0.1-0.7-0.2-1.1,0c-0.7,0.3-1.1,1.1-0.8,1.8c0.1,0.3,0.4,0.6,0.8,0.8
\t\t\tL31,35.2c0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,33.4,32.6,32.8,32.1,32.6z"/>
\t\t<path id="Path_910" class="st1" d="M32.1,43.4l-6-2.6c-0.7-0.3-1.6,0-1.9,0.8c-0.3,0.7,0,1.6,0.8,1.9l6,2.6
\t\t\tc0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.4-0.6,1.4-1.4C33,44.2,32.6,43.7,32.1,43.4L32.1,43.4z"/>
\t\t<path id="Path_911" class="st1" d="M62.2,10.7c0.2,0,0.4,0,0.6-0.1l4.1-1.7c0.7-0.3,1.1-1.1,0.7-1.9c-0.3-0.7-1.1-1.1-1.9-0.8
\t\t\tc0,0,0,0,0,0L61.6,8c-0.7,0.3-1.1,1.1-0.7,1.9C61.1,10.4,61.6,10.7,62.2,10.7L62.2,10.7z"/>
\t\t<path id="Path_912" class="st1" d="M65.7,11.7L41.9,21.8c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1.1,1.1,1.9,0.8l0,0l23.7-10.1
\t\t\tc0.7-0.3,1.1-1.1,0.7-1.9C67.2,11.7,66.4,11.4,65.7,11.7L65.7,11.7L65.7,11.7z"/>
\t\t<path id="Path_913" class="st1" d="M50.4,29.5c-0.4-0.1-0.7-0.1-1.1,0l-7.4,3.1c-0.7,0.3-1.1,1.1-0.7,1.9c0.2,0.5,0.7,0.9,1.3,0.9
\t\t\tc0.2,0,0.4,0,0.6-0.1l7.4-3.1c0.7-0.3,1.1-1.1,0.8-1.9C51,29.9,50.7,29.6,50.4,29.5L50.4,29.5z"/>
\t\t<path id="Path_914" class="st1" d="M65.7,33.3l-5.1,2.2c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1.1,1.1,1.9,0.8l0,0l5.1-2.2
\t\t\tc0.7-0.3,1.1-1.1,0.8-1.9C67.2,33.3,66.4,33,65.7,33.3L65.7,33.3L65.7,33.3z"/>
\t</g>
\t<path id="Path_915" class="st2" d="M202.8,32.3L202.8,32.3c-0.4-0.3-0.9-0.3-1.3-0.1l-2.7,1.1v-3.1c0-0.8-0.6-1.4-1.4-1.4
\t\tc-0.2,0-0.4,0-0.6,0.1l-2.7,1.1V27c0-0.8-0.6-1.4-1.4-1.4c-0.2,0-0.4,0-0.6,0.1l-24.8,10.6l-24.8-10.6c-0.7-0.3-1.6,0-1.9,0.7
\t\tc-0.1,0.2-0.1,0.4-0.1,0.6v3.1l-2.7-1.1c-0.7-0.3-1.6,0-1.9,0.7c-0.1,0.2-0.1,0.4-0.1,0.6v3.1l-2.7-1.1c-0.7-0.3-1.6,0-1.9,0.7
\t\tc-0.1,0.2-0.1,0.4-0.1,0.6v55c0,0.6,0.3,1.1,0.9,1.3l34.7,14.8l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.4-0.1l0.1,0l-0.1-0.4
\t\tl0,0l0.2,0.3l34.7-14.8c0.5-0.2,0.9-0.7,0.9-1.3v-55C203.4,33,203.2,32.6,202.8,32.3z M165.9,49.3v51.9L134,87.5V35.7L165.9,49.3z
\t\t M138.7,32.4l28,12c0.4,0.2,0.8,0.2,1.1,0l28-12v2.2l-28.6,12.2l-28.6-12.2L138.7,32.4z M143.4,31.3v-2.2l23.4,10
\t\tc0.4,0.2,0.8,0.2,1.1,0l23.4-10v2.2l-23.9,10.2L143.4,31.3z M200.6,35.7v51.9l-31.8,13.6V49.3L200.6,35.7z"/>
</g>
</svg>

`;

export const mockProducts = [
  {
    id: 0,
    icon: image,
    activeIcon: activeImage,
    name: 'Online Training',
  },
  {
    id: 1,
    icon: image,
    activeIcon: activeImage,
    name: 'Courseware Download',
  },
  {
    id: 2,
    icon: image,
    activeIcon: activeImage,
    name: 'Custom LMS',
  },
];

export const mockCourses = [
  {
    id: 0,
    icon: image,
    activeIcon: activeImage,
    name: 'General Training',
  },
  {
    id: 1,
    icon: image,
    activeIcon: activeImage,
    name: 'Human Resources',
  },
  {
    id: 2,
    icon: image,
    activeIcon: activeImage,
    name: 'School Personel',
  },
  {
    id: 4,
    icon: image,
    activeIcon: activeImage,
    name: 'Child Care Providers',
  },
  {
    id: 5,
    icon: image,
    activeIcon: activeImage,
    name: 'Medical Professionals',
  },
  {
    id: 6,
    icon: image,
    activeIcon: activeImage,
    name: 'Mental Health & Social Workers',
  },
  {
    id: 7,
    icon: image,
    activeIcon: activeImage,
    name: 'Law Enforcement',
  },
  {
    id: 8,
    icon: image,
    activeIcon: activeImage,
    name: 'Clergy',
  },
  {
    id: 9,
    icon: image,
    activeIcon: activeImage,
    name: 'Volunteers',
  },
  {
    id: 10,
    icon: image,
    activeIcon: activeImage,
    name: 'Other',
  },
];


