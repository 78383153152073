import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  private _messages$ = new BehaviorSubject<string[]>([]);
  constructor() { }

  log(message: string) {
    this.messages$.next([...this.messages$.value, message]);
  }

  clear() {
    this.messages$.next([]);
  }

  getMessages() {
    return this.messages$.value;
  }

  get messages$() {
    return this._messages$;
  }

  get hasMessages$(): Observable<boolean> {
    return this.messages$.pipe(map((messages) => messages.length > 0));
  }
}
