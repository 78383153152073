import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class UserInvitesService extends BaseAPI {
  getAll(guid: string | null, paramsRequested: any) {
    return this.http.get(`EntityAdminUserInvite/userinvites-grid/${guid}`, {
      params: paramsRequested,
    });
  }

  resendInvitation(formValues: any) {
    return this.http.post(`EntityAdminUserInvite/resend`, formValues);
  }

  cancelInvite(inviteGuid: any) {
    return this.http.post(`EntityAdminUserInvite/cancel/${inviteGuid}`, null);
  }

}
