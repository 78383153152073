import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoursesQuery } from '../../../routes/learner/courses/state/courses.query';
import { CourseApiService } from '../../../api/services/courses/course-api.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoursesService } from '../../../routes/learner/courses/state/courses.service';
import { LookupsQuery } from 'src/app/lookups/lookups.query';
import { LookupsStore } from 'src/app/lookups/lookups.store';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { AuthQuery } from '../../../routes/authentication/state/auth.query';
import { UserStateService } from 'src/app/api/services/user-state/user-state.service';
import { AuthService } from 'src/app/routes/authentication/state/auth.service';
import { AllCoursesService } from '../../../routes/learner/courses/components/courses/state/all-courses.service';
import { AllCoursesQuery } from '../../../routes/learner/courses/components/courses/state/all-courses.query';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ChooseCourseTextComponent } from '../choose-course-text/choose-course-text.component';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ModalLayoutComponent } from '../modals/modal-layout/modal-layout.component';
import { UserCascadingDropdownsComponent } from 'src/app/shared/components/user-cascading-dropdowns/user-cascading-dropdowns.component';

@UntilDestroy()
@Component({
    selector: 'app-select-course',
    templateUrl: './select-course.component.html',
    styleUrls: ['./select-course.component.scss'],
    standalone: true,
    imports: [
        ModalLayoutComponent,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        MatLegacyFormFieldModule,
        MatLegacySelectModule,
        NgFor,
        MatLegacyOptionModule,
        ChooseCourseTextComponent,
        MatLegacyProgressSpinnerModule,
        AsyncPipe,
        UserCascadingDropdownsComponent,
    ],
})
export class SelectCourseComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  courseIdsFilter!: Subscription;
  public selectCourseForm: UntypedFormGroup = Object.create(null);
  courseID!: number;
  coursesToAdd: any[] = [];
  availableCourses: any[] = [];
  availableCoursesFiltered: any[] = [];
  disableAddTrainingBtn: boolean = true;
  addTrainingLoading: boolean = false;
  trainingCoursesFound: boolean = false;
  defaultLanguage: number = 1; //English
  isIndividual!: boolean;
  isEntityAdmin!: boolean;
  isCoopAdmin!: boolean;
  showTypeAndState!: boolean;
  currentCourseService!: any;
  currentCourseQuery!: any;
  previousFormValues: any;
  courseIdsFiltered!: any[];
  @Input() coopFilter: boolean = false;
  @Input('coopCourseIds') coopCourseIds$ = new Observable<number[]>();

  filtersForm = this.fb.group({
    stateId: this.fb.control(null),
    industryId: this.fb.control(null),
    professionId: this.fb.control({ value: null, disabled: true }),
    language: this.fb.control(null),
  });

  get selectedLanguage() {
    return this.filtersForm.get('language')?.value;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SelectCourseComponent>,
    public courseApiService: CourseApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public organizationService: OrganizationService,
    private coursesService: CoursesService,
    public coursesQuery: CoursesQuery,
    public allCoursesService: AllCoursesService,
    public allCoursesQuery: AllCoursesQuery,
    private lookupsStore: LookupsStore,
    public lookupsQuery: LookupsQuery,
    public lookupsService: LookupsService,
    private authService: AuthService,
    private AuthQuery: AuthQuery,
    private userStateService: UserStateService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.courseIdsFilter) {
      this.courseIdsFilter.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.isIndividual = localStorage.getItem('entityType') === 'Individual' && !this.data.forcedLearner;
    this.isEntityAdmin = localStorage.getItem('roleType') === 'EntityAdmin' && !this.data.forcedLearner;
    this.isCoopAdmin = localStorage.getItem('roleType') === 'CoopAdmin' && !this.data.forcedLearner;
    
    this.showTypeAndState = this.isEntityAdmin || (this.isIndividual && !this.coopFilter) || this.isCoopAdmin;

    const entityGuid = window.localStorage.getItem('entityGuid');

    if (this.showTypeAndState) {
      this.organizationService
        .getOrg(entityGuid!)
        .pipe(takeUntil(this.destroy$))
        .subscribe((org: any) => {
          this.filtersForm.get('stateId')?.setValue(org.stateId);
        });
    } else {
      this.filtersForm.get('stateId')?.setValue(null);
    }

    if (this.isEntityAdmin || this.isCoopAdmin) {
      this.currentCourseService = this.allCoursesService;
      this.currentCourseQuery = this.allCoursesQuery;
    } else {
      this.currentCourseService = this.coursesService;
      this.currentCourseQuery = this.coursesQuery;
    }

    if (!this.isEntityAdmin && !this.isCoopAdmin) {
      this.AuthQuery.user$.subscribe((user) => {
        const userState = user?.stateSettings?.filter((item: any) => item.key === 'defaultLanguage');

        if (userState[0]) {
          this.defaultLanguage = +userState[0].value;
          this.filtersForm.get('language')?.setValue(this.defaultLanguage);
        } else {
          this.filtersForm.get('language')?.setValue(this.defaultLanguage);
        }
      });
    }

    combineLatest([this.currentCourseQuery.allCourses$, this.coopFilter? this.coopCourseIds$ : this.currentCourseQuery.allCourses$])
      .pipe(
        //@ts-ignore
        map(([courses, coopCourseIds]) => {
          if (this.coopFilter) {
            // @ts-ignore
            courses = courses.filter((course) => coopCourseIds.includes(course.id));
          }

          if (courses?.length === 0) {
            return;
          }

          if (courses?.length > 0) {
            this.trainingCoursesFound = true;
          }
          this.availableCourses = courses;

          this.coursesToAdd = courses
            .map((course) => {
              if (course.courseSelected) {
                return course;
              }
            })
            .filter(Boolean);

          return courses.map((course) => course.courseSelected);
        }),
        tap((courses: any) => {
          this.filterAvailableCourses(this.filtersForm.getRawValue());
          if (courses?.includes(true)) {
            this.disableAddTrainingBtn = false;
          } else {
            this.disableAddTrainingBtn = true;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.selectCourseForm = this.fb.group({});

    this.filtersForm.valueChanges.subscribe((form) => {
      this.filterAvailableCourses(form);
    });
  }

  filterAvailableCourses(form: any) {
    if (!this.showTypeAndState) {
      this.availableCoursesFiltered = this.availableCourses.filter(
        (course: any) =>
          Object.keys(course.languages!).some((l) => (form.language ? l == form.language : true))
      );
      if (this.availableCoursesFiltered?.length > 0) {
        this.trainingCoursesFound = true;
      } else {
        this.trainingCoursesFound = false;
      }
      return;
    }
    if (form.stateId) {
      if (this.previousFormValues) {
        if (
          this.previousFormValues.stateId === form.stateId &&
          this.previousFormValues.industryId === form.industryId &&
          this.previousFormValues.professionId === form.professionId) {
          if (this.courseIdsFiltered) {
            this.availableCoursesFiltered = this.availableCourses.filter(
              (course: any) =>
                this.courseIdsFiltered.includes(course.id) &&
              Object.keys(course.languages!).some((l) => (form.language ? l == form.language : true))
            );
            if (this.availableCoursesFiltered?.length > 0) {
              this.trainingCoursesFound = true;
            } else {
              this.trainingCoursesFound = false;
            }
          }
          return;
        }
      }
      this.previousFormValues = form;
      const params = {
        StateId: form.stateId ?? '',
        IndustryId: form.industryId ?? '',
        ProfessionId: form.professionId ?? ''
      }
      if (this.courseIdsFilter) {
        this.courseIdsFilter.unsubscribe();
      }
      this.courseIdsFilter = this.lookupsService.courseIdsFilter(params).subscribe((courseIds) => {
        this.courseIdsFiltered = courseIds;
        this.availableCoursesFiltered = this.availableCourses.filter(
          (course: any) =>
            courseIds.includes(course.id) &&
          Object.keys(course.languages!).some((l) => (form.language ? l == form.language : true))
        );
        if (this.availableCoursesFiltered?.length > 0) {
          this.trainingCoursesFound = true;
        } else {
          this.trainingCoursesFound = false;
        }
      });
    }
  }

  addCoursesLearner() {
    this.addTrainingLoading = true;
    if (this.data.componentType === 'select course') {
      const addCourseCalls = this.coursesToAdd
        .filter((c) => c)
        .map((course) => {
          this.courseID = course.id;
          return { id: course.id, languageId: this.selectedLanguage ?? 1 };
        });

      this.courseApiService
        .addCourse(addCourseCalls)
        .pipe(
          tap(() => {
            this.coursesService.ResetAllCoursesActive();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.userStateService
            .userState({ key: 'defaultLanguage', value: this.selectedLanguage ?? 1 })
            .subscribe(() => this.authService.userState());
          this.coursesService.getAllEntityCourses().subscribe();
          this.dialogRef.close({ event: 'course-added', courseID: this.courseID });
          let viewVideoBio = window.document.querySelector<any>('.view-video-bio');
          if (viewVideoBio)
            viewVideoBio.parentNode.style.zIndex = '1000';
          this.addTrainingLoading = false;
        });
    }
  }

  addCoursesOrgAdmin() {
    this.addTrainingLoading = true;
    if (this.data.componentType === 'select course') {
      const addCourseCalls: number[] = this.coursesToAdd
        .filter((c) => c)
        .map((course) => {
          this.courseID = course.id;
          return course.id;
        });

      this.courseApiService
        .addOrgCourses(addCourseCalls)
        .pipe(
          tap(() => {
            this.allCoursesService.resetAllCourses();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.dialogRef.close({ event: 'course-added', courseID: this.courseID });
          let viewVideoBio = window.document.querySelector<any>('.view-video-bio');
          if (viewVideoBio)
            viewVideoBio.parentNode.style.zIndex = '1000';
          this.addTrainingLoading = false;
        });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
