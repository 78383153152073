import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map } from 'rxjs';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';

export const subscriptionGuard: CanActivateFn = (route, state) => {
  const subscription$ = inject(SubscriptionQuery).subscriptionUsage$;
  const router = inject(Router);
  return subscription$.pipe(
    map((subscription) => {
      if (subscription?.hasActiveSubscription || !subscription?.isTrialOver || (subscription?.certificateQuota != undefined && subscription?.certificateQuota > 0)) {
        if(state.url === '/subscribe') {
          return router.createUrlTree([route.data.redirectTo || '/organization/account-billing']);
        }
        return true;
      } else {
        if(state.url === '/subscribe') {
          return true;
        }
        return router.createUrlTree([route.data.redirectTo || '/subscribe']);
      }
    })
  );
};
