import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { TrainingMetric } from './training-metric.model';
import { TrainingMetricsStore } from './training-metrics.store';

@Injectable({ providedIn: 'root' })
export class TrainingMetricsService {
  constructor(private trainingMetricsStore: TrainingMetricsStore, private apiService: ApiService) {}

  getTrainingMetricsReport(requestParams: any) {
    return this.apiService.trainingMetricsService.getReport(requestParams);
  }
}
