import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'localDateFormat',
    standalone: true,
})
export class LocalDateFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const browserLanguage = navigator.language;
    // mm/dd/yyyy
    const utcDate = value;
    let date = new Date(utcDate).toLocaleDateString(browserLanguage);
    var d = new Date(utcDate);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    let time = d;
    if (args === 'full') {
      const totalTimeAndDate = `${this.extractDate(date)} ${this.extractTime(time)}`;
      return totalTimeAndDate;
    } else {
      const totalDate = `${this.extractDate(utcDate)}`;
      return totalDate;
    }
  }

  extractDate(date: any) {
    const dateSplit = date.split('-');
    const firstArr = dateSplit[0];
    const secondArr = dateSplit[1];
    const year = dateSplit[2];
    const conFirstArr = +firstArr;
    const conSecondArr = +secondArr;
    let newFirstArr = '';
    let newSecondArr = '';
    if (conFirstArr < 10) {
      newFirstArr = `${firstArr}`;
    } else {
      newFirstArr = `${firstArr}`;
    }
    if (conSecondArr < 10) {
      newSecondArr = `${secondArr}`;
    } else {
      newSecondArr = `${secondArr}`;
    }

    return `${newFirstArr}/${newSecondArr}/${year}`;
  }

  extractTime(time: any) {
    let h = time.getHours() - 1;
    let amPm = h >= 12 ? 'AM' : 'PM';
    let newHours = 0;
    let newMinutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : `${time.getMinutes()}`;
    if (h > 12) {
      let hh = h - 12;
      newHours = hh;
    } else {
      newHours = h;
    }

    let newTime = `${newHours}:${newMinutes} ${amPm}`;
    return `${newTime}`;
  }
}
