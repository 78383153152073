<!-- Breadcrumb -->
<div *ngIf="pageInfo?.urls" class="content-container mb-0">
  <div class="flex flex-wrap">
    <div class="">
      <div class="breadcrumb flex flex-wrap align-items-center">
        <h1 class="sr-only">{{ pageInfo.urls[0]?.title }} - {{ pageInfo.urls[1]?.title }}</h1>
        <ul class="list-style-none flex flex-wrap  text-black font-bold items-center">
          <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
            <li class="breadcrumb-item flex items-center capitalize" *ngIf="!last" [routerLink]="url.url">
              <a [routerLink]="url.url">{{ url.title }}</a>
              <span class="flex pr-1 pl-1 breadcrumb-item">
                <mat-icon> navigate_next </mat-icon>
              </span>
            </li>
            <li class="breadcrumb-item capitalize cursor-default active" *ngIf="last">{{ url.title }}</li>
          </ng-template>
        </ul>
      </div>
    </div>
  </div>
</div>
