<footer
  class="border-t border-color-ddd flex flex-col lg:flex-row text-primary items-start lg:items-center justify-start lg:justify-center copywrite p-3 pr-8">
  <div class="copywrite">
    <p class="small mb-0"> &copy; {{ date }} Mandated Reporter Training</p>
  </div>
  <div class="copywrite">
    <ng-container *ngIf="!loggedIn">
      <p class="small lg:flex flex-col lg:flex-row">
        <a href="https://mandatedreportertraining.com/company/terms-of-use" target="_blank"
          class="primary-link"><span class="mx-1 hidden lg:inline-block font-normal">|</span>
          Terms <span class="hidden lg:inline-block">Of Use</span>
          <span class="mx-1 font-normal ">|</span></a>
        <a href="https://mandatedreportertraining.com/company/privacy-statement" target="_blank"
          class="primary-link">Privacy <span class="hidden lg:inline-block">Statement</span>
          <span class="mx-1 font-normal">|</span></a>
          <a href="https://mandatedreportertraining.com/company/accessibility" target="_blank"
          class="primary-link">Accessibility</a>
      </p>
    </ng-container>
  </div>
  <p class="small copywrite">
    <span class="mx-1 hidden lg:inline-block">|</span>
    <span class="mr-1 w-full">Powered by <a href="https://simplelearning.com" target="_blank"
        class="primary-link">Simple</a>
    </span>

    <span class="copywrite" *ngIf="!loggedIn">
      <span class="mx-1 font-normal">|</span>
       v.{{ versionNumber.number }} rel. {{ versionNumber.date | date : 'MM.dd.yy' }}
    </span>
  </p>
</footer>