<app-modal-layout>
  <h1 class="text-center text-primary">Select Training</h1>
  <h4 class="text-center my-md hidden lg:block">Select the training(s) you wish to add below then click “Add Training”.</h4>
  <div class="lg:p-5">
    <div class="grid grid-rows-columns lg:ml-auto lg:mr-auto justify-center gap-8">
      <div
        class="w-full h-full transform transition lg:hover:scale-110"
        *ngFor="let course of organizationQuery.activeCourses$ | async"
      >
        <div class="flex flex-col h-full">
          <div
            class="p-3 flex-1 sm:transform sm:transition mobile-choose-course flex items-center justify-center bg-color-lightBlue py-lg text-center cursor-pointer relative choose-course h-full mx-auto sm:mx-0 w-full"
            [ngClass]="
              course?.alreadyUserCourses
                ? 'course-active-element disabled'
                : course?.editCourse
                ? 'course-active-element'
                : ''
            "
            (click)="selectCourse(course)"
          >
          <div [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : 'text-primary'">
            <div>
              <div *ngIf="course?.isCE" class="flex text-left justify-start items-center">
                <p class="small">
                  <mat-icon class="round-active-text font-weight-bold leading-none"
                    [ngClass]="course?.alreadyUserCourses || course?.editCourse ? 'check-icon-text-outline text-secondary': 'check-icon-text-filled text-primary'"
                  >done</mat-icon>
                  <span [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : 'text-primary'">CE Approved</span>
                </p>
              </div>
            </div>
            <div class="flex">
              <p class="bold"
              [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : ''">
                {{ course?.trainingHeader}}
                {{ course?.state !== 'NATL' ? ' | ' + course?.state : ''}}
              </p>
            </div>
            <h2 class="learner leading-none font-semibold capitalize text-left"
              [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : ''">{{ course.name }}</h2>
            <p class="text-left mb-0 mt-3" [ngClass]="course?.alreadyUserCourses || course?.editCourse ? ' text-white' : ''"
              >{{course?.moduleNames.length}} {{course?.moduleNames.length !== 1 ? 'Modules:': 'Module:'}} {{course?.moduleNames.join(', ')}}</p>
            <mat-icon
              class="check-icon-text-filled round-active-icon-text font-weight-bold leading-none text-primary"
            >
              done
            </mat-icon>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-center text-center mb-16">
    <button class="btn-primary large" [disabled]="addCoursesBtn || course.length === 0"
      (click)="selectTraining()">
Select Training
    </button>
  </div>
</app-modal-layout>
