import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserGroupsStore, UserGroupsState } from './user-groups.store';

@Injectable({ providedIn: 'root' })
export class UserGroupsQuery extends QueryEntity<UserGroupsState> {

  constructor(protected store: UserGroupsStore) {
    super(store);
  }

}
