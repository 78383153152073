import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { AddUser, SingleUser } from './add-user.model';
import { AddUsersStore } from './add-users.store';
import { ApiService } from '../../../../../../api/services/api.service';
import { LocalDateFormatPipe } from '../../../../../../shared/pipes/local-date-format/local-date-format.pipe';

@Injectable({ providedIn: 'root' })
export class AddUsersService {
  constructor(
    private addUsersStore: AddUsersStore,
    private api: ApiService,
    private localDateFormatPipe: LocalDateFormatPipe
  ) {}

  addOrgUser(orgUser: SingleUser) {
    return this.api.organization.addOrg(orgUser);
  }
  addOrgUsers(orgUsers: any[]) {
    return this.api.organization.addOrgUsers(orgUsers);
  }

  joinOrgUser(orgUser: any) {
    return this.api.organization.adduser(orgUser);
  }

  getUsers(requestedBody: any) {
    return this.api.organization.getOrgUsers(requestedBody).pipe(
      tap((entities: any) => {
        const newEntity = [{ ...entities }];
        const { rows, totalNumberOfItems } = newEntity[0];
        const newUsers = rows.map((user: any) => {
          const { associatedDate, ...remaining } = user;
          return {
            associatedDate: associatedDate ? this.localDateFormatPipe.transform(associatedDate) : null,
            ...remaining,
          };
        });

        const newEntities = [
          {
            rows: newUsers,
            totalNumberOfItems,
          },
        ];
        // @ts-ignore
        this.addUsersStore.set(newEntities);
      })
    );
  }

  getAllExcelUsers(requestedBody: any) {
    return this.api.organization.getAllExcelOrgUsers(requestedBody);
  }

  getUser(entityUserId: any) {
    return this.api.organization.getuser(entityUserId);
  }
}
