import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, of, throwError } from 'rxjs';
import { catchError, take, tap, map, switchMap, takeUntil } from 'rxjs/operators';
import { NgIf, NgFor, AsyncPipe, NgClass } from '@angular/common';
import { OrganizationQuery } from 'src/app/routes/org/organization/org-profile/state/organization.query';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { CoursesService } from 'src/app/routes/learner/courses/state/courses.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-select-assign-training',
  templateUrl: './select-assign-training.component.html',
  styleUrls: ['./select-assign-training.component.scss'],
  standalone: true,
  imports: [
    ModalLayoutComponent,
    NgIf,
    AsyncPipe,
    NgFor,
    NgClass,
    SnackbarComponent,
    MatIconModule
  ],
})
export class SelectAssignTrainingComponent implements OnInit, OnDestroy {

  course: any[] = [];
  addCoursesBtn = true;
  constructor(
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private coursesService: CoursesService,
    private dialogRef: MatDialogRef<SelectAssignTrainingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.organizationService.resetAllUserCourse();
    this.reloadOrgCourses();
  }

  ngOnDestroy(): void {
    this.organizationService.resetEditCourse();
  }

  selectCourse(course: any) {
    if (course?.alreadyUserCourses === false) {
      this.organizationService.updateEditCourse(course.id);
      this.organizationQuery
        .selectAll()
        .pipe(
          take(1),
          map((data) => {
            return data;
          })
        )
        .subscribe((data) => {
          const activeButtonArray = data.filter((item: any) => {
            return item.course.editCourse === true;
          });
          // @ts-ignore
          this.course = activeButtonArray.map((course) => course.course);
          if (activeButtonArray?.length > 0) {
            this.addCoursesBtn = false;
          } else {
            this.addCoursesBtn = true;
          }
        });
    }
  }

  reloadOrgCourses() {
    const entityGuid = window.localStorage.getItem('entityGuid');
    const entityType = window.localStorage.getItem('entityType');
    if (!entityGuid) {
      return;
    }

    if (entityType !== 'Organization' && entityType !== 'Coop') {
      return;
    }

    const coursesSub = this.organizationService.getOrganizationCourses(entityGuid);

    this.organizationQuery
      .selectAll()
      .pipe(
        take(1),
        map((data) => {
          return data;
        }),
        switchMap((data) => {
          if (data.length > 0) {
            return of(null);
          } else {
            return coursesSub;
          }
        }),
      )
      .subscribe();
  }

  selectTraining() {
    this.dialogRef.close(this.course);
  }
}