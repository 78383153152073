import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Course } from './course.model';

export interface CoursesState extends EntityState<Course>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'courses' })
export class CoursesStore extends EntityStore<CoursesState> {

  constructor() {
    super();
  }

}
