
<h2 class="py-5">Pay-As-You-Go</h2>
<div class="payment-card">
    <div>
        <div>
            <h3>Purchase Certificate Credits</h3>
        </div>
        <div class="mt-4">
            <div class="flex items-center numberCounter">
                <button (click)="decrementCount()">
                    <mat-icon>horizontal_rule</mat-icon>
                </button>
                <div class="flex justify-center flex-grow">
                    <form [formGroup]="form" class="w-full">
                        <input matInput type="text"
                            formControlName="numberOfCertsString" (blur)="updateNumberOfCerts($event)" (click)="clear($event)"/>
                    </form>
                </div>
                <button (click)="incrementCount()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    
        <p class="pt-3">Pay-As-You-Go certificate credits can be used in conjunction with Annual Plans to create the perfect blend of features and certificate credits. Start with an Annual Plan then purchase additional Pay-As-You-Go credits as needed!</p>
    </div>
    <div>


        <div >
            <p class="small">
                <span class="h2">{{totalCertPrice | currency:'USD':'symbol':'1.2-2' }}</span>
                <span>&nbsp; one time purchase</span>
            </p>
        </div>

    
        <div class="mt-4">
            <button class="btn-primary large" (click)="submit()">Buy Now</button>
        </div>
    </div>

</div>

<div class="w-full lg:w-auto mt-4">
    <h3>Pay-As-You-Go Features</h3>
    <ul class="ul-reset checkmark-ul pt-3">
        <li>Certificates Roll Over Year to Year</li>
        <li>Unlimited Access to Course Catalog</li>
        <li>Reporting &amp; Metrics</li>
        <li>Downloadable Reports</li>
    </ul>
</div>




