import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoursePlayerService extends BaseAPI {
  public lmsFinish(body: any) {
    return this.http.post(`coursePlayer/LMSFinish`, body);
  }

  public lmsUpdate(body: any) {
    return this.http.post('coursePlayer/LMSUpdate', body);
  }
}
