import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LearningMetricsStore, LearningMetricsState } from './learning-metrics.store';

@Injectable({ providedIn: 'root' })
export class LearningMetricsQuery extends QueryEntity<LearningMetricsState> {

  constructor(protected store: LearningMetricsStore) {
    super(store);
  }

}
