import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { CoursesStore } from './courses.store';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../../../api/services/api.service';

@Injectable({ providedIn: 'root' })
export class CoursesService {
  constructor(
    private coursesStore: CoursesStore,
    private http: HttpClient,
    private api: ApiService
  ) {}

  upgradeCourses = new BehaviorSubject(false);
  retakeCourses = new BehaviorSubject({ moduleID: <any>[], logout: <boolean>false });

  getAllEntityCourses() {
    return this.api.courseApiService.getAllEntityCourses().pipe(
      tap((entities) => {
        const same = JSON.stringify(entities) === JSON.stringify(this.coursesStore.getValue().entities);
        if (!same && entities) {
          const cloneEntities = [...entities];
          const newEntity = cloneEntities.map((entity) => {
            const { selected, ...remaining } = entity;
            return {
              courseSelected: false,
              alreadySelected: selected,
              already: selected ? true : false,
              selected,
              ...remaining,
            };
          });
          this.coursesStore.set(newEntity);
        }
      })
    );
  }
  
  updateCoursesActive(id: number) {
    const StoreEntities = this.coursesStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      if (value?.id === id) {
        const { alreadySelected, courseSelected, already, ...remaining } = value;
        if (!alreadySelected) {
          const newCourse = { courseSelected: !courseSelected, alreadySelected, already: !already, ...remaining };
          this.coursesStore.update(key, newCourse);
        }
      }
    });
  }

  ResetAllCoursesActive() {
    const StoreEntities = this.coursesStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      const { alreadySelected, courseSelected, ...remaining } = value;
      const newCourse = { courseSelected: false, alreadySelected, ...remaining };
      this.coursesStore.update(key, newCourse);
    });
  }

  setActive(id: any) {
    this.coursesStore.setActive(id);
  }

  remove(id: ID) {
    this.coursesStore.remove(id);
  }

  downloadCert(certId: string) {
    let url = 'certificate/' + certId;
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      this.downloadFile(blob);
    });
  }

  downloadFile(blob: Blob) {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = 'certificate.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
