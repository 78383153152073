import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { ApiService } from 'src/app/api/services/api.service';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { Subject, of, throwError } from 'rxjs';
import { catchError, take, tap, map, switchMap, takeUntil } from 'rxjs/operators';
import { NgIf, NgFor, AsyncPipe, NgClass } from '@angular/common';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { OrganizationQuery } from 'src/app/routes/org/organization/org-profile/state/organization.query';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { CoursesService } from 'src/app/routes/learner/courses/state/courses.service';

@Component({
  selector: 'app-assign-training',
  templateUrl: './assign-training.component.html',
  styleUrls: ['./assign-training.component.scss'],
  standalone: true,
  imports: [
      ModalLayoutComponent,
      MatIconModule,
      FormsModule,
      ReactiveFormsModule,
      MatLegacyFormFieldModule,
      MatLegacyInputModule,
      NgIf,
      AsyncPipe,
      NgFor,
      NgClass,
      SnackbarComponent,
      MatDatepickerModule,
      MatRadioModule,
  ],
})
export class AssignTrainingComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  addCoursesBtn = true;
  disabled = false;
  courseIds: any[] = [];
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private snackBar: MatSnackBar,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private coursesService: CoursesService,
    private dialogRef: MatDialogRef<AssignTrainingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      dueDate: [null, [this.dateValidator]],
      email: [null]
    });
  }

  futureDatesFilter = (d: Date | null): boolean => {
    if (!d) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return d > today;
  }

  dateValidator = (control: FormControl) => {
    const date = control.value;
    if (!date) return null;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return date > today ? null : { invalidDate: true };
  }

  ngOnInit(): void {
    this.organizationService.resetAllUserCourse();
    this.reloadOrgCourses();
  }

  ngOnDestroy(): void {
    this.organizationService.resetEditCourse();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  selectCourse(course: any) {
    if (course?.alreadyUserCourses === false) {
      this.organizationService.updateEditCourse(course.id);
      this.organizationQuery
        .selectAll()
        .pipe(
          take(1),
          map((data) => {
            return data;
          })
        )
        .subscribe((data) => {
          const activeButtonArray = data.filter((item: any) => {
            return item.course.editCourse === true;
          });
          // @ts-ignore
          this.courseIds = activeButtonArray.map((course) => course.course.id);
          if (activeButtonArray?.length > 0) {
            this.addCoursesBtn = false;
          } else {
            this.addCoursesBtn = true;
          }
        });
    }
  }

  reloadOrgCourses() {
    const entityGuid = window.localStorage.getItem('entityGuid');
    const entityType = window.localStorage.getItem('entityType');
    if (!entityGuid) {
      return;
    }

    if (entityType !== 'Organization' && entityType !== 'Coop') {
      return;
    }

    const coursesSub = this.organizationService.getOrganizationCourses(entityGuid);

    this.organizationQuery
      .selectAll()
      .pipe(
        take(1),
        map((data) => {
          return data;
        }),
        switchMap((data) => {
          if (data.length > 0) {
            return of(null);
          } else {
            return coursesSub;
          }
        }),
      )
      .subscribe();
  }

  calculateDate(val: any) {
    if (val) {
      return new Date(val).toISOString();
    }
  }

  assignCourses() {
    const entityGuid = window.localStorage.getItem('entityGuid');
    const entityType = window.localStorage.getItem('entityType');
    if (!entityGuid) {
      return;
    }

    if (entityType !== 'Organization' && entityType !== 'Coop') {
      return;
    }

    if (this.courseIds.length === 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: {
          message: 'Please select at least one course',
          toastType: ToastType.Error,
        },
      });
      return;
    }

    if (this.form.invalid) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: {
          message: 'Please select a due date',
          toastType: ToastType.Error,
        },
      });
      return;
    }

    this.disabled = true;
    const { dueDate, email } = this.form.value;
    const formValue = {
      entityUserId: this.data.entityUserId,
      courseIds: this.courseIds,
      dueDate: dueDate ? this.calculateDate(dueDate) : null,
      email: email ?? false,
    };

    this.organizationService.assignCourseUser(formValue).subscribe(
      (data) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: {
            message: 'Courses assigned successfully',
            toastType: ToastType.Success,
          },
        });
        this.dialogRef.close(true);
      },
      (error) => {
        this.disabled = false;
        if (error.error) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: {
              message: error.error.message,
              toastType: ToastType.Error,
            },
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: {
              message: 'An error occurred while assigning courses',
              toastType: ToastType.Error,
            },
          });
        }
      }
    );
  }
}
