import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BatchInvitesStore, BatchInvitesState } from './import-learners-grid.store';

@Injectable({ providedIn: 'root' })
export class BatchInvitesQuery extends QueryEntity<BatchInvitesState> {

  constructor(protected store: BatchInvitesStore) {
    super(store);
  }

}
