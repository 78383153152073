import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Data, RouterLink } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, MatIconModule]
})
export class BreadcrumbComponent implements OnInit {
  pageInfo: Data = Object.create(null);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    // Trigger breadcrumb setup on component initialization (page refresh)
    this.setupBreadcrumbs();

    // Subscribe to router events to update breadcrumbs when navigation changes
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setupBreadcrumbs();
      });
  }

  setupBreadcrumbs() {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const event = route.snapshot.data;
    this.pageInfo = event;
  }
}
