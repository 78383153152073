import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { paths } from '../../interfaces/swagger-types';
import { BehaviorSubject, take, tap } from 'rxjs';

export type InviteRegister =
  paths['/api/Registration/register-invited-user']['post']['requestBody']['content']['text/json'];

@Injectable({
  providedIn: 'root',
})
export class InviteAcceptConfirmedService extends BaseAPI {
  inviteInfo$ = new BehaviorSubject<any>(null);

  getInvite(inviteGuid: string) {
    return this.http.get(`org/get-invite-info/${inviteGuid}`).pipe(
      tap((inviteInfo: any) => {
        this.inviteInfo$.next(inviteInfo);
      }),
      take(1)
    );
  }

  registerInviteUser(inviteUser: InviteRegister) {
    return this.http.post(`registration/register-invited-user`, inviteUser);
  }

  inviteResponse(inviteGuid: string, accepted: boolean) {
    return this.http.post(`org/existing-user-invite-response/${inviteGuid}/${accepted}`, null);
  }

  resendInvite(email: string, acceptUrl: string) {
    const body = {
      email: email,
      acceptUrl: acceptUrl,
    };
    return this.http.post(`account/resend-invite`, body);
  }
}
