import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class AppversionService extends BaseAPI {

  checkForUpdate() {
    return this.http.get('LookUp/appversion');
  }
}
