import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BatchInvites } from './import-learners-grid.model';
import { BatchInvitesStore } from './import-learners-grid.store';
import { ApiService } from 'src/app/api/services/api.service';
import { LocalDateFormatPipe } from 'src/app/shared/pipes/local-date-format/local-date-format.pipe';



@Injectable({ providedIn: 'root' })
export class BatchInvitesService {
  constructor(
    private batchInvitesStore: BatchInvitesStore,
    private apiService: ApiService,
    private localDateFormatPipe: LocalDateFormatPipe
  ) {}

  getBatchInvitesReport(requestParams: any) {

    let sortDir = requestParams.SortDirection;
    let sortField = requestParams.SortField;

    return this.apiService.batchInvitesService.getBatchInvitesReport(requestParams).pipe(
      tap((entities: any) => {
        const newEntities = [entities];
        const { rows, totalNumberOfItems, showZeroOverlay } = newEntities[0];
        const newMetrics = rows?.map((row: any) => {
          const { sendDate, processedDate, ...remaining } = row;
          const splitDateSent = sendDate.split('T')[0];
          const splitDateProcessed = processedDate?.split('T')[0];
          return {
            dateSent: sendDate ? this.localDateFormatPipe.transform(splitDateSent) : '',
            processedDate: processedDate ? this.localDateFormatPipe.transform(splitDateProcessed) : '',
            ...remaining,
          };
        });
        const newEntity = [
          {
            id: 1,
            rows: newMetrics,
            totalNumberOfItems,
            showZeroOverlay,
          },
        ];
        this.batchInvitesStore.set(newEntity);
      })
    );
  }

  // When status is 'Completed' allow user to download the batch invite report via Email
  getExcelBatchInvites(requestedBody: any) {
    return this.apiService.batchInvitesService.exportBatchInvitesReport(requestedBody);
  }

  // Allow user to cancel a single batch invite
   cancelBatchInvite(requestedBody: any) {
    return this.apiService.batchInvitesService.cancelBatchInvite(requestedBody);
  }

  emailbatchFile(batchId: any) {
    return this.apiService.batchInvitesService.emailBatchFile(batchId);
  }
}
