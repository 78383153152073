import { Directive, Input, ElementRef } from '@angular/core';

export interface DirectiveData {
  waitSeconds?: number;
  hasSpinner?: boolean;
  spinnerClass?: string;
  hideText?: boolean;
}

@Directive({
    selector: '[actionButton]',
    standalone: true,
})
export class ActionButtonDirective {

  
  waitSeconds?: number | null;
  hasSpinner?: boolean = false;
  spinnerClass: string = ''
  hideText?: boolean = false;

  spinner = ``
  oldhtml: string = ``;

  @Input() set actionButton(value: DirectiveData) {
    if (value) {
      this.waitSeconds = value.waitSeconds;
      this.hasSpinner = value.hasSpinner;
      this.spinnerClass = value.spinnerClass || '';
      this.hideText = value.hideText;
    }
  }

  constructor(private element: ElementRef) {
    this.element.nativeElement.addEventListener('click', () => {
      this.element.nativeElement.setAttribute('disabled', 'true');
      this.oldhtml = this.element.nativeElement.innerHTML;
      if (this.hasSpinner) {
        this.spinner = `<span class="btn-spinner `
        if (!this.hideText) {
          this.spinner += `absolute `;
          this.element.nativeElement.style.position = 'relative';
        }
        
        this.spinner += this.spinnerClass +`"></span>`;
        if (!this.hideText) {
          this.spinner = this.oldhtml + this.spinner;
        }
        this.element.nativeElement.innerHTML = this.spinner;
      }
      
      if (this.waitSeconds != null && this.waitSeconds != undefined) {
        setTimeout(() => {
          this.element.nativeElement.removeAttribute('disabled');
        }, this.waitSeconds * 1000);
      }

    });


    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
          if (!this.element.nativeElement.disabled) {
            if (this.oldhtml)
              this.element.nativeElement.innerHTML = this.oldhtml;
          }
        }
      }
    });
    observer.observe(this.element.nativeElement, { attributes: true });
  }
}
