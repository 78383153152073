import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { AllCourse } from './all-course.model';

export interface AllCoursesState extends EntityState<AllCourse>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'all-courses' })
export class AllCoursesStore extends EntityStore<AllCoursesState> {
  constructor() {
    super();
  }
}
