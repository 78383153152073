import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import { SwitchEntityProfileStore, SwitchEntityProfileState } from './switch-entity-profile.store';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SwitchEntityProfileQuery extends QueryEntity<SwitchEntityProfileState> {
  getAllOrg$ = this.selectAll().pipe(filterNilValue());
  activeOrg$ = this.selectActive().pipe(filterNilValue());
  entityType$ = new BehaviorSubject<any>('');

  constructor(protected store: SwitchEntityProfileStore) {
    super(store);
  }
}
