import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LearningMetric } from './learning-metric.model';
import { LearningMetricsStore } from './learning-metrics.store';
import { ApiService } from 'src/app/api/services/api.service';
import { LocalDateFormatPipe } from 'src/app/shared/pipes/local-date-format/local-date-format.pipe';

@Injectable({ providedIn: 'root' })
export class LearningMetricsService {
  constructor(
    private learningMetricsStore: LearningMetricsStore,
    private apiService: ApiService,
    private localDateFormatPipe: LocalDateFormatPipe
  ) {}

  getLearnerMetricsReport(requestParams: any) {
    return this.apiService.learnerMetricsService.getLearningMetricsReport(requestParams).pipe(
      tap((entities: any) => {
        const newEntities = [entities];
        const { rows, totalNumberOfItems } = newEntities[0];
        const newMetrics = rows?.map((row: any) => {
          const { dateAdded, dateCompleted, ...remaining } = row;
          const splitDateAdded = dateAdded.split('T')[0];
          const splitDateCompleted = dateCompleted?.split('T')[0];
          return {
            dateAdded: this.localDateFormatPipe.transform(splitDateAdded),
            dateCompleted: dateCompleted ? this.localDateFormatPipe.transform(splitDateCompleted) : '',
            ...remaining,
          };
        });
        const newEntity = [
          {
            id: 1,
            rows: newMetrics,
            totalNumberOfItems,
          },
        ];
        this.learningMetricsStore.set(newEntity);
      })
    );
  }
  getExcelLearners(requestedBody: any) {
    return this.apiService.learnerMetricsService.exportLearningMetricsReport(requestedBody);
  }
}
