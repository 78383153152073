<!-- ============================================================== -->
<!-- Only router without any element -->
<!-- ============================================================== -->
<div class="preloader" *ngIf="loadLoginHtml">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>

<div>
  <app-banner></app-banner>
</div>
<ng-container *ngIf="!loadLoginHtml">
  <div class="flex flex-col justify-between" style="height: 100dvh;">
    <div>
      <mat-toolbar class="auth-header">
        <div class="w-full">
          <div class="flex justify-between items-center">
            <div class="logo">
              <a href="https://mandatedreportertraining.com/" class="header-logo regular-course-icon">
                <img src="assets/MandatedReporterTraining-log.svg" alt="Mandated Reporter Training" />
              </a>
      
              <a href="https://mandatedreportertraining.com/" class="header-logo contrast-course-icon">
                <img src="assets/MandatedReporterTraining-log-contrast.svg" alt="Mandated Reporter Training" />
              </a>
            </div>
          </div>
        </div>
      </mat-toolbar>
      <mat-sidenav-container class="blank-component-mat-drawer-content">
        <router-outlet></router-outlet>
      </mat-sidenav-container>
    </div>
    <app-footer></app-footer>
  </div>
</ng-container>
