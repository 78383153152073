import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class OffsiteCertsService extends BaseAPI {

  getOffsiteCerts(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminOffsiteCert/${entityGuid}`, {
      params: requestParams
    });
  }

  downloadOffsiteCert(certId: number) {
    return this.http.get(`certificate/offsite/${certId}`, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }
}
