import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { paths } from '../../interfaces/swagger-types';
import { Observable } from 'rxjs';

export type IndividualRegister =
  paths['/api/Registration/totp-register-individual']['post']['requestBody']['content']['text/json'];
export type OrganizationRegistration =
  paths['/api/Registration/totp-register-org']['post']['requestBody']['content']['text/json'];

@Injectable({
  providedIn: 'root',
})
export class RegistrationService extends BaseAPI {
  public individualRegister(form: IndividualRegister) {
    return this.http.post('/registration/totp-register-individual', form);
  }

  public organizationRegister(form: OrganizationRegistration) {
    return this.http.post('/registration/totp-register-org', form);
  }
}
