import { ChangeDetectorRef, Component, OnDestroy, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems, Menu } from '../menu-items/menu-items';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/routes/authentication/state/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { AccordionAnchorDirective } from '../../../directives/accordion/accordionanchor.directive';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { AccordionLinkDirective } from '../../../directives/accordion/accordionlink.directive';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { AccordionDirective } from '../../../directives/accordion/accordion.directive';
import { MatLegacyListModule } from '@angular/material/legacy-list';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['sidebar.component.scss'],
    standalone: true,
    imports: [
        MatLegacyListModule,
        AccordionDirective,
        NgFor,
        AccordionLinkDirective,
        RouterLinkActive,
        NgIf,
        AccordionAnchorDirective,
        RouterLink,
        MatIconModule,
        AsyncPipe,
    ],
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  status = true;
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  mi$!: Observable<any[]>;
  @Output() eventEmitter = new EventEmitter();
  @Input() sidebarOpened!: boolean;
  menuChildren!: any;
  menuitems!: Menu[];
  sideMenu: any[] = [];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.mi$ = this.menuItems.getMenuitem();

  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0,
    });
  }

  logout() {
    this.authService.logout();
  }

  closeSideBar() {
    this.eventEmitter.emit();
  }
}
