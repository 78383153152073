import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GrecaptchaService {
  grecaptcha$: any = new BehaviorSubject<any>(null);
  private _grecaptcha: any;

  constructor() {
    this.grecaptcha$.subscribe((grecaptcha: any) => {
      this._grecaptcha = grecaptcha;
    });

    const checkGrecaptcha = () => {
      if (window.grecaptcha) {
        this.grecaptcha$.next(window.grecaptcha);
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkGrecaptcha, 100);

    // Fallback in case grecaptcha is available initially
    checkGrecaptcha();
  }

  execute(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this._grecaptcha) {
        reject('grecaptcha not loaded');
        return;
      }
      this._grecaptcha.ready(() => {
        this._grecaptcha
          .execute(environment.recaptchaSiteKey, {
            action: action + (environment.context !== 'prod' ? `_${environment.context}` : ''),
          })
          .then((token: string) => {
            resolve(token);
          });
      });
    });
  }
}
