<div *ngIf="cancelledInvite" class="noti-container">
  <div class="noti">
    <p>Your Invite from {{ orgName }} has been cancelled, but you can still register on your own!</p>
    <div class="h-full flex items-center">
      <mat-icon class="primary-link cursor-pointer button-type-none" (click)="closeNoti()">close </mat-icon>
    </div>
  </div>
</div>
<div class="register-container mt-3">
  <h1 class="text-primary font-bold text-center">Register as an Individual Learner</h1>
  <div class="custom-register-tabs shadow-none">
    <div class="pt-5">
      <h5 class="hidden sm:block text-center px-12 mx-12 pb-5">
        You are registering as an individual learner. You can share your training certificate with any organization after your training is complete.
      </h5>
      <form [formGroup]="form">
        <div class="flex flex-wrap w-full pl-5 pr-5">
          <app-user-cascading-dropdowns class="w-full" [form]="form"></app-user-cascading-dropdowns>
          <div class="w-full sm:w-1/2 pr-0 sm:pr-6 pb-2">
            <mat-form-field class="w-full">
              <mat-label>First Name</mat-label>
              <input matInput type="text" formControlName="firstName" />
              <mat-error
                *ngIf="form.controls['firstName'].hasError('required') && form.controls['firstName'].touched">Required
              </mat-error>
              <mat-error *ngIf="form.controls['firstName'].errors?.firstName && form.controls['firstName'].touched">You
                must include a valid Full Name.
              </mat-error>
              <mat-error *ngIf="form.controls['firstName'].errors?.message">{{
                form.controls['firstName'].getError('message')
                }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2 pb-2">
            <mat-form-field class="w-full">
              <mat-label>Last Name</mat-label>
              <input matInput type="text" formControlName="lastName" />
              <mat-error
                *ngIf="form.controls['lastName'].hasError('required') && form.controls['lastName'].touched">Required
              </mat-error>
              <mat-error *ngIf="form.controls['lastName'].errors?.lastName && form.controls['lastName'].touched">You
                must
                include a valid Last Name.
              </mat-error>
              <mat-error *ngIf="form.controls['lastName'].errors?.message">{{
                form.controls['lastName'].getError('message')
                }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2 pr-0 sm:pr-6 pb-2">
            <mat-form-field class="w-full">
              <mat-label>Email</mat-label>
              <input matInput type="text" (blur)="checkEmail($event.target.value, form.controls['email'])" formControlName="email" />
              <mat-error *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">Required
              </mat-error>
              <mat-error *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched">You must
                include a valid email.
              </mat-error>
            </mat-form-field>
            <div class="-mt-4 relative z-[1]" *ngIf="verifyEmail">
              <mat-error>
                You need to confirm your email.
                <span class="underline cursor-pointer" (click)="resendEmail()">Resend Verification Email</span>
              </mat-error>
            </div>
            <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['email'].errors?.message }">
              <mat-error class="text-danger support-text" *ngIf="form.controls['email'].errors?.message">{{
                form.controls['email'].getError('message')
                }}</mat-error>
            </div>
            <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['email'].errors?.invite }">
              <mat-error class="text-danger support-text" *ngIf="form.controls['email'].errors?.invite">
                {{ form.controls['email'].getError('invite').message }}
                <br />
                <span class="underline cursor-pointer"
                  (click)="resendInvite(form.controls['email'].getError('invite').email)">Resend Invite
                  Email</span>
              </mat-error>
            </div>
          </div>
          <div class="w-full sm:w-1/2 pb-2">
            <mat-form-field class="w-full">
              <mat-label>Confirm Email</mat-label>
              <input matInput type="text" formControlName="confirmEmail" />
              <mat-error *ngIf="
                  form.controls['confirmEmail'].hasError('required') && form.controls['confirmEmail'].touched
                ">Required
              </mat-error>
              <mat-error *ngIf="
                  form.controls['confirmEmail'].errors?.notEquivalent && form.controls['confirmEmail'].touched
                ">Email must match.
              </mat-error>
              <mat-error *ngIf="form.controls['confirmEmail'].errors?.message">{{
                form.controls['confirmEmail'].getError('message')
                }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2 pb-2 pr-0 sm:pr-6">
            <mat-form-field class="w-full">
              <mat-label>Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
              <mat-icon matSuffix class="cursor-default eye-hidden" (click)="hide = !hide">{{
                hide ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              <mat-error
                *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched">Required
              </mat-error>
              <mat-error *ngIf="form.controls['password'].errors?.password && form.controls['password'].touched">You
                must
                include a valid password.
              </mat-error>
            </mat-form-field>
            <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['password'].errors?.message }">
              <mat-error *ngIf="form.controls['password'].errors?.message">{{
                form.controls['password'].getError('message')
                }}</mat-error>
            </div>
          </div>
          <div class="w-full sm:w-1/2 pb-2">
            <mat-form-field class="w-full">
              <mat-label>Confirm Password</mat-label>
              <input matInput [type]="chide ? 'password' : 'text'" formControlName="confirmPassword"
                 />
              <mat-icon matSuffix class="cursor-default eye-hidden" (click)="chide = !chide">{{
                chide ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              <mat-error *ngIf="
                  form.controls['confirmPassword'].hasError('required') &&
                  form.controls['confirmPassword'].touched
                ">You must include confirm password.
              </mat-error>
              <mat-error *ngIf="
                  form.controls['confirmPassword'].errors?.notEquivalent &&
                  form.controls['confirmPassword'].touched
                ">Passwords do not match.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="w-full pb-2">
            <mat-form-field class="w-full sm:w-1/2 pr-0 sm:pr-6 demo-title">
              <mat-label>Organization Name</mat-label>
              <input type="text" matInput formControlName="volunteerOrgName" />
              <mat-error *ngIf="form.controls['volunteerOrgName'].errors?.message">{{
                form.controls['volunteerOrgName'].getError('message')
                }}</mat-error>
              <mat-error
                *ngIf="form.controls['volunteerOrgName'].errors?.maxlength && form.controls['volunteerOrgName'].touched">Cannot
                exceed 50 characters</mat-error>
              </mat-form-field>

              <mat-form-field class="w-full sm:w-1/2  pb-2">
                <mat-label>Zip Code</mat-label>
                <input matInput type="text" formControlName="zipcode" mask="00000" />
                <mat-error
                  *ngIf="form.controls['zipcode'].hasError('required') && form.controls['zipcode'].touched">Required
                </mat-error>
                <mat-error *ngIf="form.controls['zipcode'].errors?.message">{{
                  form.controls['zipcode'].getError('message')
                  }}</mat-error>
              </mat-form-field>
        
          </div>

          <div class="w-full">
            <mat-form-field class="w-full sm:w-1/2 pb-2">
              <mat-label>Preferred Language</mat-label>
              <mat-select formControlName="languageId" panelClass="mat-select-mt3">
                <mat-option *ngFor="let language of lookupsQuery.languages$ | async" [value]="language.value">
                  {{ language.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="form.controls['languageId'].hasError('required') && form.controls['languageId'].touched">Required</mat-error>
              <mat-error *ngIf="form.controls['languageId'].errors?.message">{{
                form.controls['languageId'].getError('message')
                }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <app-external-number [form]="form"></app-external-number>
        <div class="text-center mt-5">
          <button type="submit" class="btn-primary medium" [disabled]="disableFlag" id="CreateAccountIndividual"
            (click)="registerIndividual()">
            Create a Learner Account
          </button>
        </div>
      </form>
      <div class="mt-3">
        <div class="flex flex-wrap text-primary justify-center">
          <p class="large">
            <span class="mr-1">By signing up, you accept our</span>
            <a href="https://mandatedreportertraining.com/company/terms-of-use" target="_blank"
              class="primary-link mr-1 underline">Terms Of Use</a>
            <span class="mr-1">and</span>
            <a href="https://mandatedreportertraining.com/company/privacy-statement" target="_blank"
              class="primary-link underline">Privacy Statement</a>
          </p> 
        </div>
        <div class="flex text-primary justify-center ">
          <p class="large">
            <span class="mr-1">Already have an account?</span>
            <a href="/login" class="primary-link underline">Log In.</a>
          </p>
        </div>



        <div class="mt-4 text-center">
          <p class="small">This site is protected by reCAPTCHA and the Google <a class="underline"  href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="underline" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
        </div>
       
      </div>
    </div>
  </div>
</div>