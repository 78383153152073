import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef  } from '@angular/material/snack-bar';
import {NgStyle} from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

export enum ToastType { 
  Success = 'Success',
  Error = 'Error',
  Warning = 'Warning'
}


@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [
    NgStyle,
    MatIconModule
  ]
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  closeSnackbar() {
    this.snackBarRef.dismiss();
  }

  getBorderColor(toastType: ToastType): string {
    switch (toastType) {
      case ToastType.Success:
        return '#29B494';
      case ToastType.Error:
        return '#FF0F0F';
      case ToastType.Warning:
        return '#FDC602';
      default:
        return '#007bff';;
    }
  }
}
