import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { components, paths } from '../../interfaces/swagger-types';
import { Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SubscriptionStore } from 'src/app/subscription/state/subscription.store';
export type EntityAdminSubscriptionUsageViewModel =
  paths['/api/EntityAdminPayment/subscription-usage/{entityGuid}']['get']['responses']['200']['content']['text/json'];

export type SubscriptionViewModel =
  paths['/api/EntityAdminPayment/subscription/{entityGuid}']['get']['responses']['200']['content']['text/json'];
@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseAPI {
  constructor(http: HttpClient, private subscriptionStore: SubscriptionStore) {
    super(http);
  }

  getPricingPlans() {
    return this.http.get('EntityAdminPayment/products');
  }

  getSubscriptionUsage(entityGuid: string) {
    const roleType = localStorage.getItem('roleType');
    if (roleType === 'EntityAdmin') {
      return this.http
        .get<EntityAdminSubscriptionUsageViewModel>(`EntityAdminPayment/subscription-usage/${entityGuid}`)
        .pipe(
          tap((usage) => {
            this.subscriptionStore.updateUsage(usage);
          })
        );
    }
  }

  postCreateSession(stripePriceId: string, entityGuid: string) {
    const body = {
      stripePriceId: stripePriceId,
      entityGuid: entityGuid,
    };
    return this.http.post(`EntityAdminPayment/create-session`, body, { responseType: 'text' });
  }

  postManageSession() {
    const entityGuid = localStorage.getItem('entityGuid');
    return this.http.post(`EntityAdminPayment/manage/${entityGuid}`, {}, { responseType: 'text' });
  }

  getSubscription(entityGuid: string) {
    return this.http.get<SubscriptionViewModel>(`EntityAdminPayment/subscription/${entityGuid}`);
  }


  getBillingOverview(entityGuid: string) {
    return this.http.get(`EntityAdminPayment/billing-overview/${entityGuid}`);
  }


  getPayAsYouGoHistory(entityGuid: string) {
    return this.http.get(`EntityAdminPayment/payasyougo-history/${entityGuid}`);
  }

  getCertPrice() {
    return this.http.get(`EntityAdminPayment/payasyougo/certprice`);
  }


  createPayAsYouGoSession(entityGuid: string, numberOfCerts: number) {
    return this.http.post(`EntityAdminPayment/create-payasyougo-session/${entityGuid}/${numberOfCerts}`,  {}, { responseType: 'text' });
  }

}
