import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LookupsQuery } from 'src/app/lookups/lookups.query';
import { LookupsStore } from 'src/app/lookups/lookups.store';
import {
  AddResourcesComponent,
  DialogData,
} from '../../../../shared/components/modals/add-resources/add-resources.component';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { ResourcesService } from './state/resources.service';
import { ResourcesQuery } from './state/resources.query';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { GridOptions } from 'src/app/api/interfaces/grid-options';
import { GridTemplateComponent } from 'src/app/shared/components/layouts/grid-template/grid-template.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        NgIf,
        GridTemplateComponent,
        SnackbarComponent
    ],
})
export class ResourcesComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  showZeroOverlay = true;
  gridOptions: GridOptions = {
    filterData: [
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Type', formControlName: 'type', type: 'dropdown', lookup: [{value: 'Link', label: 'Link'}, {value: 'File', label: 'File'}] },
      { name: 'Status', formControlName: 'isActive', type: 'dropdown', lookup: [{value: 'Published', label: 'Published'}, {value: 'Draft', label: 'Draft'}, {value: 'Archived', label: 'Archive'}] },
    ],
    openFilters: false,
    columns: [
      { header: 'Resource Name', controlName: 'name' },
      { header: 'Description', controlName: 'description' },
      { header: 'Date Added', controlName: 'dateAdded', displayPipe: 'date' },
      { header: 'Type', controlName: 'type' },
      { header: 'Link', controlName: 'url',
        actionColumnitems: [
          { buttonAction: 'download', conditionalIcon: { calculateIcon: (url: any) => { return (url === 'File') ? 'file_download' : 'open_in_new'; }, iconColumn: 'type' } },
        ]
      },
      { header: 'Status', controlName: 'status' },
      { header: 'Edit', controlName: 'action',
        actionColumnitems: [
          { icon: 'edit', buttonAction: 'edit' },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    frontEndPagination: true,
  };

  constructor(
    public lookupsQuery: LookupsQuery,
    private lookupsStore: LookupsStore,
    private resourcesQuery: ResourcesQuery,
    private resourceService: ResourcesService,
    private modalService: ModalService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.refreshData();
    this.refreshQuery();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openDialog(options: {
    mode: 'add' | 'edit' | 'delete';
    componentType: 'resources';
    item?: any;
    deleteId?: any;
    itemName?: string;
  }) {
    const { mode, componentType, item, deleteId, itemName } = options;
    const data: DialogData = { mode, item, deleteId, itemName };
    if (componentType === 'resources') {
      this.modalService.open(
        AddResourcesComponent,
        {
          modalType: ModalType.Medium,
          data,
        },
        () => {
          this.gridOptions.fetchData = true;
        }
      );
    }
  }

  refreshData(changes?: any) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
    this.gridOptions.isLoading = true;
    var initialRequestedBody: any = {};
    if (changes) {
      initialRequestedBody = {
        SearchTerm: changes.search ? changes.search : '',
        Types: changes.type ? [changes.type] : [],
        Statuses: changes.isActive ? [changes.isActive] : [],
      };
    } else {
      initialRequestedBody = {
        SearchTerm: '',
        Types: [],
        Statuses: [],
      };
    }
    const resourcesSub = this.resourceService
      .getAllResources(entityGuid, initialRequestedBody)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.gridOptions.isLoading = false;
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
          return throwError(error);
        })
        )
      .subscribe((data: any) => {
        this.gridOptions.isLoading = false;
        if (data.rows.length > 0) {
          this.showZeroOverlay = false;
          this.gridOptions.addButtonName = 'Add Resources';
        }
      });
  }

  refreshQuery() {
    var gridDatasource: any[] = [];
    this.resourcesQuery
      .selectAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe((resources: any) => {
        gridDatasource = [];
        resources.forEach((resource: any) => {
          const datasource = gridDatasource;
          gridDatasource.push(resource);
          gridDatasource = datasource;
        });
        this.gridOptions.data.data = gridDatasource;
      });
  }

  resourceAction(row: any) {
    if (row.type === 'Link') {
      window.open(row?.url, '_blank');
    } else if (row.type === 'File') {
      const resourceId = row?.id;
      const file = row?.url.split('/');
      const fileName = file[file.length - 1];
      const fileType = fileName.split('.')[fileName.split('.').length - 1];
      this.resourceService.resourceFileDownload(resourceId, fileName, fileType);
    }
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.refreshData(event.data);
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      this.refreshData(event.data);
    } else if (event.action === 'clearFilters') {
      this.refreshData();
    } else if (event.action === 'addButton') {
      this.openDialog({ mode: 'add', componentType: 'resources' })
    }
    // 'edit' & 'download' is specific to this component 
    else if (event.action === 'edit') {
      this.openDialog({ mode: 'edit', componentType: 'resources', item: event.data })
    }
    else if (event.action === 'download') {
      this.resourceAction(event.data);
    }
  }
}
