import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service'; 
import { AuthService } from '../../../authentication/state/auth.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';


@Component({
    selector: 'app-email-confirmed',
    templateUrl: './email-confirmed.component.html',
    styleUrls: ['./email-confirmed.component.scss'],
    standalone: true,
    imports: [SnackbarComponent]
})
export class EmailConfirmedComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService, 
    private authServiceAS: AuthService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    const { confirmToken, userId, entityId, joinRequestGuid, inviteGuid } = this.route.snapshot.queryParams;
    this.api.auth
      .confirmEmail({ userId, confirmToken })
      .pipe(
        catchError((err) => {
          if (err?.error?.emailConfirmError) {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Token has been expired" }})
          }
          return throwError(err);
        }),
        tap((jwt) => {
          if (!joinRequestGuid && !inviteGuid) {
            this.authServiceAS.storeJWT(jwt, true );
          }   
        }),
        switchMap(() => this.authServiceAS.user$),
        tap((user) => {
          if (joinRequestGuid) {
            this.router.navigate([`/accept-invitation-confirmed`], {
              queryParams: {
                joinRequestGuid: joinRequestGuid,
              },
            });
            return;
          }
          if (inviteGuid) {
            this.router.navigate([`/accept-invitation-confirmed`], {
              queryParams: {
                inviteGuid: inviteGuid,
              },
            });
            return;
          }
          if (!user) {
            return;
          }
          const redirectURL = '/training';
          this.router.navigateByUrl(redirectURL).then();
        })
      )
      .subscribe(
        (d) => {},
        (e) => {
          if(e?.length > 0){
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${e[0]?.message}` }})
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "" }})
          }
        }
      );
  }
   
}
