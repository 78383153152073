import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { environment } from 'src/environments/environment';

export enum TotpType {
  VerifyEmail = 1,
  VerifyForgotPassword = 2,
  Validate = 3,
}

@Injectable({
  providedIn: 'root',
})
export class TotpService extends BaseAPI {
  public totpConfirmEmail(email: string, code: string) {
    return this.http.post('/TOTP/confirm-email', { email, code });
  }

  public totpValidate(email: string, code: string) {
    return this.http.post('/TOTP/validate', { email, code });
  }

  public generateTotp(email: string, totpType: TotpType, reCaptchaToken: string, reCaptchaVersion: number) {
    return this.http.post('/TOTP/generate', { email, totpType, reCaptchaToken, reCaptchaVersion});
  }

  public totpResend(email: string, totpType: number) {
    return this.http.post('/TOTP/resend', { email, totpType, reCaptchaToken: environment.recaptchaSiteKey });
  }
}
