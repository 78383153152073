import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { switchMap, tap, takeUntil } from 'rxjs/operators';
import { SwitchEntityProfileQuery } from '../../layouts/header/state/switch-entity-profile.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

@UntilDestroy()
@Component({
  selector: 'app-coop-association-modal',
  templateUrl: './coop-association-modal.component.html',
  styleUrls: ['./coop-association-modal.component.scss'],
  standalone: true,
  imports: [
      ModalLayoutComponent,
      FormsModule,
      ReactiveFormsModule,
      MatLegacyFormFieldModule,
      MatLegacyInputModule,
      MatIconModule,
      SnackbarComponent
  ],
})
export class CoopAssociationModalComponent implements OnInit, OnDestroy {


  associationLink: any;
  form: FormGroup = this.fb.group({
    publicUserAssociationURL: this.fb.control(''),
  });
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private dialogRef: MatDialogRef<CoopAssociationModalComponent>,
    public switchEntityProfileQuery: SwitchEntityProfileQuery,
    private organizationService: OrganizationService,
  ) {}

  ngOnInit(): void {
    this.loadOrg().pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  clipBoard() {
      this.clipboard.copy(`${this.associationLink}`);
      this.closeModal();
      this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Copied Link" }})
  }

  closeModal() {
    this.dialogRef.close();
  }

  loadOrg() {
    let entityGuid: any = '';
    if (window.localStorage.getItem('entityGuid')) {
      entityGuid = window.localStorage.getItem('entityGuid');
    }
    return this.switchEntityProfileQuery.activeOrg$.pipe(
      untilDestroyed(this),
      switchMap((user) => {
        return this.organizationService.getOrg(entityGuid);
      }),
      tap((data: any) => {
        this.associationLink = data?.landingPageShortUrl;
        this.form.get('publicUserAssociationURL')?.setValue(this.associationLink);
      }),
    );
  }
}
