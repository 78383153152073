import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { CommonModalComponent, CommonModalDialogData } from '../components/modals/common-modal/common-modal.component';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/api/services/payment/payment.service';
import { PricingModalComponent } from '../components/modals/pricing-modal/pricing-modal.component';
import { NewFeaturesComponent } from '../components/modals/new-features/new-features.component';

export enum ModalType {
  FullScreen = 1,
  Medium = 2,
  Small = 3,
  FullScreenMediumMobile = 4,
  FullScreenSmallMobile = 5,
}

/**
 * Please don't use unless absolutely necessary.
 * Use {@link ModalType} instead
 */
export class ModalTypeCustom {
  maxWidth?: string;
  maxHeight?: string;
  width?: string;
  height?: string;
  panelClass?: string;
}

export interface ModalOptions {
  modalType: ModalType | ModalTypeCustom;
  boxster?: boolean;
  disableClose?: boolean;
  initialTab?: number,
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _currentDialog!: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private paymentService: PaymentService,
  ) {}

  open(component: any, modalOptions: ModalOptions, afterClosed?: (result: any) => void) {
    this.closeAll(); // we only want one modal open at a time
    const modalType = modalOptions.modalType;
    const initialTab = modalOptions.initialTab;
    const hasActiveSubscription =modalOptions.data?.hasActiveSubscription ? modalOptions.data?.hasActiveSubscription:false;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      ...modalOptions.data,
      initialTab: initialTab
    };
    dialogConfig.disableClose = modalOptions.disableClose;

    if (modalType === ModalType.FullScreen) {
      dialogConfig.panelClass = 'full-screen-modal-panel-class';
    }

    if (modalType === ModalType.FullScreenMediumMobile) {
      dialogConfig.panelClass = 'full-screen-modal-medium-mobile-panel-class';
    }

    if (modalType === ModalType.Medium) {
      dialogConfig.panelClass = 'medium-modal-panel-class';
    }

    if (modalType === ModalType.Small) {
      dialogConfig.panelClass = 'small-modal-panel-class';
    }

    if (modalType === ModalType.FullScreenSmallMobile) {
      dialogConfig.panelClass = 'full-screen-modal-small-mobile-panel-class';
    }

    if (modalType instanceof ModalTypeCustom) {
      if (modalType.maxWidth) {
        dialogConfig.maxWidth = modalType.maxWidth;
      }

      if (modalType.maxHeight) {
        dialogConfig.maxHeight = modalType.maxHeight;
      }

      if (modalType.width) {
        dialogConfig.width = modalType.width;
      }

      if (modalType.height) {
        dialogConfig.height = modalType.height;
      }

      if (modalType.panelClass) {
        dialogConfig.panelClass = modalType.panelClass;
      }
    }

    this._currentDialog = this.dialog.open(component, dialogConfig);


    if (afterClosed) {
      this._currentDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((result: any) => {
          afterClosed(result);
        });
    }
  }

  close() {
    this._currentDialog.close();
  }

  closeAll() {
    this.dialog.closeAll();
  }

  // common modals that are open from multiple places
  // are defined below

  openUpgradeSubscriptionModal(hasSubscription: boolean) {
    this.open(CommonModalComponent, {
      modalType: ModalType.Medium,
      data: {
        title: 'Upgrade Subscription',
        message1: hasSubscription
          ? 'Your active users exeeds your plan seats. Upgrade your subscription or archive users.'
          : 'This feature requires a subscription to Mandated Reporter Training. Click the button below to view pricing and subscribe.',
        button1Text: hasSubscription ? 'Manage Subscription' : 'View Pricing',
        button1Clicked: hasSubscription ? this.manageSubscription.bind(this) : this.openPricingModal.bind(this),
      } as CommonModalDialogData,
    });
  }

  openPricingModal(hasActiveSubscription?: boolean) {
    this.open(PricingModalComponent, {
      modalType: ModalType.FullScreen,
      data: {hasActiveSubscription:hasActiveSubscription},
    });
  }

  
  openPayAsYouGoModal(hasActiveSubscription?: boolean) {
    this.open(PricingModalComponent, {
      modalType: ModalType.FullScreen,
      initialTab: 1,
      data: {hasActiveSubscription:hasActiveSubscription},
    });
  }

  openConfirmModal(message: string, onYesClicked: () => void, onNoClicked?: () => void) {
    this.open(CommonModalComponent, {
      modalType: ModalType.Small,
      data: {
        title: 'Are you sure?',
        message1: message,
        button1Text: 'Yes',
        button2Text: 'No',
        button1Clicked: onYesClicked,
        button2Clicked: onNoClicked || this.close.bind(this),
      } as CommonModalDialogData,
    });
  }

  openWelcomeToOrgModal() {
    this.open(CommonModalComponent, {
      modalType: ModalType.Small,
      data: {
        title: 'Welcome to Your Upgraded Organization!',
        message1: 'The Mandated Reporter Training platform lets you easily manage all your organizations trainings, settings, learners, and certificates.',
        message2: 'To kick things off, set up your organization by selecting active trainings, configuring settings and adding resources. Then create user groups with available trainings and start inviting learners.',
        customHtmlMessage: '<p>For more information, visit our <a class="btn-link" href="https://mandatedreportertraining.zendesk.com/hc/en-us/articles/17115309836059-How-to-Register-and-Setup-an-Organization" target="_blank">Getting Started Guide</a>.</p>',
        button1Text: 'Close',
        button1Clicked: () => {
          this.close();
        },
      } as CommonModalDialogData,
    });
  }

  openPurchaseCompleteModal() {
    this.open(CommonModalComponent, {
      modalType: ModalType.Small,
      data: {
        title: 'Purchase Complete!',
        message1: 'Thank you for completing your purchase; the exam for this training is now available. Simply click the Start Exam button below the exam icon to access the final assessment. On successful completion of the exam, your certificate will be issued automatically and available for download.',
        button1Text: 'Close',
        button1Clicked: () => {
          this.close();
        },
      } as CommonModalDialogData,
    });
  }

  openCertPurchaseCompleteModal() {
    this.open(CommonModalComponent, {
      modalType: ModalType.Small,
      data: {
        title: 'Purchase Complete!',
        message1: 'Thank you for purchasing your Certificate. It is now available for download on your training dashboard - simply click the Download button below the certificate icon. It’s always available for download in your Training History by clicking the link on the certificate number.',
        button1Text: 'Close',
        button1Clicked: () => {
          this.close();
        },
      } as CommonModalDialogData,
    });
  }

  openOrgCertNotIssued() {
    var customHtmlMessage = ``;
    var buttonText = '';
    const isOrgAdmin = localStorage.getItem('roleType') === 'EntityAdmin' || localStorage.getItem('roleType') === 'CoopAdmin';
    if (isOrgAdmin) {
      customHtmlMessage = `
      <h4 class="learner text-center">To issue certificates, please purchase an Annual or Pay-As-You-Go plan.</h4>
      `;
      buttonText = 'Buy Now';
    } else {
      customHtmlMessage = `<h2 class="learner text-center pb-9">Your Organization Administrator has been notified.</h2>`;
      buttonText = 'Close';
    }
    this.open(CommonModalComponent, {
      modalType: ModalType.FullScreenMediumMobile,
      data: { 
        title: 'Your organization has used all available certificate credits.',
        customHtmlMessage: customHtmlMessage,
        button1Text: buttonText,
        button1Clicked: () => {
          this.close();
          if (isOrgAdmin) {
            this.router.navigate(['/organization/account-billing']);
            this.openPricingModal();
          }
        }
      },
    });
  }

  openSuccessOrgPerchaseModal(isSubscription: boolean) {
    var title = '';
    var customHtmlMessage = ``;
    if (isSubscription) {
      title = 'Plan Purchase Complete';
      customHtmlMessage = `
      <h2 class="learner text-center">Thank you for upgrading your organization!</h2>
      <p class="medium text-center pt-7 pb-3 font-normal">
        Your account has been upgraded and any incomplete certificates are now available! 
        Use the Account Overview page to monitor your certificate usage over the plan year. If you approach the limit, don’t worry! 
        You can purchase more using Pay-As-You-Go or upgrade your plan at any time. 
      </p>
      <p class="medium text-center pb-9 font-normal">
        Be sure to explore the additional features available in your subscription, such as: invite 
        users with bulk import, landing page to connect with users, and additional courses available in the training catalog. Don’t 
        hesitate to reach out with questions - just use the Help button in the lower right.
      </p>
      `;
    } else {
      title = 'Pay-As-You-Go Purchase Complete';
      customHtmlMessage = `
      <h2 class="learner text-center">Thank you for purchasing certificates!</h2>
      <p class="medium text-center pt-7 pb-3 font-normal">
        Your account has been updated and any incomplete certificates are now available! Use the Account 
        Overview page to monitor your certificate usage. If you approach the limit, don’t worry! You can purchase more using Pay-As-You-Go or subscribe 
        to a plan at any time. 
      </p>
      <p class="medium text-center pb-9 font-normal">Don’t hesitate to reach out with questions - just use the Help button in the lower right.</p>
      `;
    }

    this.open(CommonModalComponent, {
      modalType: ModalType.FullScreenMediumMobile,
      data: { 
        title: title,
        customHtmlMessage: customHtmlMessage,
        button1Text: 'View Account & Billing',
        button1Clicked: () => {
          this.close();
        }
      },
    });
  }

  openCompletedCertModal(certId: any) {
    var h2Text = "Your certificate has been sent to your account administrator."
    const isIndividual = localStorage.getItem('entityType') === 'Individual';
    const isEntityAdmin = localStorage.getItem('roleType') === 'EntityAdmin';
    if ( isIndividual || isEntityAdmin) {
      h2Text = "Your certificate is available to share and download."
    }

    this.open(CommonModalComponent, {
      modalType: ModalType.FullScreenMediumMobile,
      data: {
        title: 'Congratulations, you have completed your training!',
        customHtmlMessage: `<h2 class="learner text-center pb-9">${h2Text}</h2>`,
        button1Text: 'View & Share Your Certificate',
        button1Clicked: () => {
          this.router.navigateByUrl(`/certificates/certificate/${certId}`);
          this.close();
        },
      } as CommonModalDialogData,
    });
  }

  newFeaturesModal(data: any) {
    this.open(NewFeaturesComponent, {
      modalType: ModalType.FullScreenMediumMobile,
      data: data,
    });
  }

  // private methods
  private manageSubscription() {
    this.paymentService.postManageSession().subscribe((res) => {
      window.location.href = res;
    });
  }
}
