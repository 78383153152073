import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { LearningMetric } from './learning-metric.model';

export interface LearningMetricsState extends EntityState<LearningMetric> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'learning-metrics' })
export class LearningMetricsStore extends EntityStore<LearningMetricsState> {

  constructor() {
    super();
  }

}
