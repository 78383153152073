import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText',
  standalone: true,
})

export class TruncateTextPipe implements PipeTransform {
  transform(input: string, maxLength: number): string {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
    } else {
      return input;
    }
  }
}
