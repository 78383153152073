import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { paths } from '../../interfaces/swagger-types';

export type AddUserGroup = paths['/api/EntityAdminEntity/add-entity-group']['post']['requestBody']['content']['text/json'];
@Injectable({
  providedIn: 'root',
})
export class UserGroupsService extends BaseAPI {
  upsertGroup(addUserGroup: AddUserGroup) {
    return this.http.post('EntityAdminEntity/add-entity-group', addUserGroup);
  }
  updateGroup(groupId: any, addUserGroup: AddUserGroup) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.put(`EntityAdminEntity/update-entity-group/${entityGuid}/${groupId}`, addUserGroup);
  }
  getUserGroups(entityGuid: string, params?: any) {
    return this.http.get(`EntityAdminEntity/entity-groups/${entityGuid}`, { params });
  }
}
