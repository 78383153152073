<div *ngIf="debugService.hasMessages$ | async" class="absolute bg-gray-100 w-fit min-w-[300px] z-50 bottom-2 left-2">
    <div class="m-1 p-1 flex justify-between">
        Debug messages:
        <button class="bg-white px-2 py-1" (click)="debugService.clear()">clear</button>
    </div>
    <div *ngFor="let message of debugService.messages$ | async">
        <div class="m-1 p-1 border-l-4 border-orange-300 bg-white">{{message}}</div>
    </div>
</div>
<div class="preloader" *ngIf="loading">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>
<router-outlet></router-outlet>