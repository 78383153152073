import { distinct } from 'rxjs';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SubscriptionStore, SubscriptionState } from './subscription.store';
import { PaymentService } from 'src/app/api/services/payment/payment.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SubscriptionQuery extends Query<SubscriptionState> {
  subscriptionUsage$ = this.select(state => state.usage);

  constructor(protected store: SubscriptionStore, private modalService: ModalService, private paymentService: PaymentService, private router: Router) {
    super(store);
  }

  checkSubscriptionUsage(onComplete?: (usage) => void, showModal: boolean = true) {
    const entityGuid = localStorage.getItem('entityGuid')!;
    const subscriptionUsage = this.getValue().usage;

    if (subscriptionUsage?.hasActiveSubscription !== undefined) {
      if (subscriptionUsage.requiresUpgrade) {
        let hasSubscription = subscriptionUsage.hasActiveSubscription;
        if (showModal && this.isUpgradeModalPage()) this.modalService.openUpgradeSubscriptionModal(hasSubscription);
      }
      if (onComplete) onComplete(subscriptionUsage);
    } else {
      this.paymentService.getSubscriptionUsage(entityGuid)?.pipe(distinct()).subscribe((results) => {
        if (results.requiresUpgrade) {
          let hasSubscription = results.hasActiveSubscription!;
          if (showModal && this.isUpgradeModalPage()) this.modalService.openUpgradeSubscriptionModal(hasSubscription);
        }
        if (results && onComplete) onComplete(results);
      });
    }
  }

  isUpgradeModalPage(): boolean {
    return (
      this.router.url.includes('organization') ||
      this.router.url.includes('team') ||
      this.router.url.includes('reports') ||
      this.router.url.includes('trainings')
    );
  }
}
