import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { ResourcesStore } from './resources.store';

@Injectable({ providedIn: 'root' })
export class ResourcesService {
  constructor(private resourcesStore: ResourcesStore, private apiService: ApiService) {}
  getAllResources(entityGuid: any, requestedBody: any) {
    return this.apiService.resourcesService.getResources(entityGuid, requestedBody).pipe(
      tap((entities: any) => {
        const { rows } = entities;
        this.resourcesStore.set(rows);
      })
    );
  }
  addResources(request: FormData) {
    return this.apiService.resourcesService.addResource(request);
  }
  resourceFileDownload(resourceId: any, fileName: string, fileType: string) {
    return this.apiService.resourcesService.fileDownload(resourceId, fileName, fileType);
  }
}
