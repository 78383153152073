<ng-container *ngIf="!hasSubscription; else subscribed">
  <app-boxy-message   
    [boxyMessageOptions]="boxyMessage"
    (buttonClicked)="onBoxyButtonClick()"
  ></app-boxy-message>
</ng-container>


<ng-template #subscribed>
  <div class="content-container ml-4 mr-4">
    <h1 class="learner">Share Organization Web Page</h1>
    <p class="medium pt-2">Use your organization's training web page to allow your team to join without an invitation. 
      By enabling this setting, learners with access to the link can join your organization without needing your approval.
      <a
        class="text-right primary-link"
        href="https://mandatedreportertraining.zendesk.com/hc/en-us/articles/18755043082267"
        target="_blank"
      >Learn More</a
    ></p>
    <form [formGroup]="form" (ngSubmit)="updateSettings()">
    <div class="mt-auto flex flex-row">
        <div class="flex flex-col my-8">
            <mat-slide-toggle formControlName="publicUserAssociationToggle" (change)="setPublicPageStatus()">
            </mat-slide-toggle>
        </div>
        <div class="flex flex-col my-6 pl-5">
          <h4 class="learner" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Enable Organization Web Page</h4>
          <p *ngIf="orgData.publicLandingPageEnabled">{{ form.get('publicUserAssociationURL')?.value }}</p>
        </div>
    </div>

    
    <button class="btn-primary medium" type="submit" [disabled]="!orgData.publicLandingPageEnabled" (click)="clipBoard()">
      <ng-container>Copy Link To Clipboard</ng-container>
    </button> 

    <h2 class="learner pt-8" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Web Page Link</h2>
    <p class="pt-2" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Share this link with your users. Note: Anyone with the link can use this page to join your organization as a learner. Use caution when sharing your link.</p>
    <div class="mt-4 w-full flex gap-4">
      <div class="w-1/2 flex lg:pb-20">
        <div class="w-full lg:w-4/5 pb-2">
            <mat-form-field class="w-full">
              <p class="small" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Organization Landing Page Link</p>
              <input
                id="publicUserAssociationURL"
                class="flex-wrap"
                type="text"
                matInput
                formControlName="publicUserAssociationURL"
                readonly="true"
              />
              <mat-icon *ngIf="orgData.publicLandingPageEnabled" matSuffix class="primary-link" (click)="clipBoard()"
                >assignment</mat-icon>
            </mat-form-field>
        </div>
      </div>
      <div class="w-full lg:w-1/2 pr-6">
          <mat-form-field floatLabel="always" class="w-full">
            <!-- <mat-label>Customized URL</mat-label> -->
            <p [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">https://invite.mandatedreportertraining.com/</p>
            <input
              id="slug" 
              class="flex-wrap" 
              type="text" 
              matInput 
              formControlName="slug" 
              (blur)="isSlugValid()" 
            />
          </mat-form-field>
        <p class="small" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Create a custom URL for your webpage link.</p>
      </div>
    </div>
  
        <div class="w-full">
          <div class="w-full flex m-0">
            <div class="sm:w-4/5">
              <h3 class="learner pt-1" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Email Allow List</h3>
                <p class="w-1/2" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">
                  Entering an email domain here will allow only individuals with that domain in their email address to join your organization. Leaving this blank allows any individual to join.
                </p>
                <ng-container formArrayName="whiteListedDomains" *ngIf="true">
                  <div *ngFor="let control of whiteListedDomains.controls; let i = index">
                      <div class="m-b-0 flex flex-row">
                        <div class="w-1/2 pr-2">
                          <mat-form-field class="w-full">
                            <mat-label>Email Domain</mat-label>
                            <input 
                              type="text" 
                              matInput
                              [formControlName]="i" />
                          </mat-form-field>
                        </div>
                        <mat-icon (click)="removeDomain(i)" class="btn-action" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">close</mat-icon>
                      </div>
                  </div>
                </ng-container>
                <div class="w-full flex">
                  <button type="button" (click)="addDomain()" [disabled]="!orgData.publicLandingPageEnabled"
                    class="flex flex-row">
                    <span [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Add Email Domain</span>
                    <mat-icon [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">add_circle</mat-icon>
                  </button>
                </div>
            </div>
          </div>
        </div> 
      
    <div>
      <button type="submit" class="btn-primary-outlined mt-4 fixed-size-sm" [disabled]="!orgData.publicLandingPageEnabled">
        Update Settings
      </button>
    </div>
    </form>
  </div>
</ng-template>