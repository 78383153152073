import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AddUsersStore, AddUsersState } from './add-users.store';

@Injectable({ providedIn: 'root' })
export class AddUsersQuery extends QueryEntity<AddUsersState> {

  constructor(protected store: AddUsersStore) {
    super(store);
  }

}
