import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { paths } from '../../interfaces/swagger-types';
import { Observable } from 'rxjs';
import { share, take } from 'rxjs/operators';

export type CoursesResponse =
  paths['/api/LearnerCourse/{entityGuid}']['get']['responses']['200']['content']['text/json'];

@Injectable({
  providedIn: 'root',
})
export class CourseApiService extends BaseAPI {
  public startOnlineCourse(courseId: string) {
    return this.http.post(`/UserSession/online-course/${courseId}`, null);
  }

  public removeCourse(data: any) {
    const { id } = data;
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.delete(`LearnerCourse/${entityGuid}/${id}`);
  }

  public courses(): Observable<CoursesResponse | null> {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.get<CoursesResponse>(`entityAdminCourse/${entityGuid}`).pipe(take(1));
  }

  getAllEntityCourses() {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.get<CoursesResponse>(`LearnerCourse/available-courses/${entityGuid}`).pipe();
  }

  getAllDashboardCourses(entityGuid: any) {
    return this.http.get(`LearnerCourse/${entityGuid}`).pipe();
  }

  getCourseHistory(entityGuid: any) {
    return this.http.get(`LearnerCourse/course-history/${entityGuid}`).pipe();
  }

  getShareOptions(certId: number) {
    return this.http.get(`Certificate/share-options/${certId}`).pipe();
  }

  public addCourse(data: any): Observable<any> {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`LearnerCourse/${entityGuid}`, data);
  }

  public reTakeCourse(data: any): Observable<any> {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`LearnerCourse/retake-course/${entityGuid}`, data);
  }

  public changeCourseLanguage(body: any) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`LearnerCourse/change-course-language/${entityGuid}`, body);
  }

  public addUserCourse(userCourse: any, learnerUserId: string) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.put(`EntityAdminCourse/learner-available-courses/${entityGuid}/${learnerUserId}`, userCourse);
  }

  public addOrgCourse(orgCourseId: number) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`EntityAdminCourse/add-entity-course/${entityGuid}/${orgCourseId}`, {});
  }

  public addOrgCourses(coursesToAdd: number[]) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`EntityAdminCourse/add-entity-courses/${entityGuid}`, coursesToAdd);
  }

  toggleActiveAndInactive(orgCourse: any) {
    const { entityGuid, courseId } = orgCourse;
    return this.http.put(`EntityAdminCourse/toggle-entity-course-status/${entityGuid}/${courseId}`, null);
  }

  createCertSession(userLearningPathId: number, certComplete: boolean, successReturnPath:string) {
    const body = {
      userLearningPathId : userLearningPathId,
      certComplete: certComplete,
      successReturnPath: successReturnPath
    };
    return this.http.post(`Payment/create-session-cert`, body, { responseType: 'text' });
  }

  shareCert(entityGuid: string, certId: number) {
    return this.http.post(`Certificate/share-certificate/${entityGuid}/${certId}`, null, { responseType: 'text' });
  }
}
