import { Injectable } from '@angular/core';
import { ApiService } from '../../../../api/services/api.service';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  constructor(private api: ApiService) {}

  addIndividual(registration: any) {
    return this.api.registration.individualRegister(registration);
  }

  addOrganization(registration: any) {
    return this.api.registration.organizationRegister(registration);
  }

  validEmail(email: string) {
    return this.api.auth.emailExist(email);
  }

  validEntityUserEmail(email: string, entityGuid: string) {
    return this.api.auth.entityUserEmailExist(email, entityGuid);
  }
}
