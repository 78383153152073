import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class ImportUserService extends BaseAPI {

  batchPreview(entityGuid: any, batch: any) {
    return this.http.post(`EntityAdminBatch/batch-user-invite/${entityGuid}`, batch);
  }

  batchInvite(batchId: any, isConfirmed: any) {
    return this.http.post(`EntityAdminBatch/user-invite/${batchId}/${isConfirmed}`, null);
  }
}
