import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BatchInvites } from './import-learners-grid.model';

export interface BatchInvitesState extends EntityState<BatchInvites> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'batch-invites' })
export class BatchInvitesStore extends EntityStore<BatchInvitesState> {

  constructor() {
    super();
  }

}
