import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class BannerMessageService extends BaseAPI {

  getBannerMessages() {
    return this.http.get('LookUp/banner-messages');
  }
}
