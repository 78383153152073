export interface Auth {
  user?: any;
  jwt?: string;
  userIsLoggedIn?: boolean;
}

export function createAuth() {
  return {
  } as Auth;
}
