<div *ngIf="!hasSubscription"  class="pt-2 pl-2">
  <p>This feature is available with a paid subscription. <a (click)="openPrices()"
      class="primary-link ml-0">Subscribe Now.</a></p>
</div>
<div class="content-container no-data-overlay-parent" *ngIf="stepper === 1">
  <h2 class="learner pb-10">Assign trainings to users in your organization.</h2>
  <form [formGroup]="assignTrainingForm" class="pb-14">
    <h2 class="learner">Users</h2>
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2">
        <mat-form-field class="w-full p-2 pl-0">
          <mat-label>Users</mat-label>
          <mat-select formControlName="users" panelClass="mat-select-mt3">
            <mat-option *ngFor="let users of users$" [value]="users.value">
              {{ users.label }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="assignTrainingForm.controls['users'].hasError('required') && assignTrainingForm.controls['users'].touched">
            Required
          </mat-error>
          <mat-error *ngIf="assignTrainingForm.controls['users'].errors?.message">{{
            addGroups.controls['users'].getError('message')
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-full sm:w-1/2" *ngIf="currentUsersValue === 'group'">
        <div class="w-full py-2 pr-2 pl-0 sm:pl-2">
          <app-form-select label="Select Group" multiple="true" formControlName="groupIds" [options]="groups$ | async">
          </app-form-select>
        </div>
      </div>
    </div>
    <div class="flex" *ngIf="currentUsersValue === 'selectUsers'">
      <a class="flex primary-link cursor-pointer items-center custom-mat-icon-min-width" (click)="selectUsers()">
        <mat-icon class="custom-mat-icon">add</mat-icon>
        <p class="btn btn-icon-text font-bold medium">Select Users</p>
      </a>
      <p class="text-primary flex items-center">{{learnerNames.join(", ")}}</p>
    </div>
    <h2 class="learner pt-12">Trainings</h2>
    <p class="pt-2 pb-6">Select the training you would like to assign.</p>
    <div class="pb-14 flex">
      <button class="flex primary-link cursor-pointer items-center custom-mat-icon-min-width" (click)="selectTraining()" [disabled]="!hasSubscription">
        <mat-icon class="custom-mat-icon">add</mat-icon>
        <span class="btn btn-icon-text font-bold medium pr-5">Select Training</span>
      </button>
      <p class="text-primary flex items-center">{{courseDisplays.join(", ")}}</p>
    </div>

    <h2 class="learner">Due Date</h2>
    <p class="pt-2">Select the date you would like the training to be due.</p>
    <mat-form-field class="w-full sm:w-1/2 max-w-xs">
      <mat-label>Date</mat-label>
      <input matInput formControlName="dueDate" [matDatepicker]="picker1" [matDatepickerFilter]="futureDatesFilter"/>
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1 [disabled]="!hasSubscription"></mat-datepicker>
      <mat-error *ngIf="assignTrainingForm.get('dueDate')?.hasError('invalidDate')">Please select a future date.</mat-error>
      </mat-form-field>

    <h2 class="learner pt-10">Email Notifications</h2>
    <p class="py-2">Do you want to send an email to notify the learners of the assignment.</p>
    <mat-radio-group formControlName="email">
      <mat-radio-button [value]="true">Yes</mat-radio-button>
      <mat-radio-button [value]="false">No</mat-radio-button>
    </mat-radio-group>
  </form>
  <div class="flex justify-center">
    <button type="button" class="btn-primary large" (click)="next()" [disabled]="!hasSubscription"> Next </button>
  </div>
</div>
<div class="content-container no-data-overlay-parent" *ngIf="stepper === 2">
  <h2 class="learner">Review and Confirm</h2>
  <h2 class="learner pt-12 pb-2">Users</h2>
  <div *ngIf="currentUsersValue === 'isAllLearners'">
    <p class="medium font-semibold">All</p>
  </div>
  <div *ngIf="currentUsersValue === 'group'">
    <p class="medium font-semibold">Groups</p>
    <p class="text-primary">{{groupNames.join(", ")}}</p>
  </div>
  <div *ngIf="currentUsersValue === 'selectUsers'">
    <p class="medium font-semibold">Learners</p>
    <p class="text-primary">{{learnerNames.join(", ")}}</p>
  </div>
  <h2 class="learner pt-12 pb-2">Trainings</h2>
  <p class="text-primary">{{courseDisplays.join(", ")}}</p>
  <h2 class="learner pt-12 pb-2">Due Date</h2>
  <p class="text-primary" *ngIf="assignTrainingForm.value.dueDate">Due: {{assignTrainingForm.value.dueDate | date : 'MM/dd/yy'}}</p>
  <p class="text-primary" *ngIf="!assignTrainingForm.value.dueDate">No due date selected.</p>
  <h2 class="learner pt-12 pb-2">Email Notifications</h2>
  <p class="text-primary" *ngIf="assignTrainingForm.value.email">Sending email notifications.</p>
  <p class="text-primary" *ngIf="!assignTrainingForm.value.email">No email notifications selected.</p>
  <div class="w-full grid justify-center items-center pt-12">
    <div>
      <button class="btn-primary medium" type="button" (click)="assignTraining()" [disabled]="!hasSubscription">
        <ng-container>Assign Training</ng-container>
      </button>
    </div>
    <div class="flex justify-center pt-3">
      <button class="primary-link flex justify-center items-center" type="button" (click)="backStep()">
        <mat-icon class="rotated-icon-135">arrow_outward</mat-icon>
        <span>Back</span>
      </button>
    </div>
  </div>
</div>
<div class="content-container no-data-overlay-parent" *ngIf="stepper === 3">
  <h2 class="learner pb-10">Training Assignment Processing</h2>
  <p>
    Your training assignment will process shortly. Assigned trainings will appear in your Learner Metrics Report as 
    the assignments are processed. An email with assignment results will be sent to you on completion.
  </p>
  <div class="pt-10">
    <a class="btn-primary-outlined medium" type="button" routerLink="/reports/learner-metrics">
      View Learner Report
    </a>
</div>