<div class="pt-10">
  <div *ngIf="inviteValid" class="noti-container">
    <div class="noti">
      <p *ngIf="!isJoinRequest">You've been invited to join {{ inviteOrgName }}. Register to continue.</p>
      <p *ngIf="isJoinRequest">Register to join {{ inviteOrgName }}</p>
    </div>
  </div>
  <div class="register-container">
    <div>
      <mat-card-title>
        <h1 class="text-primary font-bold mb-5 sm:mb-10 text-center">Register and Start Training</h1>
      </mat-card-title>
      <mat-card-content>
        <form [formGroup]="registerForm">
          <div class="flex flex-wrap">
            <div class="w-full sm:w-1/2 pr-0 sm:pr-6">
              <mat-form-field class="w-full">
                <mat-label>First Name</mat-label>
                <input type="text" matInput formControlName="firstName" />
                <mat-error
                  *ngIf="
                    registerForm.controls['firstName'].hasError('required') &&
                    registerForm.controls['firstName'].touched
                  "
                >
                  Required
                </mat-error>
                <mat-error *ngIf="registerForm.controls['firstName'].errors?.message">{{
                  registerForm.controls['firstName'].getError('message')
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2">
              <mat-form-field class="w-full">
                <mat-label>Last Name</mat-label>
                <input type="text" matInput formControlName="lastName" />
                <mat-error
                  *ngIf="
                    registerForm.controls['lastName'].hasError('required') && registerForm.controls['lastName'].touched
                  "
                >
                  Required
                </mat-error>
                <mat-error *ngIf="registerForm.controls['lastName'].errors?.message">{{
                  registerForm.controls['lastName'].getError('message')
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2 pr-0 sm:pr-6 pb-2">
              <mat-form-field class="w-full">
                <mat-label>Email Address</mat-label>
                <input matInput type="text" formControlName="email" />
                <mat-error
                  *ngIf="registerForm.controls['email'].hasError('required') && registerForm.controls['email'].touched"
                  >Required
                </mat-error>
                <mat-error
                  *ngIf="registerForm.controls['email'].errors?.email && registerForm.controls['email'].touched"
                  >You must include a valid email.
                </mat-error>
                <mat-error *ngIf="registerForm.controls['email'].errors?.message">{{
                  registerForm.controls['email'].getError('message')
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2 pb-2">
              <mat-form-field class="w-full">
                <mat-label>Confirm Email Address</mat-label>
                <input matInput type="text" formControlName="confirmEmail"  />
                <mat-error
                  *ngIf="
                    registerForm.controls['confirmEmail'].hasError('required') &&
                    registerForm.controls['confirmEmail'].touched
                  "
                  >Required
                </mat-error>
                <mat-error
                  *ngIf="
                    registerForm.controls['confirmEmail'].errors?.notEquivalent &&
                    registerForm.controls['confirmEmail'].touched
                  "
                  >Email must match.
                </mat-error>
                <mat-error *ngIf="registerForm.controls['confirmEmail'].errors?.message">{{
                  registerForm.controls['confirmEmail'].getError('message')
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full sm:w-1/2 pb-2 pr-0 sm:pr-6">
              <mat-form-field class="w-full">
                <mat-label>Password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
                <mat-icon matSuffix class="cursor-default eye-hidden" (click)="hide = !hide">{{
                  hide ? 'visibility' : 'visibility_off'
                }}</mat-icon>

                <mat-error
                  *ngIf="
                    registerForm.controls['password'].hasError('required') && registerForm.controls['password'].touched
                  "
                  >Required
                </mat-error>
                <mat-error
                  *ngIf="
                    registerForm.controls['password'].errors?.password && registerForm.controls['password'].touched
                  "
                  >You must include a valid password.
                </mat-error>
              </mat-form-field>
              <div [ngClass]="{ 'mb-1 -mt-4 ': registerForm.controls['password'].errors?.message }">
                <mat-error *ngIf="registerForm.controls['password'].errors?.message">{{
                  registerForm.controls['password'].getError('message')
                }}</mat-error>
              </div>
            </div>
            <div class="w-full sm:w-1/2 pb-2">
              <mat-form-field class="w-full">
                <mat-label>Confirm Password</mat-label>
                <input
                  matInput
                  [type]="chide ? 'password' : 'text'"
                  formControlName="confirmPassword"
                  
                />
                <mat-icon matSuffix class="cursor-default eye-hidden" (click)="chide = !chide">{{
                  chide ? 'visibility' : 'visibility_off'
                }}</mat-icon>
                <mat-error
                  *ngIf="
                    registerForm.controls['confirmPassword'].hasError('required') &&
                    registerForm.controls['confirmPassword'].touched
                  "
                  >You must include confirm password.
                </mat-error>
                <mat-error
                  *ngIf="
                    registerForm.controls['confirmPassword'].errors?.notEquivalent &&
                    registerForm.controls['confirmPassword'].touched
                  "
                  >Passwords do not match.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="text-center">
            <button
              type="submit"
              class="btn-primary medium"
              [disabled]="disableFlag"
              (click)="registerUser()"
            >
              Create Your Account
            </button>
          </div>
        </form>
        <div class="mt-3">
          <div class="flex flex-wrap text-primary justify-center font-medium">
            <span class="mr-1">By signing up, you accept our</span>
            <a
              href="https://mandatedreportertraining.com/terms-of-use"
              target="_blank"
              class="primary-link mr-1"
              >Terms Of Use</a
            >
            <span class="mr-1">and</span>
            <a
              href="https://mandatedreportertraining.com/privacy-statement"
              target="_blank"
              class="primary-link"
              >Privacy Statement</a
            >
          </div>
          <div class="flex text-primary justify-center font-medium">
            <span class="mr-1">Already have an account?</span>
            <a class="text-primary underline font-bold" (click)="goToLogin()">Log In.</a>
          </div>
        </div>
      </mat-card-content>
    </div>
  </div>
</div>
