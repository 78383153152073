import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { pluck, shareReplay, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { components, paths } from '../../interfaces/swagger-types';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends BaseAPI {
  confirmEmail(req: { userId: string; confirmToken: string }): Observable<any> {
    return this.http.post<string>('account/confirm-email', req).pipe(pluck('jwt'));
  }

  login(req: { userName: string; password: string }, joinRequestGuid?: string) {
    if (joinRequestGuid) {
      return this.http.post(`Authentication/login/${joinRequestGuid}`, req);
    } else {
      return this.http.post('Authentication/login', req);
    }
  }

  login2fa(req: { userName: string; password: string; verificationCode: string }) {
    return this.http.post('Authentication/login-2fa', req);
  }

  showQrCode(req: { userName: string; password: string }) {
    return this.http.post('Authentication/show-qr-code', req);
  }

  loginAs(email: string){
    return this.http.post(`Authentication/login-as`, `"${email}"`, {headers: {'Content-Type': 'application/json'}});
  }
  user() {
    type userResponse = paths['/api/User']['get']['responses']['200']['content']['text/json'];

    return this.http.get<userResponse>('user').pipe(shareReplay(1));
  }

  updateUser(user: any) {
    return this.http.patch('user', user, {});
  }

  recoverPassword(req: { email: string; resetPasswordUrl: string; reCaptchaToken?: string }) {
    return this.http.post('account/totp-forgot-password', req);
  }

  forgotPassword(req: { email: string; resetPasswordUrl: string, reCaptchaToken: string, app: string }) {
    return this.http.post('account/forgot-password', req);
  }

  totpResetPassword(email: string, password: string, uniqueIdentifier: string) {
    return this.http.post('account/totp-reset-password', { email, password, uniqueIdentifier });
  }

  resetPassword(email: string, password: string, confirmationToken: string) {
    return this.http.post('account/reset-password', { email, password, confirmationToken });
  }

  changePassword(req: { currentPassword: string; newPassword: string; confirmPassword: string }) {
    return this.http.post('account/change-password', req);
  }

  emailExist(email: string) {
    const body = {
      email: email,
    };
    return this.http.post(`user/user-exists`, body);
  }

  entityUserEmailExist(email: string, entityGuid: string) {
    const body = {
      email: email,
      entityGuid: entityGuid
    };
    return this.http.post(`user/entity-user-exists`, body);
  }
  verifyEmail(body:any){

    return this.http.post('/Account/verify-email',body)
  }

  uploadImage(image: any) {
    return this.http.post(`user/upload-image`, image, { responseType: 'text' });
  }
}
