import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/api/services/payment/payment.service';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-org-admin-banner',
    templateUrl: './org-admin-banner.component.html',
    styleUrls: ['./org-admin-banner.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class OrgAdminBannerComponent implements OnInit {
  displayBanner = false;
  title = '';
  message = '';
  buttonText = '';
  linkText = '';
  link = '';
  hasSubscription = false;
  disableUpgradeButton = false;

  constructor(
    private paymentService: PaymentService,
    private subscriptionQuery: SubscriptionQuery,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.subscriptionQuery.select().subscribe((subscription) => {
      if (subscription?.usage) {
        this.getUsageDetails(subscription?.usage);
      }
    });
  }

  getUsageDetails(usage: any) {
    // in trail period and no subscription
    if (!usage.isTrialOver && usage.hasActiveSubscription == false) {
      this.displayBanner = true;
      this.title = 'Organization Preview';
      this.message = `Welcome to the new organization and learner management tool. Please enjoy your free trial - ${usage.remainingTrialDays} days remaining!`;
      this.buttonText = 'Buy Now';
    }

    // trial over, no subscription
    else if (usage.isTrialOver && usage.hasActiveSubscription == false) {
      this.displayBanner = true;
      this.title = 'Subscribe';
      this.message = 'Select a subscription to access all of the organization and learner management tools or if you have questions, ';
      this.buttonText = 'Subscribe Now';
      this.linkText = 'contact us';
      this.link = 'https://mandatedreportertraining.com/lets-talk/';
    }

    // upgrade required
    else if (usage.requiresUpgrade) {
      this.displayBanner = true;
      this.title = 'Upgrade Subscription';
      this.message = 'Upgrade your subscription to access all of the organization and learner management tools.';
      this.buttonText = 'Upgrade';
      this.hasSubscription = true;
    }
  }

  upgradeClicked() {
    if (this.hasSubscription) {
      this.disableUpgradeButton = true;
      this.paymentService.postManageSession().subscribe((res) => {
        window.location.href = res;
      });
    } else {
      this.modalService.openPricingModal(this.hasSubscription);
    }
  }
}
