<app-modal-layout>
  <ng-container class="justify-center mx-10">
    <h1 class="text-center text-primary">Select Users</h1>
    <p>Displayed below are the top 10 results. Use the search field to narrow your results.</p>
    <div>
      <form [formGroup]="searchLearnerForm" class="flex justify-center">
        <mat-form-field class="w-full md:w-1/3 sm:w-1/2">
          <mat-label>Search</mat-label>
          <input 
            matInput
            placeholder="Search"
            formControlName="search"
          />
        </mat-form-field>
      </form>
      <div class="w-full justify-center" style="display: ruby;">
        <div class="flex justify-center w-full lg:w-1/2" *ngFor="let learner of learners">
          <div class="flex w-full m-2">
            <mat-icon class="check-icon-text-filled assign-training-check-box font-weight-bold leading-none text-primary"
            *ngIf="isSelected(learner)" (click)="removeLearner(learner)">
              done
            </mat-icon>
            <button class="un-check-icon-text-filled assign-training-check-box leading-none"
            *ngIf="!isSelected(learner)" (click)="addLearner(learner)">
            </button>
            <p class="pl-2">
              <span class="font-semibold">{{learner?.name}}</span>
              {{" - "}}{{learner?.value}}
            </p>
          </div>
        </div>
      </div>
      <div class="my-5 justify-center text-center">
        <h2 class="learner pb-5">Selected Users</h2>
        <button class="btn-primary-outlined small m-2" *ngFor="let user of selectedLearners"
        (click)="removeLearner(user)">
          {{ user.name }}
          <span>X</span>
          <!-- <mat-icon class="text-2xl">highlight_off</mat-icon> -->
        </button>
      </div>
      <div class="flex justify-center text-center mt-16">
        <button class="btn-primary large" [disabled]="selectedLearners.length === 0"
        (click)="selectLearners()">Select Users</button>   
      </div>
    </div>
  </ng-container>
</app-modal-layout>