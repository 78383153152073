
<div class="pricing-container">
  <div class="my-8 text-center">
    <h1>Designed for Organizations</h1>
    <h5>Mandated Reporter Training is trusted by over 50,000 organizations nationwide.</h5>
  </div>



<section class="w-full">
  <div class="flex flex-col lg:flex-row w-full gap-8">
    <div class="w-full lg:w-1/2">
      <app-annual-plan></app-annual-plan>
    </div>

    <div class="w-full lg:w-1/2">
      <app-pay-as-you-go></app-pay-as-you-go>
    </div>

  </div>
</section>
  





