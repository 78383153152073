import { Directive } from '@angular/core';
import { AccordionLinkDirective } from './accordionlink.directive';

@Directive({
    selector: '[appAccordion]',
    standalone: true,
})
export class AccordionDirective {
  protected navlinks: Array<AccordionLinkDirective> = [];

  addLink(link: AccordionLinkDirective): void {
    link.selected = link.expandOnLoad;
    this.navlinks.push(link);
  }

  removeGroup(link: AccordionLinkDirective): void {
    const index = this.navlinks.indexOf(link);
    if (index !== -1) {
      this.navlinks.splice(index, 1);
    }
  }
}
