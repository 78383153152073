import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AddUser } from './add-user.model';

export interface AddUsersState extends EntityState<AddUser> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'add-users', idKey: 'entityUserId' })
export class AddUsersStore extends EntityStore<AddUsersState> {
  constructor() {
    super();
  }
}
