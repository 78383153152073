<app-modal-layout>
  <div title>
    Join {{ orgName }}
  </div>
  <p>
    You have been invited to join the {{ orgName }} team on Mandated Reporter Training. Joining the team will give
    you access to take trainings and other resources shared by this organization. Don't worry, you will continue to
    have access to your individual account and any existing organization associations, if applicable.
  </p>
  <p class="mt-4">To accept the invitation from {{ orgName }}, click the Accept button below.</p>
  <ng-container *ngIf="!isLoading; else loading">
    <div class="text-center mt-4">
      <button class="btn-primary large mr-4" (click)="onAccept()" [disabled]="disableFlag">Accept</button>
      <button class="primary-link" [disabled]="disableFlag1" (click)="onDeclined()">
        Decline Invitation
      </button>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="text-center mt-4">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  </ng-template>
</app-modal-layout>