import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { UserGroup } from './user-group.model';
import { UserGroupsStore } from './user-groups.store';

@Injectable({ providedIn: 'root' })
export class UserGroupsService {
  constructor(private userGroupsStore: UserGroupsStore, private apiService: ApiService) {}

  getAll(entityGuid: any, params?: any) {
    return this.apiService.userGroup.getUserGroups(entityGuid, params).pipe(
      tap((entities) => {
        this.userGroupsStore.set(<any>entities);
      })
    );
  }

  upsert(userGroup: UserGroup) {
    return this.apiService.userGroup.upsertGroup(userGroup);
  }

  updateGroup(groupId: any, userGroup: UserGroup) {
    return this.apiService.userGroup.updateGroup(groupId, userGroup);
  }
}
