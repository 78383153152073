import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationErrors } from '../../../../api/interfaces/validation-errors';
import { Subject, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { CoursesQuery } from 'src/app/routes/learner/courses/state/courses.query';
import { LookupsQuery } from 'src/app/lookups/lookups.query';
import { UserGroupsService } from '../../../../routes/org/team/user-groups/state/user-groups.service';
import { OrganizationQuery } from 'src/app/routes/org/organization/org-profile/state/organization.query';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { LookupsStore } from 'src/app/lookups/lookups.store';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
export type Mode = 'add' | 'edit' | 'delete';
export interface DialogData {
  mode?: Mode;
  item?: any;
  canEdit?: boolean;
  deleteId: any;
  itemName?: string;
}
@Component({
    selector: 'app-add-user-groups',
    templateUrl: './add-user-groups.component.html',
    styleUrls: ['./add-user-groups.component.scss'],
    standalone: true,
    imports: [
        ModalLayoutComponent,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacySelectModule,
        NgFor,
        MatLegacyOptionModule,
        NgClass,
        MatIconModule,
        AsyncPipe,
        SnackbarComponent
    ],
})
export class AddUserGroupsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  disableFlag: boolean = false;
  public addGroups: UntypedFormGroup = Object.create(null);
  public selectedCourses: any[] = [];
  addGroupCoursesBtn: boolean = true;
  mode: Mode;
  groupListNumber: any;
  groupId!: number;

  status$ = this.lookupsQuery.entityGroupStatus$.pipe(
    map((status) => {
      if (status.length > 0) {
        return status;
      } else {
        return this.lookupsStore.entityGroupStatus();
      }
    }),
    switchMap((sta) => {
      if (sta === undefined) {
        return of(null);
      } else {
        return of(sta);
      }
    })
  );

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddUserGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private router: Router,
    public coursesQuery: CoursesQuery,
    public lookupsQuery: LookupsQuery,
    public lookupsStore: LookupsStore,
    private cdr: ChangeDetectorRef,
    private userGroupState: UserGroupsService,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService
  ) {
    this.mode = data.mode || 'add';
  }

  ngOnInit(): void {
    this.addGroups = this.fb.group({
      name: this.fb.control(null, Validators.required),
      isActive: this.fb.control(null, Validators.required),
    });
    if (this.mode === 'edit') {
      this.data?.item?.courseIds.forEach((id: number) => {
        this.organizationService.updateGroupCourse(id);
      });
      this.groupListNumber = this.data?.item?.numberOfUsers;
      this.groupId = this.data?.item?.id;
      this.addGroups.patchValue({
        ...this.data.item,
        isActive: this.data.item.statusId,
      });
    }
  }

  onSubmitGroups() {
    if (!this.addGroups.valid) {
      this.addGroups.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors) => {
      if (validationErrors.length) {
        validationErrors.forEach((error) => {
          const control = this.addGroups.get(error.field);
          if (control) {
            this.addGroups.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
      }

      return of(null);
    };

    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
    let courseIds: any[] = [];
    this.organizationQuery
      .selectAll()
      .pipe(
        take(1),
        map((data) => {
          return data;
        })
      )
      .subscribe((data) => {
        const activeButtonArray = data.filter((item: any) => {
          return item.course.groupCourse === true;
        });
        if (activeButtonArray?.length > 0) {
          activeButtonArray.forEach((item: any) => {
            courseIds.push(item?.courseId);
          });
        }
      });

    if (this.data?.mode === 'add') {
      const { isActive, ...remaining } = this.addGroups.value;
      const addGroupsObject = {
        courseIds: courseIds,
        entityGuid: entityGuid,
        statusId: isActive,
        ...remaining,
      };
      this.userGroupState
        .upsert(addGroupsObject)
        .pipe(
          catchError((err) => {
            return throwError(err);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (data) => {
            this.closeModal();
            this.lookupsStore.groups();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Group Added' }})
            this.disableFlag = false;
          },
          error: (err) => {
            validationErrorHandler(err);
            this.disableFlag = false;
          },
        });
    } else if (this.data?.mode === 'edit') {
      const { isActive, name, ...remaining } = this.addGroups.value;

      const addGroupsObject = {
        courseIds: courseIds,
        statusId: isActive,
        groupName: name,
        ...remaining,
      };

      this.userGroupState
        .updateGroup(this.data?.item?.id, addGroupsObject)
        .pipe(
          catchError((err) => {
            return throwError(err);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (data) => {
            this.closeModal();
            this.lookupsStore.groups();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Group Updated' }})
            this.disableFlag = false;
          },
          error: (err) => {
            validationErrorHandler(err);
            this.disableFlag = false;
          },
        });
    }

    //
  }

  goToGrid() {
    this.router.navigateByUrl(`/team/all-users?groupId=${this.groupId}`).then();
    this.closeModal();
  }
  goToOrg() {
    this.router.navigateByUrl('/trainings/trainings').then();
    this.closeModal();
  }
  closeModal() {
    this.dialogRef.close();
  }

  handleCourseSelection(course: any, selected: any) {
    const selectCourses = [...this.selectedCourses];

    const i = selectCourses.findIndex((sCourse) => sCourse.id === course.id);
    if (i >= 0) {
      selectCourses.splice(i, 1);
    }
    if (selected?.courseSelected) {
      selectCourses.push(course);
    }

    this.selectedCourses = selectCourses;
    this.cdr.detectChanges();
  }
  selectCourse(course: any) {
    this.organizationService.updateGroupCourse(course.id);
    this.organizationQuery
      .selectAll()
      .pipe(
        take(1),
        map((data) => {
          return data;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        const activeButtonArray = data.filter((item: any) => {
          return item.course.editCourse === true;
        });
        if (activeButtonArray?.length > 0) {
          this.addGroupCoursesBtn = false;
        } else {
          this.addGroupCoursesBtn = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.organizationService.resetGroupCourse();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
