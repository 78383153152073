import { Directive, HostListener, Inject } from '@angular/core';
import { EntityCoursesService } from 'src/app/routes/learner/courses/components/state/entity-courses.service';
import { AccordionLinkDirective } from './accordionlink.directive';

@Directive({
    selector: '[appAccordionToggle]',
    standalone: true,
})
export class AccordionAnchorDirective {
  protected navlink: AccordionLinkDirective;

  constructor(
    @Inject(AccordionLinkDirective) navlink: AccordionLinkDirective,
    private entityCoursesService: EntityCoursesService
  ) {
    this.navlink = navlink;
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    if (this.navlink.selectItem === 'sub') {
      this.navlink.toggle();
    } else if (this.navlink.selectItem === 'link') {
      this.navlink.selected = true;
    }

    this.entityCoursesService.upgradeCourses.next(true);
  }
}
