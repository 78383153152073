<app-modal-layout>
  <div title>Share Public Association Page</div>
  <p>
    The Public Association page for your Cooperative enables learners to register and view your trainings. As learners associate, you will see them listed on this Team page.
  </p>
  <p>
    To share the link to your page, simply copy the URL below and share with your team.
  </p>
  <form [formGroup]="form">
    <mat-form-field class="w-full">
      <mat-label>Public User Association URL </mat-label>
      <input
        id="publicUserAssociationURL"
        class="flex-wrap"
        type="text"
        matInput
        formControlName="publicUserAssociationURL"
        readonly="true"
      />
      <mat-icon matSuffix class="primary-link" (click)="clipBoard()"
        >assignment</mat-icon
      >
    </mat-form-field>
  </form>
  <div class="text-center">
    <button
    class="btn-primary large"
      (click)="clipBoard()"
    >
      Copy Link to Clipboard
    </button>
  </div>
</app-modal-layout>

