<div *ngIf="isLoaded">
  <!-- <div class="content-container w-full h-full" *ngIf="upgradeRequired">
    <img src="assets/images/learner-metrics.png" class="w-full h-full" />
  </div> -->
  <div class="content-container">
    <mat-toolbar class="title-bar mb-5 pb-5 lg:pb-0 justify-center h-auto lg:h-20">
      <div class="flex flex-wrap items-center w-full" [formGroup]="filtersForm">
        <div class="w-full lg:w-1/2">
          <div class="lg:w-full lg:pr-4">
            <mat-form-field class="w-full lg:w-4/5">
              <mat-label>Search</mat-label>
              <input matInput placeholder="Search" #input formControlName="search" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="w-full lg:w-1/2 flex flex-wrap lg:items-center lg:justify-end">
          <div class="w-full lg:w-5/6 flex flex-wrap">
            <div class="w-full lg:w-1/2 lg:pr-4">
              <mat-form-field class="w-full">
                <mat-label>From</mat-label>
                <input matInput [matDatepicker]="picker1" (focus)="picker1.open()" formControlName="fromDate" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2 lg:pr-4">
              <mat-form-field class="w-full">
                <mat-label>To</mat-label>
                <input matInput [matDatepicker]="picker" (focus)="picker.open()" formControlName="toDate" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <!-- <div class="w-full lg:w-1/4 lg:pr-4">
              <mat-form-field class="w-full">
                <mat-label>Training</mat-label>
                <mat-select formControlName="courseId" panelClass="mat-select-mt3">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option *ngFor="let course of courses$ | async" [value]="course.value">{{ course.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/4">
              <mat-form-field class="w-full">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" panelClass="mat-select-mt3">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option value="Complete">Complete</mat-option>
                  <mat-option value="In_Progress">In Progress</mat-option>
                  <mat-option value="Not_Started">Not Started</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          </div>
          <div class="w-full lg:w-auto flex justify-center lg:justify-start text-center lg:text-right">
            <button mat-button type="button" class="primary-link cursor-pointer" (click)="clearFilters()">
              <mat-icon> highlight_off </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-toolbar>
    <div class="grid-table">
      <div class="table-responsive-offsite-certs">
        <table mat-table [dataSource]="dataSource" class="w-full" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="studentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Student Name</th>
            <td mat-cell *matCellDef="let row">
              {{ row.studentName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="studentId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Student Id</th>
            <td mat-cell *matCellDef="let row">
              {{ row.studentId }}
            </td>
          </ng-container>
          <ng-container matColumnDef="courseName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Course Name</th>
            <td mat-cell *matCellDef="let row">
              {{ row.courseName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="training">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Training</th>
            <td mat-cell *matCellDef="let row">
              {{ row.training }}
            </td>
          </ng-container>
          <ng-container matColumnDef="issueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Issue Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row.issueDate ? (row.issueDate | date: 'MM/dd/yyyy') : '-' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="expiryDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Expires</th>
            <td mat-cell *matCellDef="let row">
              {{ row.expiryDate ? (row.expiryDate | date: 'MM/dd/yyyy') : '-' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Certification Number<th>
            <td mat-cell *matCellDef="let row">
              <a class="w-full flex btn-action primary-link" (click)="downloadCert(row.id)">
                {{ row.certificationName }}
              </a>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data</td>
          </tr>
        </table>
        <div class="flex my-4 w-full px-2 justify-between items-center custom-pagination">
          <div class="flex">
            <a (click)="exportCsv()" class="primary-link cursor-pointer filter-alignment {{
                disableflag === true ? 'pointer-events-none' : ''
              }}">Export to CSV</a>
          </div>
          <div class="flex items-center gap-4">
            <div class="flex items-center mat-paginator-page-size">
              <div class="custom-pagination-label">Items per page:</div>
              <div [formGroup]="paginationForm">
                <mat-form-field class="mat-paginator-page-size-select">
                  <mat-select formControlName="pageSize">
                    <mat-option *ngFor="let pageSize of pageSizeOptions" [value]="pageSize">
                      {{ pageSize }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="flex custom-pagination-buttons">
              <div class="">
                <span>Items {{ itemsFrom }}-{{ itemsTo }} of {{ totalNumberOfItems }}</span>
              </div>
              <mat-icon class="cursor-pointer select-none" (click)="paginationLeft()">chevron_left</mat-icon>
              <div>
                <span>{{ currentPageNumber }}</span>
                <span>of</span>
                <span>{{ totalNumberOfPages }}</span>
              </div>
              <mat-icon class="cursor-pointer select-none" (click)="paginationRight()">chevron_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>