import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { EntityCourse } from './entity-course.model';

export interface EntityCoursesState extends EntityState<EntityCourse>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'entity-courses' })
export class EntityCoursesStore extends EntityStore<EntityCoursesState> {
  constructor() {
    super();
  }
}
