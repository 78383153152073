import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';

export interface CommonModalDialogData {
  title: string;
  message1: string;
  button1Text: string;
  button2Text?: string;
  message2?: string;
  customHtmlMessage?: string;
  button1Clicked?: () => void;
  button2Clicked?: () => void;
  disableClose?: boolean;
}

@Component({
    selector: 'app-common-modal',
    templateUrl: './common-modal.component.html',
    styleUrls: ['./common-modal.component.scss'],
    standalone: true,
    imports: [ModalLayoutComponent, NgIf],
})
export class CommonModalComponent implements OnInit, AfterViewInit {
  title!: string;
  message1!: string;
  message2!: string;
  button1Text!: string;
  button2Text!: string;
  customHtmlMessage!: string;
  button1Clicked!: () => void;
  button2Clicked!: () => void;
  disableClose!: boolean;
  disableFlag!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CommonModalDialogData,
    private dialogRef: MatDialogRef<CommonModalComponent>,
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.message1 = this.data.message1;
    this.message2 = this.data.message2!;
    this.customHtmlMessage = this.data.customHtmlMessage!;
    this.button1Text = this.data.button1Text;
    this.button2Text = this.data.button2Text!;
    this.disableClose = this.data.disableClose!;
  }

  ngAfterViewInit(): void {
    this.button1Clicked = this.data.button1Clicked!;
    this.button2Clicked = this.data.button2Clicked!;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
