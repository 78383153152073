import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { EditUser } from './edit-user.model';
import { EditUsersStore } from './edit-users.store';
import { ApiService } from '../../../../../api/services/api.service';

@Injectable({ providedIn: 'root' })
export class EditUsersService {
  constructor(private editUsersStore: EditUsersStore, private api: ApiService) {}

  update(user: EditUser) {
    return this.api.organization.updateOrgUser(user);
  }

  getUser(entityUserId: any) {
    return this.api.organization.getuser(entityUserId);
  }
}
