import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../../../../api/services/api.service';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthQuery } from '../../state/auth.query';
import { AuthService } from '../../state/auth.service';
import { Subject, merge, of, throwError } from 'rxjs';
import { LoginService } from './state/login.service';
import { RegistrationService } from '../register/registration.service';
import { environment } from 'src/environments/environment';
import { InviteAcceptConfirmedService } from 'src/app/api/services/invite-accept-confirmed/invite-accept-confirmed.service';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgIf, NgClass } from '@angular/common';
import { MatLegacySnackBar, MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { TotpType, TotpService } from 'src/app/api/services/TOTP/totp.service';
import { VerifyType } from '../verify-email/verify-email.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacySnackBarModule,
    NgClass,
    MatIconModule,
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public form: UntypedFormGroup = Object.create(null);
  public hide: boolean = true;
  email!: string;
  dontCallAgain: boolean = false;
  moduleID: any[] = [];
  disableFlag: boolean = true;
  verifyEmail: boolean = false;
  loading: boolean = false;
  joinRequestGuid!: string;
  inviteValid: boolean = false;
  inviteOrgName!: string;
  is2FaRequired: boolean = false;
  is2faSetup: boolean = true;
  qrCodeImgSrc: string = '';
  showQrCodeFlag: boolean = false;
  downloadApp: boolean = false;
  setupVerifyAuthCode: boolean = false;
  verifyAuthCode: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private snackBar: MatLegacySnackBar,
    private loginService: LoginService,
    private registrationService: RegistrationService,
    private totpService: TotpService,
    private inviteService: InviteAcceptConfirmedService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.form = this.fb.group({
      userName: this.fb.control(null, [Validators.required, Validators.email]),
      password: this.fb.control(null, Validators.required),
      verificationCode: this.fb.control(null),
    });

    this.loginService.dontCallAgain.pipe(takeUntil(this.destroy$)).subscribe((call) => {
      this.dontCallAgain = call;
    });

    this.inviteService.inviteInfo$.pipe(takeUntil(this.destroy$)).subscribe((inviteInfo) => {
      if (inviteInfo?.inviteValid) {
        this.form.patchValue({
          userName: inviteInfo?.email,
        });
        this.form.get('userName')?.disable();
        this.inviteValid = true;
        this.inviteOrgName = inviteInfo?.inviteOrgName;
      }

      if (inviteInfo?.isJoinRequest) {
        this.joinRequestGuid = inviteInfo?.inviteGuid!;
      }
      this.disableFlag = false;
    });
  }

  onSubmit() {
    this.loading = true;
    this.disableFlag = true;
    this.authService
      .login(this.form.getRawValue(), this.joinRequestGuid)
      .pipe(
        catchError((e) => throwError(e)),
        tap((response) => {
          if (!response.jwt && !response.twoFactorRequired) {
            this.loading = false;
            this.disableFlag = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: 'Incorrect username/password' },
            });
          }

          if (response.jwt && response.twoFactorRequired !== true) {
            const redirectUrlParam = this.route.snapshot.queryParams['redirecturl'];
            if (redirectUrlParam) {
              window.location.href = redirectUrlParam;
            }
          }

          if (response.twoFactorRequired) {
            this.loading = false;
            this.is2FaRequired = true;

            if (response.qrCode) {
              this.downloadApp = true;
              this.is2faSetup = false;
              // show QR code
              this.qrCodeImgSrc = 'data:image/bmp;base64,' + response.qrCode;
            } else {
              this.verifyAuthCode = true;
            }

            this.disableFlag = false;
            this.form.get('verificationCode')?.setValidators(Validators.required);
          }
        }),
        filter((response) => Boolean(response.jwt)),
        switchMap(() => {
          return this.authQuery.user$;
        }),
        map((user) => {
          if (!user) {
            return;
          }

          return user;
        }),
        switchMap((user) => {
          if (user === undefined) {
            return of(null);
          } else {
            const { orgAndRoles } = user;
            const observables = orgAndRoles.map((org: any) => {
              return this.api.courseApiService.getAllDashboardCourses(org?.entityGuid);
            });
            return merge<any[]>(...observables);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (data) => {
          this.disableFlag = false;
          if (data) {
            data.forEach((item: any) => {
              const modules: any = item?.modules;
              if (modules?.length > 0) {
                if (
                  (modules[modules.length - 1].active === true && modules[0].status === 'Complete') ||
                  (modules[modules.length - 1].active === true && modules[0].status === 'Passed')
                ) {
                  this.moduleID.push(modules[0].id);
                  window.localStorage.setItem(
                    'course-module',
                    JSON.stringify({ moduleID: this.moduleID, logout: true }),
                  );
                }
              }
            });
          }
        },
        (e) => {
          this.loading = false;
          let err = e;
          this.disableFlag = false;
          const control = this.form?.get('userName');
          this.verifyEmail = false;
          if (err?.error?.login_failure?.length > 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: 'Incorrect username/password' },
            });
          } else if (err?.error?.verify_email?.length > 0) {
            this.verifyEmail = true;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: 'Incorrect username/password' },
            });
          }
        },
      );
  }

  onSubmit2fa() {
    this.loading = true;
    this.disableFlag = true;

    this.authService
      .login2fa(this.form.value)
      .pipe(
        catchError((e) => throwError(e)),
        filter((response) => Boolean(response.jwt)),
        switchMap(() => {
          return this.authQuery.user$;
        }),
        map((user) => {
          if (!user) {
            return;
          }

          return user;
        }),
        switchMap((user) => {
          if (user === undefined) {
            return of(null);
          } else if (user.roles.includes('Admin') || user.roles.includes('LeadSupport')) {
            this.router.navigateByUrl('/login-as').then();
            return of(null);
          } else {
            const { orgAndRoles } = user;
            const observables = orgAndRoles.map((org: any) => {
              return this.api.courseApiService.getAllDashboardCourses(org?.entityGuid);
            });
            return merge<any[]>(...observables);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (data) => {
          this.disableFlag = false;
          if (data) {
            data.forEach((item: any) => {
              const modules: any = item?.modules;
              if (modules?.length > 0) {
                if (
                  (modules[modules.length - 1].active === true && modules[0].status === 'Complete') ||
                  (modules[modules.length - 1].active === true && modules[0].status === 'Passed')
                ) {
                  this.moduleID.push(modules[0].id);
                  window.localStorage.setItem(
                    'course-module',
                    JSON.stringify({ moduleID: this.moduleID, logout: true }),
                  );
                }
              }
            });
          }
        },
        (e) => {
          this.loading = false;
          this.disableFlag = false;
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Error, message: 'Incorrect verification code' },
          });
        },
      );
  }

  checkEmail(email: any, control: any) {
    this.disableFlag = false;
    if (control?.errors === null) {
      this.registrationService
        .validEmail(email.target.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          if (data.inviteExists) {
            control.markAsTouched();
            control.setErrors({
              invite: { message: 'You have an open invitation.', email: `${email.target.value}` },
            });
          }
        });
    }
  }

  resendEmail() {
    this.totpService.totpResend(this.form?.get('userName')?.value?.trim(), TotpType.VerifyEmail).subscribe({
      next: () => {
        const email = this.form?.get('userName')?.value?.trim();
        const forgot = false;
        this.router.navigateByUrl('/verify-email', { state: { email, forgot, verifyType: VerifyType.VerifyEmail } });
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 4000,
          data: { toastType: ToastType.Success, message: 'Verification Code Resent' },
        });
      },
      error: (error) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Something went wrong, please try again.' },
        });
      },
    });
  }

  resendInvite(email: any) {
    const acceptUrl = `${environment?.domainName}/accept-invitation-confirmed`;
    this.inviteService
      .resendInvite(email, acceptUrl)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.router.navigateByUrl(`/verify?email=${this.form.get('userName')?.value}&forgot=false`);
        },
        error: (err) => {
          this.form.reset();
        },
      });
  }

  showQRCode() {
    this.downloadApp = false;
    this.setupVerifyAuthCode = true;
  }

  showVerifyAuthCode() {
    this.setupVerifyAuthCode = false;
    this.verifyAuthCode = true;
  }
}
