  <h1 class="text-center text-primary">Add Trainings</h1>
  <h4 class="text-center my-md hidden lg:block">Use the filters to easily find the trainings you need.</h4>
  <div [formGroup]="filtersForm" class="flex flex-wrap justify-center lg:gap-5 mb-5 mt-8 lg:mt-0">
    <app-user-cascading-dropdowns *ngIf="showTypeAndState || true" class="w-full select-course-cascading-dropdowns center-course-cascading-dropdowns" [form]="filtersForm"></app-user-cascading-dropdowns>
  </div>
  <div *ngIf="trainingCoursesFound; else noneFound">
    <h4 class="text-center mb-1xl hidden lg:block">Select the training(s) you wish to add below then click “Add Training”.</h4>
    <form [formGroup]="selectCourseForm">
      <div
        class="grid grid-rows-columns lg:ml-auto lg:mr-auto justify-center gap-8 mb-2xl">
        <app-choose-course-text [item]="course" *ngFor="let course of availableCoursesFiltered"
          class="w-full h-full transform transition lg:hover:scale-110"></app-choose-course-text>
      </div>
      <div class="flex justify-center text-center mb-16">
        <button class="btn-primary large" [disabled]="disableAddTrainingBtn"
          (click)="(isEntityAdmin || isCoopAdmin) ? addCoursesOrgAdmin() : addCoursesLearner()">
          <mat-spinner class="mr-5" *ngIf="addTrainingLoading" [diameter]="20"></mat-spinner>Add Trainings
        </button>
      </div>
    </form>
  </div>
  <ng-template #noneFound>
    <p *ngIf="!showTypeAndState" class="text-center font-semibold">
      No trainings found that match your search criteria.
      <br>
      Use the filters above to modify your search or contact your
      organization's administrator to assign trainings.
    </p>
    <p *ngIf="showTypeAndState" class="text-center font-semibold">
      No trainings found that match your search criteria. Use the filters above to modify your search.
    </p>
  </ng-template>