import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class TrainingMetricsService extends BaseAPI{
  getReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    requestParams.entityGuid = entityGuid;
    return this.http.get(`entityAdminReports/training-metrics`, {
      params: requestParams,
    });
  }
}
