import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgxMaskDirective } from 'ngx-mask';

const ECE_NUMBER_REGEX = /^1\d{8}$/;

@Component({
  selector: 'app-external-number',
  templateUrl: './external-number.component.html',
  styleUrls: ['./external-number.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    MatLegacyInputModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    NgxMaskDirective
  ]
})
export class ExternalNumberComponent implements OnInit {
  @Input() form!: UntypedFormGroup;
  @Input() isProfile = false;
  showExtNum = false;

  constructor() { }

  ngOnInit() {
    // professions are based on industry selection
    this.form.get('industryId')?.valueChanges.subscribe((industryId) => {
      this.checkShowExternalNumber();
    });

    this.form.get('stateId')?.valueChanges.subscribe(() => {
      this.checkShowExternalNumber();
    });
  }

  checkShowExternalNumber() {
    // if state is California, and industry is Education, Child or Elder Care, or Counseling, show external number
    if (this.form.get('stateId')?.value === 5) {
      if (
        this.form.get('industryId')?.value === 3 ||
        this.form.get('industryId')?.value === 4 ||
        this.form.get('industryId')?.value === 12
      ) {
        this.showExtNum = true;
        this.form.get('externalIdentityNumber')?.setValidators(Validators.compose([Validators.pattern(ECE_NUMBER_REGEX)]));
      } else {
        this.showExtNum = false;
        this.form.get('externalIdentityNumber')?.clearValidators();
        this.form.get('externalIdentityNumber')?.reset();
      }
    } else {
      this.showExtNum = false;
      this.form.get('externalIdentityNumber')?.clearValidators();
      this.form.get('externalIdentityNumber')?.reset();
    }
  }

}
