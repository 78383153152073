import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { SwitchEntityProfile } from './switch-entity-profile.model';

export interface SwitchEntityProfileState extends EntityState<SwitchEntityProfile>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'switch-entity-profile', idKey: 'entityGuid' })
export class SwitchEntityProfileStore extends EntityStore<SwitchEntityProfileState> {
  constructor() {
    super();
  }
}
