<div class="banner-message" *ngIf="displayBanner">
  <div class="flex flex-col gap-2 md:flex-row justify-between md:items-center w-full">
    <div>
      <h2 class="learner text-primary font-bold">{{ title }}</h2>
      <p>{{ message }} <span *ngIf="link"><a href="{{link}}" class="primary-link underline">{{linkText}}</a></span></p>
    </div>
    <div *ngIf="buttonText !== ''" class="h-10 flex items-center text-base">
      <button
        class="btn-secondary medium cursor-pointer items-center text-center whitespace-nowrap"
        (click)="upgradeClicked()"
        [disabled]="disableUpgradeButton"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>
