import { Directive, HostBinding, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccordionDirective } from './accordion.directive';

@Directive({
    selector: '[appAccordionLink]',
    standalone: true,
})
export class AccordionLinkDirective implements OnInit, OnDestroy {
  @Input() public group: any;
  @Input() public selectItem: any;
  @Input() public expandOnLoad: any;

  @HostBinding('class.selected')
  @Input()
  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  @Input() set sidebarOpen(isOpen: boolean) {
    this.selected = isOpen;
  }

  protected _selected = false;
  nav: AccordionDirective;
  constructor(@Inject(AccordionDirective) nav: AccordionDirective, public route: ActivatedRoute) {
    this.nav = nav;
  }

  ngOnInit(): any {
    this.nav.addLink(this);
  }

  ngOnDestroy(): any {
    this.nav.removeGroup(this);
  }

  toggle(): any {
    this.selected = !this.selected;
  }
}
