import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  showSpinner = new BehaviorSubject<boolean>(false);
  spinnerBackground = new BehaviorSubject<boolean>(true);

  show() {
    this.showSpinner.next(true);
  }

  hide() {
    this.showSpinner.next(false);
  }

  whiteBackground() {
    this.spinnerBackground.next(true);
  }

  overlayBackground() {
    this.spinnerBackground.next(false);
  }
  constructor() {}
}
