<app-modal-layout>
  <div title>Reset Password</div>
  <p>
    Enter the email address and we'll send the user a link to reset their password.
  </p>
  <form [formGroup]="form">
    <div class="reset-psw-max-w">
      <mat-form-field class="w-full">
        <mat-label>Email</mat-label>
        <input type="text" matInput formControlName="email" />
        <mat-error *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">Required
        </mat-error>
        <mat-error *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched">You must include a
          valid email.
        </mat-error>
        <mat-error *ngIf="form.controls['email'].errors?.message">{{
          form.controls['email'].getError('message')
          }}</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="text-center">
    <button class="btn-primary large" [disabled]="disableFlag" (click)="onSubmit()">
      Send Email
    </button>
  </div>
</app-modal-layout>