import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EntityCoursesStore } from './entity-courses.store';
import { ApiService } from '../../../../../api/services/api.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EntityCoursesQuery } from './entity-courses.query';

export enum ModuleStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Download = 'Download',
}

@Injectable({ providedIn: 'root' })
export class EntityCoursesService {
  constructor(
    private entityCoursesStore: EntityCoursesStore,
    private entityCoursesQuery: EntityCoursesQuery,
    private api: ApiService,
    private http: HttpClient,
  ) {}

  upgradeCourses = new BehaviorSubject(false);
  retakeCourses = new BehaviorSubject({ moduleID: <any>[], logout: <boolean>false });
  isLoading$ = this.entityCoursesQuery.selectLoading();

  getAllDashboardCourses() {
    const entityGuid = localStorage.getItem('entityGuid');
    return this.api.courseApiService.getAllDashboardCourses(entityGuid).pipe(
      tap((entities: any) => {
        if (!this.entityCoursesQuery.hasActive()) {
          const selectedCourse = entities.find((entity: any) => entity.selected);
          this.entityCoursesStore.set(entities, { activeId: selectedCourse?.id });
        } else {
          this.entityCoursesStore.set(entities);
        }
      }),
    );
  }

  getCourseHistory() {
    const entityGuid = localStorage.getItem('entityGuid');
    return this.api.courseApiService.getCourseHistory(entityGuid);

  }

  getShareOptions(certId: number) {
    return this.api.courseApiService.getShareOptions(certId);
  }

  setActive(id: any) {
    this.entityCoursesStore.setActive(id);
  }

  downloadCert(certId: string) {
    let url = 'certificate/' + certId;
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      this.downloadFile(blob, certId);
    });
  }

  downloadFile(blob: Blob, certId: string) {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = 'CERT-' + certId.toString().padStart(8, '0') + '.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  buyCert(userLearningPathId: number, certComplete: boolean, successReturnPath: string) {
    this.api.courseApiService.createCertSession(userLearningPathId, certComplete, successReturnPath).subscribe((res: any) => {
      window.location.href = res;
    });
  }

  shareCertificate(entityGuid: string, certId: number) {
    return this.api.courseApiService.shareCert(entityGuid, certId);
  }

  getCourseStatus(course: any) {
   return course.status;
  }

  getModuleStatus(module: any) {
    switch (module.status) {
      case 'Not Started':
        return ModuleStatus.NotStarted;
      case 'In Progress':
        return ModuleStatus.InProgress;
      case 'Passed':
        return ModuleStatus.Complete;
      case 'Complete':
        return ModuleStatus.Complete;
      default:
        return ModuleStatus.NotStarted;
    }
  }
}
