<app-modal-layout>
  <div title>
    <ng-container *ngIf="mode === 'add'">Add Resource</ng-container>
    <ng-container *ngIf="mode === 'edit'">Update Resource</ng-container>
  </div>
  <form [formGroup]="addResources">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
        <mat-form-field class="w-full">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" #t panelClass="mat-select-mt3">
            <mat-option value="Link">Link</mat-option>
            <mat-option value="File">File</mat-option>
          </mat-select>
          <mat-error
            *ngIf="addResources.controls['type'].hasError('required') && addResources.controls['type'].touched">
            Required
          </mat-error>
          <mat-error *ngIf="addResources.controls['type'].errors?.message">{{
            addResources.controls['type'].getError('message')
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
        <mat-form-field class="w-full">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status" panelClass="mat-select-mt3">
            <mat-option value="Archived">Archive</mat-option>
            <mat-option value="Draft">Draft</mat-option>
            <mat-option value="Published">Published</mat-option>
          </mat-select>
          <mat-error
            *ngIf="addResources.controls['status'].hasError('required') && addResources.controls['status'].touched">
            Required
          </mat-error>
          <mat-error *ngIf="addResources.controls['status'].errors?.message">{{
            addResources.controls['status'].getError('message')
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-full sm:pr-6">
        <mat-form-field class="w-full">
          <mat-label>Resource Name</mat-label>
          <input type="text" matInput formControlName="name" />
          <mat-error
            *ngIf="addResources.controls['name'].hasError('required') && addResources.controls['name'].touched">
            Required
          </mat-error>
          <mat-error *ngIf="addResources.controls['name'].errors?.message">{{
            addResources.controls['name'].getError('message')
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-full sm:pr-6">
        <mat-form-field class="w-full">
          <mat-label>Description (Limited to 300 characters)</mat-label>
          <input type="text" matInput maxlength="300" formControlName="description" />
          <mat-error *ngIf="
                  addResources.controls['description'].hasError('required') &&
                  addResources.controls['description'].touched
                ">
            Required
          </mat-error>
          <mat-error *ngIf="addResources.controls['description'].errors?.message">{{
            addResources.controls['description'].getError('message')
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-full sm:pr-6" *ngIf="t.value === 'Link'">
        <mat-form-field class="w-full">
          <mat-label>Link</mat-label>
          <input type="text" matInput formControlName="url" />
        </mat-form-field>
        <mat-error *ngIf="addResources.controls['url'].hasError('required') && addResources.controls['url'].touched">
          Required
        </mat-error>
        <mat-error *ngIf="addResources.controls['url'].hasError('pattern')" class="text-danger support-text mt-0">
          url pattern invalid
        </mat-error>
        <mat-error *ngIf="addResources.controls['url'].errors?.message">{{
          addResources.controls['url'].getError('message')
          }}</mat-error>
      </div>
      <div class="w-full sm:pr-6" *ngIf="t.value === 'File'">
        <span class="sub-heading">File (Limited to PDF, doc, xlsx, jpg, png file types and 1Mb file size)</span>
        <div class="label-input-file1 w-full">
          <label for="photos">
            <input type="file" hidden id="photos"
              accept="image/png,image/jpg,image/jpeg,application/pdf,application/doc,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              (change)="onFileChange($event)" />

            <span class="btn-primary-outlined small input-file-btn">Upload File</span>
            <mat-error
              *ngIf="addResources.controls['file'].hasError('required') && addResources.controls['file'].touched">
              Required
            </mat-error>
          </label>
        </div>
        <div>
          <div *ngIf="failImageFlag" class="{{ failImageFlag ? 'mb-4' : '' }}">
            <span class="image-error">
              We accept only jpg,png,pdf,docs,xlsx files and file size must be less than 1mb</span>
          </div>
          <div *ngIf="failSizeFlag" class="{{ failSizeFlag ? 'mb-4' : '' }}">
            <span class="image-error"> file size must be less than 1mb </span>
          </div>
          <div *ngIf="failFlag" class="{{ failFlag ? 'mb-4' : '' }}">
            <span class="image-error"> We accept only jpg,png,pdf,docs,xlsx files </span>
          </div>
        </div>
        <div *ngIf="fileNameDisplay">
          <div class="mb-1 w-full sm:pr-6 flex flex-wrap sm:w-1/2 justify-between items-center">
            <p>
              {{ fileNameDisplay }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="text-center">
    <button class="btn-primary large" (click)="onSubmitResources()" [disabled]="showDisabled">
      <ng-container *ngIf="mode === 'add'">Add Resource</ng-container>
      <ng-container *ngIf="mode === 'edit'">Update Resource</ng-container>
    </button>
  </div>
</app-modal-layout>