import { Component, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, map } from 'rxjs';
import { SeoOptions } from './shared/interfaces/seo-options.interface';
import { AsyncPipe, DOCUMENT, NgFor, NgIf } from '@angular/common';
import { DebugService } from './shared/services/debug.service';

const BLANK_SEO_OPTIONS: SeoOptions = {
  setTitleManually: null,
  title: '',
  title2: '',
  description: '',
  robots: '',
  canonicalUrl: '',
  og: {
    title: '',
    description: '',
    image: null,
    url: null,
    site_name: null,
    type: null,
  },
  twitter: {
    title: '',
    description: '',
    url: null,
    card: null,
    image: null,
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgIf, NgFor, AsyncPipe],
})
export class AppComponent {
  loading = true;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private document: Document,
    public debugService: DebugService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        // mergeMap((route) => route.data),
      )
      .subscribe((event: any) => {
        const seoOptions = event.data.value.seoOptions as SeoOptions;
        let title: string;
        title = this.router.url.replace('/', '');
        if (title.includes('/')) title = title.split('/').join(' > ');
        if (title.includes('-')) title = title.split('-').join(' ');
        // capitalize first letter of each word
        title = title.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

        // remove all meta tags using the keys from the BLANK_SEO_OPTIONS object
        Object.keys(BLANK_SEO_OPTIONS).forEach((key) => {
          if (typeof BLANK_SEO_OPTIONS[key] === 'string') this.metaService.removeTag(`name="${key}"`);
          else {
            if (BLANK_SEO_OPTIONS[key] != null) {
              Object.keys(BLANK_SEO_OPTIONS[key]).forEach((subKey) => {
                if (typeof BLANK_SEO_OPTIONS[key][subKey] === 'string') {
                  this.metaService.removeTag(`property="${key}:${subKey}"`);
                } else if (BLANK_SEO_OPTIONS[key][subKey]) {
                  Object.keys(BLANK_SEO_OPTIONS[key][subKey]).forEach((subSubKey) => {
                    this.metaService.removeTag(`property="${key}:${subKey}:${subSubKey}"`);
                  });
                }
              });
            }
          }
        });

        if (seoOptions) {
          if (seoOptions.setTitleManually) {
            if (title.split('?')[0] == 'Verify') {
              if (event.queryParams.value.forgot == 'true') {
                this.titleService.setTitle(seoOptions?.title);
              } else {
                this.titleService.setTitle(seoOptions?.title2);
              }
            }
          } else {
            this.titleService.setTitle(seoOptions?.title);
            if (seoOptions.canonicalUrl) {
              const url = this.document.createElement('link');
              url.setAttribute('rel', 'canonical');
              url.setAttribute('href', seoOptions.canonicalUrl);
              this.document.head.appendChild(url);
            }
          }
          // loop through the seoOptions object and update the meta tags
          Object.keys(seoOptions!).forEach((key) => {
            if (typeof seoOptions[key] === 'string') {
              if (!seoOptions.canonicalUrl && !key.includes('title')) {
                if (seoOptions[key]) this.metaService.addTag({ name: key, content: seoOptions[key] });
              }
              if (key.includes('title')) {
                if (seoOptions.setTitleManually) {
                  if (title.split('?')[0] == 'Verify') {
                    if (event.queryParams.value.forgot == 'true') {
                      this.metaService.addTag({ name: 'title', content: seoOptions['title'] });
                    } else {
                      this.metaService.addTag({ name: 'title', content: seoOptions['title2'] });
                    }
                  }
                } else {
                  this.metaService.addTag({ name: 'title', content: seoOptions[key] });
                }
              }
            } else {
              Object.keys(seoOptions[key]).forEach((subKey) => {
                if (typeof seoOptions[key][subKey] === 'string') {
                  if (seoOptions[key][subKey])
                    this.metaService.addTag({ property: `${key}:${subKey}`, content: seoOptions[key][subKey] });
                } else if (seoOptions[key][subKey]) {
                  Object.keys(seoOptions[key][subKey]).forEach((subSubKey) => {
                    if (seoOptions[key][subKey][subSubKey])
                      this.metaService.addTag({
                        property: `${key}:${subKey}:${subSubKey}`,
                        content: seoOptions[key][subKey][subSubKey],
                      });
                  });
                }
              });
            }
          });
        } else {
          Object.keys(event.data.value).forEach((key) => {
            let value = event.data.value[key];
            this.metaService.addTag({ property: key, content: value });
          });

          this.titleService.setTitle(`${title} - Mandated Reporter Training`);
        }

        this.loading = false;
      });
  }
}
