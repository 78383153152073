import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import { Login } from './login.model';
import { LoginStore } from './login.store';
import { ApiService } from '../../../../../api/services/api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoginService {
  dontCallAgain = new BehaviorSubject<boolean>(false);
  constructor(private loginStore: LoginStore, private api: ApiService) {}

  loadUser() {
    return this.api.auth.user().pipe(
      shareReplay(1),
      tap((entities) => {
        // @ts-ignore
        this.loginStore.set(entities);
      })
    );
  }

  add(login: Login) {
    this.loginStore.add(login);
  }

  update(id: any, login: Partial<Login>) {
    this.loginStore.update(id, login);
  }

  remove(id: any) {
    this.loginStore.remove(id);
  }
}
