import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { paths } from '../../interfaces/swagger-types';
import { shareReplay } from 'rxjs/operators';

export type Organization = paths['/api/EntityAdminEntity/update-org']['put']['requestBody']['content']['text/json'];

export type UpdateOrgUser =
  paths['/api/EntityAdminEntity/update-org-user']['put']['requestBody']['content']['text/json'];

export type AddOrganizationUser =
  paths['/api/EntityAdminEntity/invite-user/{entityGuid}']['post']['requestBody']['content']['text/json'];

@Injectable({
  providedIn: 'root',
})
export class OrganizationsApiService extends BaseAPI {
  public getCurrent(entityGuid: string) {
    return this.http.get(`org/${entityGuid}`).pipe(shareReplay(1));
  }
  public getCurrentPublic(entityGuid: string) {
    return this.http.get(`org/public/${entityGuid}`).pipe(shareReplay(1));
  }

  public isSlugAvailable(slug: string) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.get(`entityadminentity/is-slug-available/${entityGuid}${slug}`);
  }

  uploadImage(image: any) {
    return this.http.post(`org/upload-image`, image, { responseType: 'text' });
  }

  public update(org: any) {
    return this.http.put('entityAdminEntity/update-org', org);
  }

  public addOrg(orgUser: AddOrganizationUser) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`entityAdminEntity/invite-user/${entityGuid}`, orgUser);
  }
  public addOrgUsers(orgUsers: any[]) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`entityAdminEntity/invite-users/${entityGuid}`, orgUsers);
  }

  public adduser(orgUser: any) {
    return this.http.post(`joinRequest`, orgUser);
  }
  public updateOrgUser(user: UpdateOrgUser) {
    return this.http.put('entityAdminEntity/update-org-user', user);
  }

  getOrgUsers(requestedBody: any) {
    return this.http.get(`entityAdminEntity/orglearner-grid`, { params: requestedBody });
  }

  getAllExcelOrgUsers(requestedBody: any) {
    return this.http.get(`entityAdminEntity/orglearner-grid/export`, { params: requestedBody });
  }

  getOrgCourses(entityGuid: any) {
    return this.http.get(`org/courses/${entityGuid}`);
  }

  getOrgCourseIds(entityGuid: any) {
    return this.http.get(`org/org-course-ids/${entityGuid}`);
  }

  getuser(entityUserId: any) {
    return this.http.get(`entityAdminEntity/entity-user-details/${entityUserId}`);
  }

  createOrganization(body: any) {
    return this.http.post('org', body);
  }

  setDefaultOrg(entityGuid: string) {
    return this.http.post(`org/set-default-org/${entityGuid}`, null );
  }

  assignCourseUser(body: any) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`entityAdminCourse/assign-course-user/${entityGuid}`, body);
  }

  batchAssignTraining(body: any) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`entityAdminBatch/assign-course/${entityGuid}`, body);
  }
}
