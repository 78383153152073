import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
@Injectable({
  providedIn: 'root',
})
export class CoopCertsService extends BaseAPI {
  getCoopCertsReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminCoop/coop-certs/${entityGuid}`, {
      params: requestParams,
    });
  }
  exportCoopCertsReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminCoop/coop-certs/export/${entityGuid}`, {
      params: requestParams,
    });
  }
}
