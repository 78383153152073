import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../../api/services/api.service';
import { InviteAcceptConfirmedService } from 'src/app/api/services/invite-accept-confirmed/invite-accept-confirmed.service';
@Component({
    selector: 'app-accept-invitation-confirmed',
    templateUrl: './accept-invitation-confirmed.component.html',
    styleUrls: ['./accept-invitation-confirmed.component.scss'],
    standalone: true,
})
export class AcceptInvitationConfirmedComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inviteConfirmService: InviteAcceptConfirmedService
  ) {}

  ngOnInit(): void {
    const { inviteGuid, joinRequestGuid } = this.route.snapshot.queryParams;

    if (inviteGuid || joinRequestGuid) {
      this.inviteConfirmService
        .getInvite(inviteGuid ?? joinRequestGuid)
        .pipe(
          tap((data: any) => {
            if (data) {
              if (!data?.inviteValid) {
                this.inviteConfirmService.inviteInfo$.next(null);
                this.router.navigateByUrl(`/login`);
              }

              if (data?.userHasLogin) {
                if (data?.inviteCancelled) {
                  this.inviteConfirmService.inviteInfo$.next(null);
                  this.router.navigate([`/login`], {
                    queryParams: { cancelledInvite: true, orgName: data?.inviteOrgName },
                  });
                }

                if (data?.inviteValid) {
                  this.router.navigateByUrl('/login');
                }
              } else {
                if (data?.inviteCancelled) {
                  this.inviteConfirmService.inviteInfo$.next(null);
                  this.router.navigate([`/register`], {
                    queryParams: { cancelledInvite: true, orgName: data?.inviteOrgName },
                  });
                }

                if (data?.inviteValid) {
                  this.router.navigateByUrl('/invite/register');
                }
              }
            }
          })
        )
        .subscribe();
    } else {
      this.router.navigateByUrl(`/login`);
    }
  }
}
