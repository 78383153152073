<ng-container *ngIf="control">
  <mat-form-field class="w-full">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      [placeholder]="label"
      [multiple]="multiple"
      [formControl]="control"
      [compareWith]="compareFn"
      (selectionChange)="selectionChange.emit($event)"
      [attr.disabled]="disabled"
      disableOptionCentering
      panelClass="mat-select-mt3"
    >
      <mat-option [value]="option.value" *ngFor="let option of options">{{ option.label }}</mat-option>
    </mat-select>
    <mat-error *ngIf="control?.errors?.required">Required</mat-error>
    <mat-error *ngIf="control?.errors?.message">{{ control.getError('message') }}</mat-error>
  </mat-form-field>
</ng-container>
