import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { components, paths } from '../../interfaces/swagger-types';

export interface LookupResult {
  value: any | undefined;
  name: string | null | undefined;
}

export type LookupResults = LookupResult[];

export type LookupApiResponse = any[];

export type Lookup =
  | 'industries'
  | 'professions'
  | 'job-titles'
  | 'trainings'
  | 'states'
  | 'groups'
  | 'status'
  | 'roles'
  | 'job-referral-types'
  | 'job-statuses'
  | 'courses'
  | 'added-courses'
  | 'entity-user-status'
  | 'entity-group-status'
  | 'org-roles'
  | 'entity-user-invite-status'
  | 'batch-process-status'
  | 'languages'
  | 'privacy-request-types'
  | 'learner-emails';

@Injectable({
  providedIn: 'root',
})
export class LookupsService extends BaseAPI {
  private lookup(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }
  private lookupRole(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.id, name: item.name };
        });
      })
    );
  }
  private lookupGroup(lookup: Lookup): Observable<LookupResult[]> {
    const entityGuid = window.localStorage.getItem('entityGuid');
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${entityGuid}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }
  private lookupCourse(lookup: Lookup): Observable<LookupResult[]> {
    const entityGuid = window.localStorage.getItem('entityGuid');
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${entityGuid}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }
  private lookupAllCourse(lookup: Lookup): Observable<LookupResult[]> {
    const entityGuid = window.localStorage.getItem('entityGuid');
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${entityGuid}/${true}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }
  private lookupStates(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { key: item.key, name: item.text, value: item.abbr };
        });
      })
    );
  }

  private lookupProfessions(industryId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/professions/${industryId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private lookupJobTitles(professionId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/job-titles/${professionId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private lookupCourseIdsFilter(params: any): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/courseIds-filter`, {params}).pipe(
      map((response: LookupApiResponse) => {
        return response;
      })
    );
  }

  private entitysearchLookup(lookup: Lookup, searchTerm: any): Observable<LookupResult[]> {
    const entityGuid = window.localStorage.getItem('entityGuid');
    return this.http
      .get<LookupApiResponse>(`LookUp/${lookup}/${entityGuid}`, {
        params: { searchTerm: searchTerm },
      })
      .pipe(
        map((res) => {
          return res.map((item) => {
            return { value: item.key, name: item.text };
          });
        })
      );
  }

  public industries() {
    return this.lookup('industries');
  }
  public professions(industryId: number) {
    return this.lookupProfessions(industryId);
  }
  public jobTitles(professionId: number) {
    return this.lookupJobTitles(professionId);
  }
  public entityUserStatus() {
    return this.lookup('entity-user-status');
  }
  public entityGroupStatus() {
    return this.lookup('entity-group-status');
  }
  public trainings() {
    return this.lookup('trainings');
  }
  public states() {
    return this.lookupStates('states');
  }
  public groups() {
    return this.lookupGroup('groups');
  }
  public status() {
    return this.lookup('status');
  }
  public roles() {
    return this.lookupRole('roles');
  }
  public courses() {
    return this.lookupCourse('courses');
  }
  public addedCourses() {
    return this.lookupAllCourse('courses');
  }
  public orgRoles() {
    return this.lookup('org-roles');
  }
  public orgUserInvitesStatus() {
    return this.lookup('entity-user-invite-status');
  }
  public batchProcessStatus() {
    return this.lookup('batch-process-status');
  }
  public languages() {
    return this.lookup('languages');
  }
  public privacyRequestTypes() {
    return this.lookup('privacy-request-types');
  }
  public courseIdsFilter(params) {
    return this.lookupCourseIdsFilter(params);
  }
  public learnerEmails(searchTerm?: any) {
    return this.entitysearchLookup('learner-emails', searchTerm);
  }
}
