import { Component, Inject, OnInit } from '@angular/core';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { PricingComponent } from '../../pricing/pricing.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pricing-modal',
  templateUrl: './pricing-modal.component.html',
  styleUrls: ['./pricing-modal.component.scss'],
  standalone: true,
  imports: [
    ModalLayoutComponent,
    PricingComponent
  ]
})
export class PricingModalComponent implements OnInit {
  initialTab?: number;
  hasActiveSubscription?: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.initialTab = this.data.initialTab;
    this.hasActiveSubscription = this.data.hasActiveSubscription;
  }
}
