import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminRouteGuard  {
  constructor(private router: Router,     private permissionsService: NgxPermissionsService,) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.conditionalRoute();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.conditionalRoute();
  }

  conditionalRoute() {
    if (this.permissionsService.getPermission('admin')) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }
  
}
