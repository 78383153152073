import { Injectable } from '@angular/core';
import { filterNil, QueryEntity } from '@datorama/akita';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AllCoursesStore, AllCoursesState } from './all-courses.store';

@Injectable({ providedIn: 'root' })
export class AllCoursesQuery extends QueryEntity<AllCoursesState> {
  allCourses$ = this.selectAll().pipe(
    filterNil,
    distinctUntilChanged((entitiesA, entitiesB) => this.areEntitiesEqual(entitiesA, entitiesB)),
    map((entities) => entities)
  );

  constructor(protected store: AllCoursesStore) {
    super(store);
  }

  private areEntitiesEqual(entitiesA: any, entitiesB: any): boolean {
    // Implement your custom equality logic here
    // For example, you can compare specific properties of the entities

    // Replace the following line with your custom equality check
    return JSON.stringify(entitiesA) === JSON.stringify(entitiesB);
  }
}