import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EntityAdminSubscriptionUsageViewModel } from 'src/app/api/services/payment/payment.service';

export interface SubscriptionState {
   usage: EntityAdminSubscriptionUsageViewModel | null;
}

export function createInitialState(): SubscriptionState {
  return {
    usage: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'subscription' })
export class SubscriptionStore extends Store<SubscriptionState> {

  constructor() {
    super(createInitialState());
  }

  updateUsage(usage: EntityAdminSubscriptionUsageViewModel) {
    this.update({usage: usage});
  }

}
