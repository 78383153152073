import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class BaseURLInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const apiURL = environment.apiURL;
    let requestURL = request.url.startsWith('/') ? request.url : `/${request.url}`;

    const apiReq = request.clone({ url: `${apiURL}/api${requestURL}` });
    return next.handle(apiReq);
  }
}
