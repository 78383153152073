<div class="flex flex-col h-full">
  <div
    class="p-5 flex-1 sm:transform sm:transition mobile-choose-course flex items-center bg-color-lightBlue py-lg text-center cursor-pointer relative choose-course h-full sm:mx-0 w-full"
    (click)="selectDiv(item)" [ngClass]="
      item?.alreadySelected
        ? 'course-active-element disabled'
        : item?.courseSelected
        ? 'course-active-element'
        : item?.isAssigned
        ? 'course-active-element disabled'
        : ''
    ">
    <div [ngClass]="item?.selected || item?.courseSelected ? ' text-white' : 'text-primary'">
      <div>
        <div *ngIf="item?.isCE" class="flex text-left justify-start items-center">
          <p class="small flex items-center">
            <mat-icon class="round-active-text font-weight-bold leading-none"
              [ngClass]="item?.selected || item?.courseSelected? 'check-icon-text-outline text-secondary': 'check-icon-text-filled text-primary'">done</mat-icon>
            <span [ngClass]="item?.selected || item?.courseSelected ? ' text-white' : 'text-primary'">CE Approved</span>
          </p>
        </div>
      </div>
      <div class="flex">
        <p class="bold" [ngClass]="item?.selected || item?.courseSelected ? ' text-white' : ''">
          {{ item?.trainingHeader}}
          {{ item?.selectedState !== 'NATL' ? ' | ' + item?.selectedState : ''}}
        </p>
      </div>
      <h3 class="learner leading-none font-semibold capitalize text-left lg:hidden"
      [ngClass]="item?.selected || item?.courseSelected ? ' text-white' : ''">{{ item.name }}</h3>
      <h2 class="learner leading-none font-semibold capitalize text-left hidden lg:block"
        [ngClass]="item?.selected || item?.courseSelected ? ' text-white' : ''">{{ item.name }}</h2>
      <p class="text-left mb-0 mt-3" [ngClass]="item?.selected || item?.courseSelected ? ' text-white' : ''">
        {{item?.moduleNames.length}} {{item?.moduleNames.length !== 1 ? 'Modules:': 'Module:'}}
        {{getTransformedModuleNames().join(', ')}}</p>
      <mat-icon class="check-icon-text-filled round-active-icon-text font-weight-bold leading-none text-primary">done
      </mat-icon>
    </div>
    <div class="mt-auto flex justify-center">
      <div class="mt-4" *ngIf="bottomBtn">
        <button type="button" class="btn w-full px-4 btn-primary" (click)="buttonAction(item)">
          {{ bottomBtn === 'activeCourses' ? 'Archive' : bottomBtn === 'archivedCourses' ? 'Activated' : '' }}
        </button>
      </div>
    </div>
  </div>