import { Injectable } from '@angular/core';
import { resetStores } from '@datorama/akita';
import { AuthStore } from './auth.store';
import { AuthApiService } from '../../../api/services/auth/auth-api.service';
import { AuthQuery } from './auth.query';
import { catchError, distinctUntilChanged, map, pairwise, pluck, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { components } from '../../../api/interfaces/swagger-types';
import { PermissionsService } from '../components/services/permissions.service';
import { SwitchEntityProfileService } from 'src/app/shared/components/layouts/header/state/switch-entity-profile.service';
import { BannerCustomMessageService } from 'src/app/shared/services/banner-custom-message.service';
import { UserStateService } from 'src/app/api/services/user-state/user-state.service';
import { Router } from '@angular/router';

export interface UserModel extends Partial<components['schemas']['UserProfileViewModel']> {}
export interface Auth {
  jwt: string | null;
  user?: UserModel | undefined | null | boolean;
  userIsLoggedIn: boolean | null;
  profilePercentComplete: number;
}

const initialValue: Auth = {
  jwt: null,
  userIsLoggedIn: null,
  profilePercentComplete: 0,
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private store = new BehaviorSubject<Auth>(initialValue);
  public jwt$ = this.store.pipe(pluck('jwt'), distinctUntilChanged());
  public user$ = this.store.pipe(pluck('user'), distinctUntilChanged());
  public userLoggedIn$ = this.store.pipe(pluck('userIsLoggedIn'), distinctUntilChanged());
  welcomeTour$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authStore: AuthStore,
    private authApiService: AuthApiService,
    private permissions: PermissionsService,
    private switchEntityProfileService: SwitchEntityProfileService,
    private bannerCustomMessageService: BannerCustomMessageService,
    private userStateService: UserStateService,
    private router: Router,
  ) {
    window.addEventListener('storage', (event) => {
      if (event.key === 'token') {
        const jwt = event.newValue;
        this.store.next({ ...this.store.value, jwt });
      }

      if (event.key === null) {
        this.store.next({ ...initialValue });
      }
    });
    // inital load of user details
    this.loadUserDetails().pipe(take(1)).subscribe();
    // When the app loads grab a JWT from localStorage, if it exists.
    const jwt = window.localStorage.getItem('token');
    this.store.next({ ...this.store.value, jwt });
    // Whenever the JWT token changes get the user's details
    this.jwt$
      .pipe(
        pairwise(),
        tap(([prev, next]) => {
          // if jwt is going from null to a value, login is happening
          if (prev === null && next !== null) {
            this.loadUserDetails({ isLogin: true }).pipe(take(1)).subscribe();
          }

          // if jwt is going from value to different value, new jwt is being set
          if (prev !== null && next !== null && prev !== next) {
            this.loadUserDetails({ navigate: true }).pipe(take(1)).subscribe();
          }

          // if jwt is going from value to null, reload windows
          if (next === null) {
            const location = window.location.pathname;
            if (location !== '/login-as' && location !== '/accept-invitation-confirmed' && location !== '/email-confirmed' && location !== '/join-org') {
              window.location.href = '/';
            }
          }
        }),
      )
      .subscribe();
  }

  public logout() {
    this.flushAllStorage();
    this.bannerCustomMessageService.setMessage('');
  }
  //admin only
  public loginAs(email: string): Observable<boolean> {
    return this.authApiService.loginAs(email).pipe(
      catchError((e) => throwError(e)),
      map((response: any) => {
        this.authStore.setLoading(false);
        const success = Boolean(response);
        const token = response.jwt;

        if (success) {
          this.flushAllStorage();
          this.authStore.update((state) => ({ ...state}));
          this.storeJWT(token);
        }

        return success;
      }),
    );
  }

  public login(req: { userName: string; password: string }, joinRequestGuid?: string) {
    this.authStore.setLoading(true);
    return this.authApiService.login(req, joinRequestGuid).pipe(
      catchError((e) => throwError(e)),
      map((response: any) => {
        this.authStore.setLoading(false);
        const success = Boolean(response.jwt);
        const token = response.jwt as string;

        if (success) {
          this.authStore.update((state) => ({ ...state, jwt: token }));
          this.storeJWT(token as string);
        }

        return response;
      }),
    );
  }

  public login2fa(req: { userName: string; password: string; verificationCode: string }) {
    this.authStore.setLoading(true);
    return this.authApiService.login2fa(req).pipe(
      catchError((e) => throwError(e)),
      map((response: any) => {
        this.authStore.setLoading(false);
        const success = Boolean(response.jwt);
        const token = response.jwt as string;

        if (success) {
          this.authStore.update((state) => ({ ...state}));
          this.storeJWT(token as string);
        }

        return response;
      }),
    );
  }

  showQrCode(req: { userName: string; password: string }) {
    return this.authApiService.showQrCode(req);
  }

  public loadUserDetails(options?: { isLogin?: boolean; navigate?: boolean }): Observable<any> {
    if (!window.localStorage.getItem('token') && !this.store.value.jwt) {
      this.flushAllStorage();
      return of(false);
    }
    const location = window.location.pathname;
    if (location === '/accept-invitaion-confirmed') {
      this.flushAllStorage();
      return of(false);
    }

    this.authStore.setLoading(true);
    return this.authApiService.user().pipe(
      catchError((error) => {
        this.authStore.setLoading(false);
        return of(false);
      }),
      tap((user: any) => {
        this.authStore.setLoading(false);
        if (!user) {
          this.logout();
          return;
        }
        const { orgAndRoles, roles, stateSettings } = user;
        if (roles?.includes('Admin') || roles?.includes('LeadSupport')) {
          this.permissions.applyRoles(['Admin']);
          this.store.next({ ...this.store.value, user, userIsLoggedIn: true });
          this.authStore.update((state) => ({ ...state, user, userIsLoggedIn: true }));
          return;
        }

        var switchEntityLastActiveSetting =
          stateSettings && stateSettings?.filter((item: any) => item?.key === 'switchEntityLastActive');

        //check that the user still belongs to the entity in the stateSettiings
        if (switchEntityLastActiveSetting && switchEntityLastActiveSetting?.length > 0) {
          if (!orgAndRoles?.some((item: any) => item?.entityGuid === switchEntityLastActiveSetting[0]?.value)) {
            switchEntityLastActiveSetting = [];
            this.userStateService.removeKeyFromUserState('switchEntityLastActive').subscribe();
          }
        }

        this.switchEntityProfileService.setOrganizationsStore(orgAndRoles, switchEntityLastActiveSetting, {
          isLogin: options?.isLogin,
        });

        this.store.next({ ...this.store.value, user, userIsLoggedIn: true });
        this.authStore.update((state) => ({ ...state, user, userIsLoggedIn: true }));

        if (options?.navigate) {
          this.router.navigateByUrl('/profile');
        }
      }),
    );
  }

  flushAllStorage() {
    this.store.next({ ...initialValue });
    this.permissions.flushRoles();
    resetStores();
    window.localStorage.clear();
  }

  public storeJWT(token: string, userIsLoggedIn?: boolean) {
    window.localStorage.setItem('token', token);
    this.store.next({
      ...this.store.value,
      jwt: token,
      userIsLoggedIn: userIsLoggedIn as boolean,
    });

  }

  verify(body: any) {
    return this.authApiService.verifyEmail(body);
  }

  userState() {
    this.authApiService
      .user()
      .pipe(
        catchError((error) => {
          this.authStore.setLoading(false);
          return of(false);
        }),
      )
      .subscribe((user) => {
        this.authStore.setLoading(false);
        if (!user) {
          this.logout();
          return;
        }
        this.store.next({ ...this.store.value, user, userIsLoggedIn: true });
        this.authStore.update((state) => ({ ...state, user, userIsLoggedIn: true }));
      });
  }
}
