import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Organization } from './organization.model';
import { OrganizationStore } from './organization.store';
import { ApiService } from '../../../../../api/services/api.service';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  constructor(private organizationStore: OrganizationStore, private api: ApiService) {}

  getOrg(entityGuid: string) {
    return this.api.organization.getCurrent(entityGuid);
  }
  
  getOrgPublicPage(entityGuid: string) {
    return this.api.organization.getCurrentPublic(entityGuid);
  }

  isSlugAvailable(slug: string) {
    return this.api.organization.isSlugAvailable(slug);
  }

  uploadImage(formData: any) {
    return this.api.organization.uploadImage(formData);
  }

  updateOrg(org: Organization) {
    return this.api.organization.update(org);
  }

  getOrganizationCourses(entityGuid: any) {
    return this.api.organization.getOrgCourses(entityGuid).pipe(
      tap((entities: any) => {
        const cloneEntities = [...entities];
        const newEntity = cloneEntities?.map((entity) => {
          const {isCE, moduleNames, trainingHeader, course, isActive, ...remaining } = entity;
          const statesList = Object.keys(course.states).map((key) => ({ id: +key, value: course.states[key] }));
          const newCourse = {
            ...course,
            isCE,
            moduleNames,
            trainingHeader,
            editCourse: false,
            groupCourse: false,
            alreadyUserCourses: false,
            alreadyGroupCourse: false,
            alreadyOrgCourseActive: isActive === true ? true : false,
            state: statesList.length > 1 ? 'NATL' : statesList[0].value,
          };
          return {
            course: newCourse,
            isActive,
            ...remaining,
          };
        });

        const same = JSON.stringify(newEntity) === JSON.stringify(this.organizationStore.getValue().entities);

        if (!same) {
          this.organizationStore.set(newEntity);
        }
      })
    );
  }

  toggleActiveInactive(courseId: number) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    return this.api.courseApiService.toggleActiveAndInactive({ entityGuid, courseId: courseId });
  }

  resetAllUserCourse() {
    const StoreEntities = this.organizationStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      const { course, ...remaining } = value;
      const newCourse = { ...course, editCourse: false, alreadyUserCourses: false };
      const newEntity = { course: newCourse, ...remaining };
      this.organizationStore.update(key, newEntity);
    });
  }
  

  resetAllGroupCourse() {
    const StoreEntities = this.organizationStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      const { course, ...remaining } = value;
      const newCourse = { ...course, groupCourse: false, alreadyGroupCourse: false };
      const newEntity = { course: newCourse, ...remaining };
      this.organizationStore.update(key, newEntity);
    });
  }

  updateAlreadyUserCourse(id: number) {
    const StoreEntities = this.organizationStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      if (value?.courseId === id) {
        const { course, isActive, ...remaining } = value;
        if (isActive) {
          const newCourse = { ...course, editCourse: true, alreadyUserCourses: true };
          const newEntity = { course: newCourse, ...remaining };
          this.organizationStore.update(key, newEntity);
        }
      }
    });
  }
  updateAlreadyUserCourseRemovable(id: number) {
    const StoreEntities = this.organizationStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      if (value?.courseId === id) {
        const { course, isActive, ...remaining } = value;
        if (isActive) {
          const newCourse = { ...course, editCourse: true };
          const newEntity = { course: newCourse, ...remaining };
          this.organizationStore.update(key, newEntity);
        }
      }
    });
  }
  updateEditCourse(id: number) {
    const StoreEntities = this.organizationStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      if (value?.courseId === id) {
        const { course, ...remaining } = value;
        const newCourse = { ...course, editCourse: !course.editCourse };
        const newEntity = { course: newCourse, ...remaining };
        this.organizationStore.update(key, newEntity);
      }
    });
  }
  resetEditCourse() {
    const StoreEntities = this.organizationStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      const { course, ...remaining } = value;
      const newCourse = { ...course, editCourse: false, alreadyUserCourses: false };
      const newEntity = { course: newCourse, ...remaining };
      this.organizationStore.update(key, newEntity);
    });
  }

  updateGroupCourse(id: number) {
    const StoreEntities = this.organizationStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      if (value?.courseId === id) {
        const { course, ...remaining } = value;
        const newCourse = { ...course, groupCourse: !course.groupCourse };
        const newEntity = { course: newCourse, ...remaining };
        this.organizationStore.update(key, newEntity);
      }
    });
  }
  resetGroupCourse() {
    const StoreEntities = this.organizationStore.getValue().entities;
    Object.entries(<any>StoreEntities).forEach(([key, value]: any) => {
      const { course, ...remaining } = value;
      const newCourse = { ...course, groupCourse: false };
      const newEntity = { course: newCourse, ...remaining };
      this.organizationStore.update(key, newEntity);
    });
  }

  getOrgChipLabel(org: any){
    if(org?.entityType == "Organization") {
     if(org?.roles?.includes('EntityAdmin'))  {
        return 'Org Admin';
      } else {
        return 'Org Learner';
      }
    } else if(org?.entityType == "Coop") {
      return 'Coop Admin';
    } else {
      return 'Personal';
    }
  }

  assignCourseUser(body: any) {
    return this.api.organization.assignCourseUser(body);
  }

  batchAssignTraining(body: any) {
    return this.api.organization.batchAssignTraining(body);
  }
}
