import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Sort, MatSortModule } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import { Subject, of } from 'rxjs';
import { debounceTime, map, switchMap, take, tap, takeUntil } from 'rxjs/operators';
import { LookupsQuery } from 'src/app/lookups/lookups.query';
import { LookupsStore } from 'src/app/lookups/lookups.store';
import { Filter } from 'src/app/shared/directives/mat-filter/mat-filter.directive';
// import { LearningMetricsService } from './state/learning-metrics.service';
// import { LearningMetricsQuery } from './state/learning-metrics.query';
import { EntityCoursesService } from '../../../learner/courses/components/state/entity-courses.service';
import { SubscriptionQuery } from 'src/app/subscription/state/subscription.query';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { OffsiteCertsService } from 'src/app/api/services/offsite-certs/offsite-certs.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

@Component({
  selector: 'app-offsite-certs',
  templateUrl: './offsite-certs.component.html',
  standalone: true,
  imports: [
    NgIf,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatLegacySelectModule,
    MatLegacyOptionModule,
    NgFor,
    MatLegacyButtonModule,
    MatLegacyTableModule,
    MatSortModule,
    AsyncPipe,
    DatePipe,
    SnackbarComponent
  ],
})
export class OffsiteCertsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public isLoaded = false;
  public upgradeRequired = true;
  entityGuid!: string;
  disableflag: boolean = false;
  startingDate: any;
  endingDate: any;
  currentPageNumber: number = 1;
  itemsFrom: number = 1;
  itemsTo: number = 20;
  itemsPerPage: number = 20;
  totalNumberOfItems: any = 1;
  dataSource!: MatTableDataSource<any>;
  totalNumberOfPages: any = 1;
  sortOrder: any;
  initialNum: number = 0;
  filters: Filter[] = [];
  filtersForm = this.fb.group({
    search: this.fb.control(''),
    // courseId: this.fb.control(null),
    // status: this.fb.control(null),
    fromDate: this.fb.control(null),
    toDate: this.fb.control(null),
  });
  paginationForm = this.fb.group({
    pageSize: this.fb.control(20),
  });
  pageSizeOptions: Array<number> = [20, 40, 60, 100];
  displayedColumns: string[] = [
    'studentName',
    'studentId',
    'courseName',
    'training',
    'issueDate',
    'expiryDate',
    'actions',
  ];

  courses$ = this.lookupsQuery.courses$.pipe(
    map((courses) => {
      if (courses.length > 0) {
        return courses;
      } else {
        return this.lookupsStore.courses();
      }
    }),
    switchMap((course) => {
      if (course === undefined) {
        return of(null);
      } else {
        return of(course);
      }
    }),
    take(2),
  );

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public lookupsQuery: LookupsQuery,
    public lookupsStore: LookupsStore,
    // private learnerMetricsStateService: LearningMetricsService,
    // private learenerMetricStateQuery: LearningMetricsQuery,
    private offsiteCertService: OffsiteCertsService,
    private entityCoursesService: EntityCoursesService,
    private subscriptionQuery: SubscriptionQuery,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>();
    // this.subscriptionQuery.select().subscribe((subscription) => {
    //   if (subscription?.usage) {
    //     this.isLoaded = true;
    //     if (!subscription?.usage.requiresUpgrade) {
    //       this.upgradeRequired = false;
    //       this.initGridData();
    //     } else {
    //       this.filtersForm.disable();
    //       this.upgradeRequired = true;
    //     }
    //   }
    // });
    this.initGridData();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initGridData() {
    this.dataSource = new MatTableDataSource<any>();

    const now = new Date();
    const lastYear = new Date(new Date().setDate(now.getDate() - 365));
    const nowUTC = now.toISOString();
    const lastYearUTC = lastYear.toISOString();
    this.filtersForm.get('fromDate')?.patchValue(lastYearUTC);
    this.filtersForm.get('toDate')?.patchValue(nowUTC);

    this.fetchAll();

    this.filtersForm.valueChanges
      .pipe(
        debounceTime(250),
        tap(() => {
          this.currentPageNumber = 1;
          this.fetchAll();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.paginationForm.controls?.pageSize.valueChanges.subscribe((val) => {
      this.itemsPerPage = +this.paginationForm.get('pageSize')?.value;
      this.totalNumberOfPages =
        this.totalNumberOfItems === 0 ? 1 : Math.ceil(this.totalNumberOfItems / this.itemsPerPage);
      if (this.currentPageNumber > this.totalNumberOfPages) {
        this.currentPageNumber = this.totalNumberOfPages;
      }
      this.fetchAll();
    });

    // this.learenerMetricStateQuery
    //   .selectAll()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((entities: any) => {
    //     this.dataSource.data = [];
    //     if (entities.length > 0) {
    //       const { rows, totalNumberOfItems } = entities[0];
    //       this.totalNumberOfItems = totalNumberOfItems;
    //       this.totalNumberOfPages =
    //         this.totalNumberOfItems === 0 ? 1 : Math.ceil(this.totalNumberOfItems / this.itemsPerPage);
    //       this.itemsFrom = (this.currentPageNumber - 1) * this.itemsPerPage + 1;
    //       if (this.totalNumberOfItems == 0) {
    //         this.itemsFrom = this.totalNumberOfItems;
    //       }
    //       this.itemsTo = this.itemsFrom + this.itemsPerPage - 1;
    //       if (this.itemsTo > this.totalNumberOfItems) {
    //         this.itemsTo = this.totalNumberOfItems;
    //       }
    //       if (rows?.length > 0) {
    //         rows.forEach((learner: any) => {
    //           const datasource = this.dataSource.data;
    //           this.dataSource.data.push(learner);
    //           this.dataSource.data = datasource;
    //         });
    //       }
    //     }
    //   });
  }

  fetchAll() {
    const { search, fromDate, toDate, courseId, status } = this.filtersForm.getRawValue();
    const params = {
      PageSize: this.paginationForm.get('pageSize')?.value ?? 20,
      PageNumber: this.currentPageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDate ? this.calculateDate(fromDate) : '',
      ToDate: toDate ? this.calculateDate(toDate) : '',
      CourseIds: courseId ? [courseId] : [],
      Status: status ? status : '',
      SortField: this.sortOrder
        ? this.sortOrder?.active && this.sortOrder?.direction
          ? this.sortOrder?.active
          : 'dateAdded'
        : 'dateAdded',
      SortDirection: this.sortOrder ? this.sortOrder?.direction ?? 'DESCENDING' : 'DESCENDING',
    };

    this.offsiteCertService
      .getOffsiteCerts(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.dataSource.data = [];
        if (data.rows.length > 0) {
          const { rows, totalNumberOfItems } = data;
          this.totalNumberOfItems = totalNumberOfItems;
          this.totalNumberOfPages =
            this.totalNumberOfItems === 0 ? 1 : Math.ceil(this.totalNumberOfItems / this.itemsPerPage);
          this.itemsFrom = (this.currentPageNumber - 1) * this.itemsPerPage + 1;
          if (this.totalNumberOfItems == 0) {
            this.itemsFrom = this.totalNumberOfItems;
          }
          this.itemsTo = this.itemsFrom + this.itemsPerPage - 1;
          if (this.itemsTo > this.totalNumberOfItems) {
            this.itemsTo = this.totalNumberOfItems;
          }
          if (rows?.length > 0) {
            rows.forEach((learner: any) => {
              const datasource = this.dataSource.data;
              this.dataSource.data.push(learner);
              this.dataSource.data = datasource;
              this.isLoaded = true;
            });
          }
        }
      });

    //this.learnerMetricsStateService.getLearnerMetricsReport(params).pipe(takeUntil(this.destroy$)).subscribe();
  }

  clearFilters() {
    this.filtersForm.reset();
  }

  paginationRight() {
    if (this.currentPageNumber < this.totalNumberOfPages) {
      this.currentPageNumber++;
      this.fetchAll();
    }
  }

  paginationLeft() {
    if (this.currentPageNumber > 1) {
      this.currentPageNumber--;
      this.fetchAll();
    }
  }

  sortData(sort: Sort) {
    this.sortOrder = this.sortField(sort);
    this.fetchAll();
  }

  sortField(sort: Sort) {
    let activeSort: any = '';
    let direction: any = '';
    switch (true) {
      case sort.active === 'studentName':
        activeSort = 'studentName';
        break;
      case sort.active === 'studentId':
        activeSort = 'studentId';
        break;
      case sort.active === 'courseName':
        activeSort = 'courseName';
        break;
      case sort.active === 'training':
        activeSort = 'training';
        break;
      case sort.active === 'issueDate':
        activeSort = 'dateCompleted';
        break;
      case sort.active === 'expiryDate':
        activeSort = 'expireDate';
        break;
    }
    switch (true) {
      case sort.direction === 'desc':
        direction = 'DESCENDING';
        break;
      case sort.direction === 'asc':
        direction = 'ASCENDING';
        break;
      case sort.direction === '':
        direction = null;
        break;
    }
    return { active: activeSort, direction: direction };
  }

  handleButtonAction(element: any) {
    this.entityCoursesService.downloadCert(element?.certificateId);
  }

  calculateDate(val: any) {
    if (val) {
      return new Date(val).toISOString();
    }
  }

  calculateToDate(val: any) {
    if (val) {
      const date = new Date(val).toISOString();
      const newDate: any = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return newDate.toISOString();
    }
  }

  exportCsv() {
    this.disableflag = true;
    const { search, fromDate, toDate, courseId, status } = this.filtersForm.getRawValue();
    const params = {
      PageSize: this.paginationForm.get('pageSize')?.value ?? 20,
      PageNumber: this.currentPageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDate ? this.calculateDate(fromDate) : '',
      ToDate: toDate ? this.calculateDate(toDate) : '',
      CourseIds: courseId ? [courseId] : [],
      Status: status ? status : '',
      SortField: this.sortOrder
        ? this.sortOrder?.active && this.sortOrder?.direction
          ? this.sortOrder?.active
          : 'dateAdded'
        : 'dateAdded',
      SortDirection: this.sortOrder ? this.sortOrder?.direction ?? 'DESCENDING' : 'DESCENDING',
    };
  }

  downloadCert(certId: number) {
    this.offsiteCertService.downloadOffsiteCert(certId).subscribe((response: any) => {
      const fileName = response.headers.get('content-disposition')?.split('filename=')[1].split(';')[0].replaceAll("\"", "");
      const blob = new Blob([response.body], { type: 'application/pdf' });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
}
