import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BannerCustomMessageService {
  private message$ = new BehaviorSubject<any>({});
  customMessage$ = this.message$.asObservable();
  constructor() {}

  setMessage(message: string) {
    this.message$.next(message);
  }

}
