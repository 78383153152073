import { Component, OnInit, EventEmitter, Output, OnDestroy, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportUserService } from 'src/app/api/services/import-user/import-user.service';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SettingsService } from 'src/app/api/services/settings/settings.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgIf, NgFor } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { ActionButtonDirective } from 'src/app/shared/directives/action-button/action-button.directive';

export type Mode = 'add' | 'edit' | 'delete';
export interface DialogData {
  mode: Mode;
  item?: any;
  canEdit?: boolean;
  deleteId?: any;
  itemName?: string;
  activeSubscription?: boolean;
  modalType?: string;
}
@Component({
    selector: 'app-import-users',
    templateUrl: './import-users.component.html',
    styleUrls: ['./import-users.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        NgFor,
        MatIconModule,
        MatLegacyTableModule,
        SnackbarComponent,
        ActionButtonDirective
    ],
})
export class ImportUsersComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  importUserSub!: Subscription;
  disableButton = false
  selectedFile: any;
  entityGuid: any;
  public currentStep = 1;
  dataSource!: any;
  @Output() dataEvent = new EventEmitter<{ currentAddLearnerStep: number; title: string }>();
  displayedColumns: string[] = ['email', 'group1', 'group2', 'group3'];
  error!: string;
  rowErrors: { rowNum: number; message: string }[] = [];
  importLimit: any = 500;
  sendNow = true;
  defaultDate = new Date(new Date().setDate(new Date().getDate() + 1));
  importForm = this.fb.group({
    fileUpload: this.fb.control(null, Validators.required),
    // these fields are not currently used, but may be used in the future
    // batchName: this.fb.control(null, Validators.required),
    // sendDate: this.fb.control({ value: null, disabled: true}),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private importUserService: ImportUserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private settingsService: SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
    this.entityGuid = localStorage.getItem('entityGuid');
    this.dataSource = null;

    this.settingsService
      .getSetting('BatchInviteLimit')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value: any) => {
          this.importLimit = value;
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.importUserSub) {
      this.importUserSub.unsubscribe();
    }
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.importForm.get('fileUpload')?.setValue(file.name);
      this.selectedFile = file;
      this.error = '';
      this.rowErrors = [];
    }
  }

  onFileTouched() {
    this.importForm.get('fileUpload')?.markAsTouched();
  }

  backStep() {
    this.currentStep -= 1;
    this.emitData();
  }

  importPreview() {
    this.error = '';
    this.rowErrors = [];
    if (!this.importForm.valid) {
      this.importForm.markAllAsTouched();
      return;
    }
    this.disableButton = true
    const batchData = new FormData();
    // these fields are not currently used, but may be used in the future
    // batchData.append('BatchName', this.importForm.get('batchName')?.value);
    //@ts-ignore
    // batchData.append('ScheduledDate', this.importForm.get('sendDate')?.value? new Date(this.importForm.get('sendDate')?.value).toISOString() : '');
    batchData.append('File', this.selectedFile);

    if (this.importUserSub) {
      this.importUserSub.unsubscribe();
    }
    this.importUserSub = this.importUserService
      .batchPreview(this.entityGuid, batchData)
      .subscribe({
        next: (data: any) => {
          this.disableButton = false
          this.dataSource = data;
          if (this.dataSource === null) {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Choose a valid file to continue' }})
          } else {
            this.emitData();
            this.toggleSendNow();
          }
          this.rowErrors = data.previewEntries
            .map((row: any, index: number) => {
              return {
                rowNum: index + 2, // add 2 to account for header row and 0-based index
                message: row.message.replaceAll('[', '').replaceAll(']', '').replaceAll('Pending', ''),
              };
            })
            .filter((row: any) => row.message.includes('Invalid'));

          if (this.rowErrors.length > 0) {
            this.error = 'Invalid rows found. Please fix the below errors and try again.';
          } else {
            this.error = '';
            this.currentStep = 2;
          }
        },
        error: (error: any) => {
          this.disableButton = false
          if (error.status === 400) {
            this.error = error.error;
          } else {
            this.error = 'An error occurred. Please try again.';
          }
        },
      });
  }

  import() {
    this.currentStep = 3;
    this.emitData();
  }

  continueImport() {
    this.currentStep = 4;
    this.emitData();
    this.importUserService
      .batchInvite(this.dataSource.batchId, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {});
  }

  cancelUpload() {
    this.currentStep = 1;
    this.importUserService
      .batchInvite(this.dataSource.batchId, false)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {});
    this.dataSource = null;
    this.importForm.get('fileUpload')?.setValue(null);
    this.importForm.get('batchName')?.setValue(null);
    this.emitData();
  }
  backToInvitesPage() {
    this.currentStep = 5;
    this.emitData();
  }

  toggleSendNow() {
    this.sendNow = true;
    this.importForm.get('sendDate')?.disable();
    this.importForm.get('sendDate')?.setValue(null);
  }

  toggleSendLater() {
    this.sendNow = false;
    this.importForm.get('sendDate')?.enable();
    this.importForm.get('sendDate')?.setValue(this.defaultDate);
  }

  emitData() {
    var data = {
      currentAddLearnerStep: 2,
      title: '',
    };
    if (this.currentStep < 1) {
      data.currentAddLearnerStep = 1;
      data.title = 'Add Learners';
    } else if (this.currentStep === 1) {
      data.currentAddLearnerStep = 2;
      data.title = 'Upload Learners';
    } else if (this.currentStep === 2) {
      data.currentAddLearnerStep = 2;
      data.title = 'Preview Upload';
    } else if (this.currentStep === 3) {
      data.currentAddLearnerStep = 2;
      data.title = 'Are You Sure?';
    } else if (this.currentStep === 4) {
      data.currentAddLearnerStep = 2;
      data.title = 'Upload Processing';
    } else {
      data.currentAddLearnerStep = 3;
    }
    this.dataEvent.emit(data);
  }

  navTo(route: string, isExternal: boolean) {
    if (isExternal) {
      window.open(route, '_blank');
    } else {
      this.router.navigate([route]);
    }
    this.closeModel();
  }

  closeModel() {
    this.currentStep = 5;
    this.emitData();
  }
}
