import { Injectable } from '@angular/core';
import { filterNil, QueryEntity } from '@datorama/akita';
import { CoursesStore, CoursesState } from './courses.store';
import { distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';
import { CourseHistory, CourseModule } from './course.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mockCourses, mockProducts } from './mock/mocks';

@Injectable({ providedIn: 'root' })
export class CoursesQuery extends QueryEntity<CoursesState> {
  coursesLength = new BehaviorSubject<boolean>(false);

  myCourses$ = this.selectAll().pipe(
    map((entities) =>
      entities.filter((entity) => {
        return entity.selected;
      })
    )
  );

  allCourses$ = this.selectAll().pipe(map((entities) => entities));

  mockCourses$ = of(mockCourses);
  mockProducts$ = of(mockProducts);

  currentCourseModules$ = this.selectActive().pipe(
    filterNil,
    distinctUntilChanged((entitiesA, entitiesB) => this.areEntitiesEqual(entitiesA, entitiesB)),

    map((entity) => {
      return (entity?.modules || []) as CourseModule[];
    })
  );


  currentCourse$ = this.selectActive().pipe(filterNil);

  constructor(protected store: CoursesStore) {
    super(store);
  }

  private areEntitiesEqual(entitiesA: any, entitiesB: any): boolean {
    // Implement your custom equality logic here
    // For example, you can compare specific properties of the entities

    // Replace the following line with your custom equality check
    return JSON.stringify(entitiesA) === JSON.stringify(entitiesB);
  }
}
