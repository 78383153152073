import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map, tap } from 'rxjs/operators';
import { OrganizationStore, OrganizationState } from './organization.store';

@Injectable({ providedIn: 'root' })
export class OrganizationQuery extends QueryEntity<OrganizationState> {
  constructor(protected store: OrganizationStore) {
    super(store);
  }

  allCourses$ = this.selectAll().pipe(
    map((data) => {
      const activeCourses = data
        .map((item: any) => {
          return item.course;
        })
        .filter(Boolean);
      return activeCourses;
    })
  );

  activeCourses$ = this.selectAll().pipe(
    map((data) => {
      const activeCourses = data
        .map((item: any) => {
          if (item.isActive === true) {
            return item.course;
          }
        })
        .filter(Boolean);
      return activeCourses;
    })
  );

  archivedCourses$ = this.selectAll().pipe(
    map((data) => {
      const activeCourses = data
        .map((item: any) => {
          if (item.isActive !== true) {
            return item.course;
          }
        })
        .filter(Boolean);
      return activeCourses;
    })
  );
}
