import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-widget-tile',
    templateUrl: './widget-tile.component.html',
    styleUrls: ['./widget-tile.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        MatIconModule,
    ],
})
export class WidgetTileComponent {
  private _item: any;

  @Input() set item(item: any) {
    this._item = item;
  }

  get item() {
    return this._item;
  }

  constructor() {}
}
