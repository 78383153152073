<div class="content-container w-full h-full" *ngIf="upgradeRequired">
  <img src="assets/images/learner-metrics.png" class="w-full h-full" />
</div>
<div class="content-container no-data-overlay-parent" *ngIf="!upgradeRequired">
  <div *ngIf="showZeroOverlay" class="no-data-overlay-child">
    <h2>0 Learners</h2>
    <p>You currently have no learners.<br />Start adding them by clicking the button below.</p>
    <button class="btn-light medium cursor-pointer items-center text-center" (click)="openImport({ mode: 'add', componentType: 'users', modalType: 'import' })"
      [disabled]="disableflag">Import Learners</button
    >

    <p *ngIf="!hasActiveSubscription">This feature is available with a paid subscription. <a (click)="openPrices()"
      class="white-link ml-0">Subscribe Now.</a></p>
  </div>
  <app-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></app-grid-template>
</div>