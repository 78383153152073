import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class UserStateService extends BaseAPI {
  userState(body:any){
    return this.http.post(`user/user-state`, body)
  }

  uploadProfileImage(body:any) {
    return this.http.post(`user/upload-image`, body)
  }

  getProfileImage() {
    return this.http.get(`user/profile-image`);
  }

  removeKeyFromUserState(key: string) {
    return this.http.delete(`user/user-state/${key}`)
  }

}
