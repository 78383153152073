<div class="import-users-main" *ngIf="currentStep === 1">
  <div class="numberedSections">
    <h4>1. Add Trainings and Groups</h4>
    <p class="import-users-text">
      Before inviting learners, be sure you've added trainings and created groups with trainings assigned
       to each group. We also recommend alerting your learners that they will be receiving an invitation
       email from Mandated Reporter Training. See our 
       <a
       class="primary-link"
       (click)="navTo('https://mandatedreportertraining.zendesk.com/hc/en-us/articles/17116156865179-Organization-Onboarding-Message', true)"
       >sample onboarding message.</a>
    </p>
    <p class="import-users-text pt-2">
      We recommend reviewing the
      <a
        class="primary-link"
        (click)="navTo('https://mandatedreportertraining.zendesk.com/hc/en-us/articles/17115309836059-How-to-Register-and-Setup-an-Organization', true)"
        >Organization Setup Guide</a
      >
      before beginning the user import.
    </p>
  </div>
  <div class="numberedSections">
    <h4>2. Prepare the file</h4>
    <p class="import-users-text">
      Download our <a class="primary-link" href="/assets/MRT-user-import-TEMPLATE.xlsx" download>Import Template File</a> and
      configure your user data. There is a maximum import of {{ importLimit }} learners.
    </p>
  </div>
  <div class="numberedSections">
    <h4>3. Upload</h4>
    <p class="import-users-text">
      Upload your Excel file below.
    </p>
  <!-- upload cvs file -->
    <div [formGroup]="importForm">
      <div class="mt-2.5 numberedSections">
        <div class="flex flex-wrap justify-between">  
          <div class="flex w-full sm:w-1/2 justify-between items-center">
            <mat-form-field class="w-full">
              <mat-label>File Upload</mat-label>
              <input id="fileName" type="text" matInput readonly="true" formControlName="fileUpload"/>
            </mat-form-field>
            <label class="btn-primary-outlined small flex items-center justify-center ml-4 h-10">
              <input type="file" (click)="onFileTouched()" (change)="onFileChange($event)" id="userFile" accept=".xlsx"/>
              <p class="m-0">{{ 'Browse' }}</p>
            </label>
          </div>
        </div>
        <div class="w-full sm:w-1/2">
          <mat-form-field class="w-full">
            <mat-label>Batch Name</mat-label>
            <input type="text" matInput formControlName="batchName"/>
          </mat-form-field>
        </div>
      </div>

    <div class="numberedSections">
      <h4>4. Send Date</h4>
      <div class="flex w-full sm:w-1/2 justify-between">
        <div class="mt-5">
          <mat-radio-group aria-label="Send Date" class="flex justify-between">
            <mat-radio-button class="radio-button pr-3" (change)="toggleSendNow()" value="true" checked>Send Now</mat-radio-button>
            <mat-radio-button class="radio-button pr-6" (change)="toggleSendLater()" value="false">Send Later</mat-radio-button>
          </mat-radio-group>
        </div>

        <div>
          <mat-form-field class="w-full">
            <mat-label>Send Date</mat-label>
            <input matInput [matDatepicker]="picker1" (focus)="picker1.open()" formControlName="sendDate" [min]="defaultDate" [required]="!sendNow"/>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>{{timeZone}}</mat-label>
            <mat-select formControlName="sendTime" panelClass="mat-select-mt3">
              <mat-option *ngFor="let time of sendTimes" [value]="time.value">
                {{ time.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
      <div *ngIf="error" class="sm:w-1/2">
        <p class="text-red-500 import-users-text">{{ error }}</p>
        <div *ngIf="rowErrors.length > 0" class="overflow-y-auto max-h-24 mt-1 border-solid border-1 p-1">
          <p *ngFor="let row of rowErrors" class="text-red-500 import-users-text">Row: {{ row.rowNum }}, {{ row.message }}</p>
        </div>
      </div>
    </div>
  </div>  
  <div class="import-users-intro-footer">
    <button class="btn-primary medium" (click)="importPreview()" [actionButton]="{hasSpinner: true}" [disabled]="disableButton">Preview Upload</button>
    <button *ngIf="data.modalType !== 'import'" class="btn btn-link-accordion flex justify-center items-center mt-3" type="button" (click)="backStep()">
      <mat-icon class="rotated-icon-135">arrow_outward</mat-icon>
      <span>Back</span>
    </button>
  </div>
</div>
<div class="import-users-main" *ngIf="currentStep === 2">
  <p class="w-full text-center">
    Previewed below are the first 10 rows in your import file. Please verify that data on your upload file is matched to
    the correct columns.
  </p>
  <div class="mt-2">
    <table mat-table [dataSource]="dataSource.previewEntries" class="w-full preview-table">
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email Address</th>
        <td mat-cell *matCellDef="let row">
          {{ row.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="group1">
        <th mat-header-cell *matHeaderCellDef>Group 1</th>
        <td mat-cell *matCellDef="let row">
          <a *ngIf="row.group1 !== ''; else other1">{{ row?.group1 }}</a>
          <ng-template #other1> - </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="group2">
        <th mat-header-cell *matHeaderCellDef>Group 2</th>
        <td mat-cell *matCellDef="let row">
          <a *ngIf="row.group2 !== ''; else other2">{{ row?.group2 }}</a>
          <ng-template #other2> - </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="group3">
        <th mat-header-cell *matHeaderCellDef>Group 3</th>
        <td mat-cell *matCellDef="let row">
          <a *ngIf="row.group3 !== ''; else other3">{{ row?.group3 }}</a>
          <ng-template #other3> - </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data</td>
      </tr>
    </table>
  </div>
  <div class="import-users-intro-footer">
    <button class="btn-primary medium" (click)="import()">Upload</button>
    <button class="btn btn-link-accordion flex justify-center items-center mt-3" type="button" (click)="backStep()">
      <mat-icon class="rotated-icon-135">arrow_outward</mat-icon>
      <span>Back to Upload Learners</span>
    </button>
  </div>
</div>
<div class="import-users-main justify-center text-center items-center" *ngIf="currentStep === 3">
  <p class="import-users-text">
    <strong>Please note, invitation emails will be sent immediately. This action can't be undone</strong>
  </p>
  <p class="import-users-text pt-3">
    Click the button below to continue with user import.
  </p>
  <div class="import-users-intro-footer">
    <button class="btn-primary medium" (click)="continueImport()">Continue</button>
    <button class="btn btn-link-accordion flex justify-center items-center mt-3" type="button" (click)="cancelUpload()">
      <mat-icon class="rotated-icon-135">arrow_outward</mat-icon>
      <span>Cancel Upload</span>
    </button>
  </div>
</div>
<div class="import-users-main justify-center text-center items-center" *ngIf="currentStep === 4">
  <p class="pt-8 pb-8 import-users-text">
    Your user upload will process shortly. Invited users will appear in the learners grid as their invitations are
    processed. An email with upload results will be sent to you on completion.
  </p>
  <button class="btn-primary medium" (click)="backToInvitesPage()">See Uploaded Learners</button>
</div>
