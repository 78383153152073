import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';
import { paths } from '../../interfaces/swagger-types';

export type EntityAdminDashboardResponse =
  paths['/api/EntityAdminReports/dashboard']['get']['responses']['200']['content']['application/json'];
export type LearnerInviteAcceptRatioResponse =
  paths['/api/EntityAdminReports/invite-accept-ratio']['get']['responses']['200']['content']['application/json'];
export type LearnerProgressCertificatesResponse =
  paths['/api/EntityAdminReports/learner-progress-certificates']['get']['responses']['200']['content']['application/json'];
@Injectable({
  providedIn: 'root',
})
export class DashboardApiService extends BaseAPI {

  getDashboardData(criteria: any) {
    return this.http.get<EntityAdminDashboardResponse>('EntityAdminReports/dashboard',{params: criteria}).pipe();
  }

  getLearnerInviteAcceptRatio(criteria: any) {
    return this.http.get<LearnerInviteAcceptRatioResponse>('EntityAdminReports/invite-accept-ratio', {params: criteria}).pipe();
  }

  getLearnerProgressCertificates(criteria: any) {
    return this.http.get<LearnerProgressCertificatesResponse>('EntityAdminReports/learner-progress-certificates', {params: criteria}).pipe();
  }
}
