import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ImportUsersComponent } from '../../../../routes/org/team/import-learners/import-learners-editor/import-users.component';
import { AddUsersComponent } from '../../../../routes/org/team/users/add-users/add-users.component';
import { WidgetTileComponent } from '../../widget-tile/widget-tile.component';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { NgIf, NgClass } from '@angular/common';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatIconModule } from '@angular/material/icon';
import { Subject, throwError } from 'rxjs';
import { switchMap, tap, takeUntil, catchError } from 'rxjs/operators';
import { OrganizationService } from 'src/app/routes/org/organization/org-profile/state/organization.service';
import { SwitchEntityProfileQuery } from '../../layouts/header/state/switch-entity-profile.query';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

export type Mode = 'add' | 'edit' | 'delete';

export interface DialogData {
  mode: Mode;
  item?: any;
  canEdit?: boolean;
  deleteId?: any;
  itemName?: string;
  activeSubscription?: boolean;
  modalType?: string;
}
@UntilDestroy()
@Component({
    selector: 'app-add-learners',
    templateUrl: './add-learners.component.html',
    styleUrls: ['./add-learners.component.scss'],
    standalone: true,
    imports: [
      ModalLayoutComponent,
      NgIf,
      MatLegacyRadioModule,
      MatLegacyFormFieldModule,
      MatLegacyInputModule,
      MatLegacySlideToggleModule,
      WidgetTileComponent,
      NgClass,
      AddUsersComponent,
      ImportUsersComponent,
      MatIconModule,
      FormsModule,
      ReactiveFormsModule,
      SnackbarComponent
    ]
})
export class AddLearnersComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  orgData: any;
  mode: Mode;
  selectedAddLearners: number | undefined;
  currentAddLearnerStep: number = 1;
  title: string = 'Add Learners';
  svgManualIcon = {
    activeIcon: 'manual-icon-active',
    icon: 'manual-icon',
    tileSelected: false,
    name: 'Manually',
  };
  svgImportIcon = {
    activeIcon: 'file-upload-icon-active',
    icon: 'file-upload-icon',
    tileSelected: false,
    name: 'File Upload',
  };
  svgPublicIcon = {
    activeIcon: 'webpage-icon-active',
    icon: 'webpage-icon',
    tileSelected: false,
    name: 'Public Page',
  };

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddLearnersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private modalService: ModalService,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    public switchEntityProfileQuery: SwitchEntityProfileQuery,
    private organizationService: OrganizationService,
  ) {
    this.mode = data.mode || 'add';
    if (!data.activeSubscription) {
      this.svgImportIcon.icon = 'file-upload-icon-disabled';
      this.svgPublicIcon.icon = 'webpage-icon-disabled';
    }
  }

  form = this.fb.group({
    publicUserAssociationToggle: this.fb.control(false),
    publicUserAssociationURL: this.fb.control(''),
  });

  ngOnInit(): void {
    this.loadOrg().pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  closeModal(response?: any) {
    this.dialogRef.close(response);
  }

  toggleManual() {
    this.selectedAddLearners = 1;
    this.svgManualIcon.tileSelected = !this.svgManualIcon.tileSelected;
    this.svgImportIcon.tileSelected = !this.svgManualIcon.tileSelected;
    this.svgPublicIcon.tileSelected = !this.svgManualIcon.tileSelected;
  }

  toggleImport() {
    if (this.data.activeSubscription) {
      this.selectedAddLearners = 2;
      this.svgImportIcon.tileSelected = !this.svgImportIcon.tileSelected;
      this.svgManualIcon.tileSelected = !this.svgImportIcon.tileSelected;
      this.svgPublicIcon.tileSelected = !this.svgImportIcon.tileSelected;
    }
  }

  togglePublic() {
    if (this.data.activeSubscription) {
      this.selectedAddLearners = 3;
      this.svgPublicIcon.tileSelected = !this.svgPublicIcon.tileSelected;
      this.svgManualIcon.tileSelected = !this.svgPublicIcon.tileSelected;
      this.svgImportIcon.tileSelected = !this.svgPublicIcon.tileSelected;
    }
  }

  changeCurrentStep() {
    if (this.selectedAddLearners?.valueOf() != undefined) {
      this.currentAddLearnerStep++;
      if (this.selectedAddLearners == 2) {
        this.title = 'Upload Learners';
      } else if (this.selectedAddLearners == 3) {
        this.title = 'Share Organization Landing Page';
      }
    }
  }

  receiveDataFromChild(data: {currentAddLearnerStep: number, title: string}) {
    if (data.currentAddLearnerStep === 1) {
      this.currentAddLearnerStep = data.currentAddLearnerStep;
    } else if (data.currentAddLearnerStep === 3) {
      this.closeModal(true);
    }
    this.title = data.title;
  }

  openPrices() {
    this.modalService.openPricingModal(this.data?.activeSubscription);
  }

  backStep() {
    this.currentAddLearnerStep--;
    this.title = 'Add Learners';
  }

  clipBoard() {
    this.clipboard.copy(`${this.orgData.landingPageShortUrl}`);
    this.closeModal();
    this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Copied Link" }})
  }

  loadOrg() {
    return this.switchEntityProfileQuery.activeOrg$.pipe(
      untilDestroyed(this),
      switchMap((user) => {
        return this.organizationService.getOrg(user.entityGuid);
      }),
      tap((data: any) => {
        if (data) {
          this.orgData = data;
          this.form.get('publicUserAssociationToggle')?.setValue(data?.publicLandingPageEnabled);
          this.form.get('publicUserAssociationURL')?.setValue(data?.landingPageShortUrl);
          if (data?.publicLandingPageEnabled) {
            this.form.get('publicUserAssociationURL')?.enable();
          } else {
            this.form.get('publicUserAssociationURL')?.disable();
          }
        }
      }),
    );
  }

  setPublicPageStatus() {
    if (!this.orgData) {
      return;
    }
    this.orgData.industryId = this.orgData.industryId == 0 ? null : this.orgData.industryId;
    this.orgData.publicLandingPageEnabled = this.form.get('publicUserAssociationToggle')?.value;
    this.form.get('publicUserAssociationToggle')?.disable();
    this.organizationService
    .updateOrg(this.orgData)
    .pipe(
      catchError((error) => throwError(error)),
      takeUntil(this.destroy$),
    )
    .subscribe(
      () => {
        this.form.get('publicUserAssociationToggle')?.enable();
        this.loadOrg().pipe(takeUntil(this.destroy$)).subscribe();
      },
      (error) => {
        this.form.get('publicUserAssociationToggle')?.enable();
      },
    );
  }
}
