import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ScrollingModule } from '@angular/cdk/scrolling';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatTreeModule } from '@angular/material/tree';
// import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
// import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
// import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
// import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
// import { MatExpansionModule } from '@angular/material/expansion';
// import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
// import { OverlayModule } from '@angular/cdk/overlay';
// import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
// import { MatGridListModule } from '@angular/material/grid-list';
// import { MatSortModule } from '@angular/material/sort';
// import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
// import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
// import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
// import { CdkStepperModule } from '@angular/cdk/stepper';
// import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
// import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
// import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
// import { A11yModule } from '@angular/cdk/a11y';
// import { PortalModule } from '@angular/cdk/portal';
// import { CdkTableModule } from '@angular/cdk/table';
// import { CdkTreeModule } from '@angular/cdk/tree';
// import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
// import { MatIconModule } from '@angular/material/icon';
// import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
// import { MatStepperModule } from '@angular/material/stepper';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
// import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
// import { ClipboardModule } from '@angular/cdk/clipboard';
// import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
// import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
// import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
// import { DragDropModule } from '@angular/cdk/drag-drop';
// import { MatBadgeModule } from '@angular/material/badge';
// import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
// import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    // A11yModule,
    // ClipboardModule,
    // CdkStepperModule,
    // CdkTableModule,
    // CdkTreeModule,
    // DragDropModule,
    // MatAutocompleteModule,
    // MatBadgeModule,
    // MatBottomSheetModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatStepperModule,
    // MatDatepickerModule,
    MatDialogModule,
    // MatDividerModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    // MatInputModule,
    // MatListModule,
    // MatMenuModule,
    // MatNativeDateModule,
    // MatPaginatorModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // MatRippleModule,
    // MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    MatSnackBarModule,
    // MatSortModule,
    // MatTableModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTreeModule,
    // OverlayModule,
    // PortalModule,
    // ScrollingModule,
  ],
  providers:[
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ]
})
export class MaterialModule {}
