<div class="flex flex-col h-full">
  <div
    class="flex-1 sm:transform sm:transition mobile-choose-course flex items-center justify-center bg-color-lightBlue px-sm py-lg text-center cursor-pointer relative choose-course h-full mx-auto sm:mx-0 w-full"
    [ngClass]="
      item?.alreadySelected
        ? 'course-active-element disabled'
        : item?.tileSelected
        ? 'course-active-element'
        : item?.isAssigned
        ? 'course-active-element disabled'
        : ''
    "
  >
    <div [ngClass]="item.selected ? 'flex flex-col justify-between h-full' : ''">
      <div [ngClass]="item.selected ? '' : 'mb-3'">
        <img
          src="/assets/images/add-learners/{{
            item?.alreadySelected
              ? item.activeIcon
              : item?.tileSelected
              ? item.activeIcon
              : item?.isAssigned
              ? item.activeIcon
              : item.icon
          }}.svg"
          alt="{{
            item?.alreadySelected
              ? item.activeIcon
              : item?.tileSelected
              ? item.activeIcon
              : item?.isAssigned
              ? item.activeIcon
              : item.icon
          }}"
          class="h-2xl w-2xl mx-auto regular-course-icon"
        />
      </div>
      <h5 class="leading-none font-semibold capitalize text-base">{{ item.name }}</h5>
    </div>
    <mat-icon class="check-icon round-active-icon check-icon-text-filled font-weight-bold leading-none"
      >done
    </mat-icon>
  </div>
</div>
