import { NgIf, NgStyle, CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { Subject, takeUntil } from 'rxjs';
import { PaymentService } from 'src/app/api/services/payment/payment.service';
import { BillingComponent } from '../billing/billing.component';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-account-billing',
  templateUrl: './account-billing.component.html',
  styleUrls: ['./account-billing.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    MatTabsModule,
    BillingComponent,
    CommonModule
  ],
})


export class AccountBillingComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  billingOverviewData: any;
  disableManageButton = true;
  disableUpgradeButton = false;
  planType?: string;
  hasActiveSubscription = false;
  certSubscriptionQuotaRatio = 0;
  certPayAsYouGoQuotaRatio= 0;
  seatSubscriptionQuotaRatio = 0;



  constructor(
    private paymentService: PaymentService,
    private modalService: ModalService,
    private route: ActivatedRoute,
  ) {}


  ngOnInit() {
    const { success, paygsuccess } = this.route.snapshot.queryParams;
    if (success) {
      this.modalService.openSuccessOrgPerchaseModal(true);
    } else if (paygsuccess) {
      this.modalService.openSuccessOrgPerchaseModal(false);
    }

    const entityGuid: any = window.localStorage.getItem('entityGuid');
    this.paymentService.getBillingOverview(entityGuid).subscribe((data) => {
      this.billingOverviewData = data;
      this.hasActiveSubscription= this.billingOverviewData.hasActiveSubscription;
      this.planType = data['planType'];
      if(this.billingOverviewData.certificateSubscriptionQuota !==0){ 
        this.certSubscriptionQuotaRatio=  this.billingOverviewData.certificatesUsedSubscription/this.billingOverviewData.certificateSubscriptionQuota;
      }
      if(this.billingOverviewData.certificatePayAsYouGoQuota !==0 ){
        this.certPayAsYouGoQuotaRatio =  this.billingOverviewData.certificatesUsedPayAsYouGo/this.billingOverviewData.certificatePayAsYouGoQuota;
      }
      if(this.billingOverviewData.seatSubscriptionQuota !==0){
        this.seatSubscriptionQuotaRatio = this.billingOverviewData.seatsUsedSubscription/this.billingOverviewData.seatSubscriptionQuota;
      }
    })
  }


  calculateProgressBar(numerator: number, denominator: number): string {
    if(denominator ===0){
      return `calc(0%)`
    }
    return `calc(${(numerator / denominator) * 100}%)`;
  }

  manageSubscription() {
    this.disableManageButton = true;
    this.paymentService
      .postManageSession()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        window.location.href = res;
      });
  }


  upgradeClicked() {
    if (this.hasActiveSubscription) {
      this.disableUpgradeButton = true;
      this.paymentService.postManageSession().subscribe((res) => {
        window.location.href = res;
      });
    } else {
      this.modalService.openPricingModal(this.hasActiveSubscription);
    }
  }

  openPayAsYouGoTab() {
    this.modalService.openPayAsYouGoModal(this.hasActiveSubscription);
  }
}
